<template id="cardapio-template">
  <div id="cardapio-wrapper">
    <div class="header">
      <sui-icon name="arrow left" @click="goBack" size="large" color="grey" />
      <h3>Cardápio</h3>
    </div>

    <sui-card-group :items-per-row="5" stackable>
      <sui-card class="item-card" v-for="item in cardapio" :key="item._id">
        <sui-card-content>
          <sui-card-header>{{ item.titulo }}</sui-card-header>
          <sui-card-description>
            {{ item.descricao_prato }}
          </sui-card-description>
        </sui-card-content>
        <sui-card-content extra>
          {{ currency(item.valor_venda) }}
          <sui-icon
            name="shopping cart"
            slot="right"
            color="black"
            @click="addToCart(item)"
          />
        </sui-card-content>
      </sui-card>
    </sui-card-group>
  </div>
</template>

<script>
import { getCardapio } from "../../services/clienteService";
import { toast } from "../../helpers";

export default {
  name: "CategoriasPublico",
  components: {},
  data() {
    return {
      cardapio: [],
    };
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    currency(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(amount);
    },
    addToCart(item) {
      this.$store.commit("addToCart", item);
      toast("success", "Item adicionado com sucesso!");
    },
  },
  async mounted() {
    this.cardapio = await getCardapio(
      this.$route.params.tenant,
      this.$route.params.categoria
    );
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#cardapio-wrapper {
  padding-top: 2rem;
  margin-right: 2rem;

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    h3 {
      color: $secondary-text-color;
      margin-bottom: 2rem;
      margin-left: 1rem;
    }
  }

  .icon {
    cursor: pointer;
  }

  .category-button {
    margin-bottom: 1rem;
  }
}
</style>
