<template>
  <sui-form id="form-checkout">
    <sui-form-field>
      <label>Forma de pagamento</label>
      <sui-dropdown
        placeholder="Forma de pagamento"
        selection
        :options="options"
        v-model="pagamento.forma"
        class="forma-select"
      />
    </sui-form-field>
    <sui-form-field v-show="pagamento.forma == 'dinheiro'">
      <label>Precisa de troco? ({{ currency(total) }})</label>
      <input
        type="text"
        name="cardholderEmail"
        placeholder="Se não precisar, deixe em branco."
        v-model="pagamento.troco"
      />
    </sui-form-field>
  </sui-form>
</template>

<script>
import axios from "axios";
import { toast } from "../../helpers";

export default {
  name: "PagamentoLocal",
  props: {
    total: Number
  },
  data() {
    return {
      pagamento: {},
      options: [
        { text: "Crédito", value: "credito" },
        { text: "Débito", value: "debito" },
        { text: "Dinheiro", value: "dinheiro" }
      ]
    };
  },
  methods: {
    currency(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(amount);
    }
  },
  watch: {
    pagamento: {
      handler() {
        if (this.pagamento.forma != "dinheiro") this.pagamento.troco = "";
        this.$emit("formularios", { pagLocal: this.pagamento });
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
.forma-select {
  overflow-y: visible;
}
</style>
