<template>
  <div id="fornecedores-wrapper">
    <h3>
      <sui-icon name="arrow left" @click="$router.push('/admin')" />
      Fornecedores
    </h3>
    <div>
      <sui-table striped>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell>Fornecedor</sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>

        <sui-table-body>
          <sui-table-row v-for="res in fornecedores" :key="res._id">
            <sui-table-cell>{{ res.nome }}</sui-table-cell>
          </sui-table-row>
        </sui-table-body>
      </sui-table>
    </div>
  </div>
</template>

<script>
import { getFornecedores } from "../../services/adminService";

export default {
  name: "ListFornecedores",
  data() {
    return {
      fornecedores: [],
    };
  },
  async mounted() {
    this.fornecedores = await getFornecedores();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#fornecedores-wrapper {
  margin: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .icon {
    cursor: pointer;
  }
}
</style>
