<template lang="html">
  <div id="integracoes-wrapper">
    <h3>Integrações</h3>
    <sui-menu pointing secondary>
      <a
        is="sui-menu-item"
        v-for="item in items"
        :active="isActive(item)"
        :key="item"
        :content="item"
        @click="select(item)"
      />
    </sui-menu>

    <!-- MERCADO PAGO-->

    <sui-segment v-if="isActive('Mercado Pago')">
      <sui-form class="put-token" @submit="setKey">
        <sui-form-field>
          <sui-checkbox
            label="Habilitar compras online"
            toggle
            v-model="mercadoPago.onlineEnabled"
          />
        </sui-form-field>
        <sui-form-fields fields="two" v-show="mercadoPago.onlineEnabled">
          <sui-form-field>
            <label>Token de Acesso</label>
            <input
              :type="mercadoPago.seeInputPrivate ? 'text' : 'password'"
              placeholder="Token de Acesso"
              required
              v-model="mercadoPago.privateKey"
            />
          </sui-form-field>
          <sui-form-field class="buttons">
            <sui-button
              :icon="mercadoPago.seeInputPrivate ? 'lock open' : 'lock'"
              @click="
                mercadoPago.seeInputPrivate = !mercadoPago.seeInputPrivate
              "
              type="button"
            />
          </sui-form-field>
        </sui-form-fields>
        <sui-form-fields fields="two" v-show="mercadoPago.onlineEnabled">
          <sui-form-field>
            <label>Token de Acesso Público</label>
            <input
              :type="mercadoPago.seeInputPublic ? 'text' : 'password'"
              placeholder="Token de Acesso"
              required
              v-model="mercadoPago.publicKey"
            />
          </sui-form-field>
          <sui-form-field class="buttons">
            <sui-button
              :icon="mercadoPago.seeInputPublic ? 'lock open' : 'lock'"
              @click="mercadoPago.seeInputPublic = !mercadoPago.seeInputPublic"
              type="button"
            />
          </sui-form-field>
        </sui-form-fields>
        <sui-button
          v-show="mercadoPago.onlineEnabled"
          icon="question"
          type="button"
          color="teal"
          @click="modal = true"
        />
        <sui-button
          content="Salvar"
          primary
          icon="save"
          label-position="left"
          type="submit"
          :loading="loading"
        />
      </sui-form>
    </sui-segment>

    <sui-modal v-model="modal">
      <sui-modal-header>
        Como conseguir o Token de Acesso?
      </sui-modal-header>
      <sui-modal-content scrolling>
        <ol>
          <li>
            Crie uma
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mercadopago.com.br/hub/registration/landing"
            >
              conta
            </a>
            no Mercado Pago.
          </li>
          <li>
            Crie uma aplicação através do
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.mercadopago.com.br/developers/panel/applications"
            >
              Dashboard
            </a>
          </li>
          <li>
            Acesse suas credenciais e as ative, como mostra na imagem abaixo
            <img src="../assets/credentials.png" />
          </li>
          <li>
            Preencha o formulário, passando o link <b>corneria.com.br</b> no
            link do site
          </li>
          <li>
            Copie Public Key no campo Token de Acesso Públic, e Access Token no
            campo Token de Acesso. Depois disso é só salvar!
            <img src="../assets/token.png" />
          </li>
        </ol>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button content="OK" primary @click="modal = false" />
      </sui-modal-actions>
    </sui-modal>

    <!-- ACESSO -->
    <sui-segment v-if="isActive('Acessos')">
      <sui-form class="put-token" @submit="setKey">
        <sui-form-field>
          <sui-checkbox
            label="Habilitar acesso de administradores no sistema"
            toggle
            v-model="adminAccess"
          />
        </sui-form-field>

        <sui-button
          content="Salvar"
          primary
          icon="save"
          label-position="left"
          type="submit"
          :loading="loading"
        />
      </sui-form>
    </sui-segment>
  </div>
</template>

<script>
import { putKey } from "../services/adminService";
import { getRestaurantInfo } from "../services/clienteService";

export default {
  name: "Integracoes",
  data() {
    return {
      items: ["Mercado Pago", "Acessos"],
      active: "Mercado Pago",
      modal: false,
      mercadoPago: {
        privateKey: "",
        publicKey: "",
        onlineEnabled: false,
        seeInputPrivate: false,
        seeInputPublic: false,
      },
      adminAccess: null,
      loading: false,
    };
  },
  async mounted() {
    const restaurant = await getRestaurantInfo(
      localStorage.getItem("tenantSlug")
    );

    this.mercadoPago.onlineEnabled = restaurant.onlineEnabled;
    this.mercadoPago.privateKey = restaurant.mercadoPagoPrivateKey;
    this.mercadoPago.publicKey = restaurant.mercadoPagoPublicKey;

    this.adminAccess = restaurant.canAdminAccess;

    localStorage.setItem("onlineEnabled", restaurant.onlineEnabled);
  },

  methods: {
    isActive(name) {
      return this.active === name;
    },
    select(name) {
      this.active = name;
    },
    toggleSeeInput() {
      this.seeInput = !this.seeInput;
    },
    async setKey(e) {
      e.preventDefault();

      this.loading = true;

      const res = await putKey({
        id: localStorage.getItem("tenant"),
        onlineEnabled: this.mercadoPago.onlineEnabled,
        mercadoPagoPrivateKey: this.mercadoPago.privateKey,
        mercadoPagoPublicKey: this.mercadoPago.publicKey,
        canAdminAccess: this.adminAccess,
      });

      if (res)
        localStorage.setItem("onlineEnabled", this.mercadoPago.onlineEnabled);

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
#integracoes-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .buttons {
    display: flex;
    align-items: flex-end;
  }
  img {
    width: 100%;
  }
}
</style>
