<template>
  <div id="classificacao-wrapper">
    <h3>Classificação de Produtos</h3>
    <div class="segments">
      <ClassificacaoSegment
        title="Grupos"
        :arr="grupos"
        segment
        @segmentClicked="clickedGrupo"
        @iconClicked="iconGrupoClicked"
      />
      <ClassificacaoSegment
        title="Subgrupos"
        :arr="subgrupos"
        @segmentClicked="clickedSubgrupo"
        @iconClicked="iconSubgrupoClicked"
      />
      <ClassificacaoSegment title="Itens" :arr="items" isItem />
    </div>

    <sui-modal v-model="modalVisible" size="small">
      <sui-modal-header>{{ optionModal }} {{ tipoModal }}</sui-modal-header>
      <sui-modal-content>
        <sui-form @submit="handleSubmit">
          <sui-form-fields :fields="tipoModal == 'subgrupo' ? 2 : 1">
            <sui-form-field
              v-show="tipoModal == 'subgrupo' && optionModal == 'Adicionar'"
            >
              <sui-dropdown
                selection
                placeholder="Grupo"
                :options="grupos"
                v-model="item.idGrupo"
            /></sui-form-field>
            <sui-form-field
              :width="
                tipoModal == 'subgrupo' && optionModal == 'Adicionar' ? 8 : 16
              "
            >
              <sui-input placeholder="Nome" v-model="item.nome" />
            </sui-form-field>
          </sui-form-fields>
          <div class="right-buttons">
            <sui-button @click="modalVisible = false" type="button"
              >Cancelar</sui-button
            >
            <sui-button primary type="submit">Salvar</sui-button>
          </div>
        </sui-form>
      </sui-modal-content>
    </sui-modal>

    <sui-modal size="tiny" v-model="deleteVisible">
      <sui-modal-header>Confirmação</sui-modal-header>
      <sui-modal-content>
        Você tem certeza que deseja excluir esse item?
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click="deleteVisible = false">Cancelar</sui-button>
        <sui-button color="red" @click="handleDelete" :loading="loading">
          Excluir
        </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import {
  getGrupos,
  getSubgrupos,
  getProdutoPorSubgrupo,
  postGruposSub,
  putGruposSub,
  deleteGruposSub,
} from "../services/apiService";
import ClassificacaoSegment from "../components/ClassificacaoSegment";

export default {
  name: "Classificação",
  components: {
    ClassificacaoSegment,
  },
  data() {
    return {
      deleteVisible: false,
      modalVisible: false,
      tipoModal: "",
      optionModal: "",
      deleteId: null,
      loading: false,
      grupos: [],
      subgrupos: [],
      items: [],
      item: {},
    };
  },
  methods: {
    async handleDelete() {
      this.loading = true;
      const res = await deleteGruposSub(this.tipoModal, this.item._id);
      if (res) {
        if (this.tipoModal == "grupo") {
          this.$store.commit("removeGrupo", this.item._id);
          this.grupos = this.grupos.filter(
            (item) => item.value != this.item._id
          );
        }
        this.subgrupos = [];
        this.item = [];
      }
      this.loading = false;
      this.deleteVisible = false;
    },
    async clickedGrupo(id) {
      this.subgrupos = [];
      this.items = [];
      const subgrupos = await getSubgrupos(id);
      subgrupos.forEach((subgrupo) => {
        this.subgrupos.push({ text: subgrupo.nome, value: subgrupo._id });
      });
    },
    async clickedSubgrupo(id) {
      this.items = [];
      const items = await getProdutoPorSubgrupo(id);
      items.forEach((item) => {
        this.items.push({
          text: item.item,
          value: item._id,
        });
      });
    },
    iconGrupoClicked(args) {
      const { option, id } = args;
      this.item._id = id;
      this.openModal(option, "grupo");
    },
    iconSubgrupoClicked(args) {
      const { option, id } = args;
      this.item._id = id;
      this.openModal(option, "subgrupo");
    },
    openModal(option, tipo, isDelete = false) {
      this.tipoModal = tipo;
      if (option == 3) {
        this.optionModal = "Excluir";
        this.deleteVisible = true;
        return;
      } else if (option == 1) this.optionModal = "Adicionar";
      else if (option == 2) this.optionModal = "Editar";
      this.modalVisible = true;
    },
    async handleSubmit(e) {
      e.preventDefault();
      if (this.optionModal == "Adicionar") {
        const res = await postGruposSub(this.tipoModal, this.item);
        if (res && this.tipoModal == "grupo")
          this.grupos.push({ text: res.nome, value: res._id });
        this.item.nome = "";
        this.modalVisible = false;
      } else if (this.optionModal == "Editar") {
        const res = await putGruposSub(this.tipoModal, this.item);
        if (res && this.tipoModal == "grupo") {
          const index = this.grupos.findIndex((item) => item.value == res._id);
          this.grupos[index].text = res.nome;
        } else if (res && this.tipoModal == "subgrupo") {
          const index = this.subgrupos.findIndex(
            (item) => item.value == res._id
          );
          this.subgrupos[index].text = res.nome;
        }
        this.item.nome = "";
        this.modalVisible = false;
      }
    },
  },
  async mounted() {
    if (this.$store.state.api.grupos.count == null) await getGrupos();
    this.$store.state.api.grupos.data.forEach((grupo) => {
      this.grupos.push({ text: grupo.nome, value: grupo._id });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
#classificacao-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .segments {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    > * {
      margin: 1rem;
      width: 100%;
    }
  }

  .right-buttons {
    float: right;
    margin-bottom: 1rem;
  }
}
</style>
