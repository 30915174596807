<template>
  <div id="cadastro-produtos-wrapper">
    <h3>Cadastro de Produtos</h3>
    <sui-menu pointing secondary>
      <a
        is="sui-menu-item"
        v-for="tab in tabs"
        :active="isActive(tab)"
        :key="tab"
        :content="tab"
        @click="select(tab)"
      />
    </sui-menu>
    <sui-segment v-show="isActive('Geral')">
      <Geral @input="setData" :value="cadastro" />
      <sui-button
        icon="right chevron"
        label-position="right"
        content="Próximo"
        class="next-button"
        color="teal"
        @click="select('Tributação')"
      />
    </sui-segment>
    <sui-segment v-show="isActive('Tributação')">
      <Tributacao :value="cadastro" @input="setData" />
      <sui-button
        icon="right chevron"
        label-position="right"
        content="Próximo"
        class="next-button"
        color="teal"
        @click="select('Classificação')"
      />
    </sui-segment>
    <sui-segment v-show="isActive('Classificação')">
      <Classificacao :value="cadastro" @input="setData" />
      <sui-button
        icon="right chevron"
        label-position="right"
        content="Próximo"
        class="next-button"
        color="teal"
        @click="select('Fornecedores')"
      />
    </sui-segment>
    <sui-segment v-show="isActive('Fornecedores')">
      <Fornecedores :value="cadastro" @input="setData" />
      <sui-button
        icon="save"
        label-position="right"
        content="Salvar"
        class="next-button"
        color="teal"
        :loading="loading"
        @click="addProduto"
      />
    </sui-segment>
  </div>
</template>

<script>
import { toast } from "../../helpers";
import { postProduto } from "../../services/apiService";

import Geral from "../../components/CadastroProdutos/Geral.vue";
import Tributacao from "../../components/CadastroProdutos/Tributacao.vue";
import Classificacao from "../../components/CadastroProdutos/Classificacao.vue";
import Fornecedores from "../../components/CadastroProdutos/Fornecedores.vue";

export default {
  name: "CadastroProdutos",
  components: {
    Geral,
    Tributacao,
    Classificacao,
    Fornecedores,
  },
  data() {
    return {
      activeTab: "Geral",
      tabs: ["Geral", "Tributação", "Classificação", "Fornecedores"],
      cadastro: {},
      requiredFields: [
        "item",
        "sku",
        "unidade",
        "situacao",
        "grupo",
        "subgrupo",
      ],
      loading: false,
    };
  },
  mounted() {
    this.cadastro = this.$store.state.cadastro.produto;
  },
  methods: {
    isActive(name) {
      return this.activeTab === name;
    },
    select(name) {
      this.$store.commit("setProduto", this.cadastro);
      this.activeTab = name;
    },
    setData(e) {
      this.cadastro = e;
    },
    async addProduto() {
      for (const field in this.requiredFields)
        if (!(this.requiredFields[field] in this.cadastro)) {
          toast("error", "Preencha todos os campos obrigatórios!");
          if (field <= 5) this.select("Geral");
          else if (field <= 8) this.select("Tributação");
          else this.select("Classificação");
          return;
        }

      this.loading = true;
      await postProduto(this.cadastro);
      this.$store.commit("clearProduto");
      this.$router.push({ name: "produtos" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#cadastro-produtos-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .next-button {
    margin: 2rem 0;
    float: right;
  }
}
</style>
