<template lang="html">
  <div id="pedidos-wrapper">
    <h3>Pedidos</h3>
    <div class="pedidos-container">
      <div>
        <h3>Fazendo</h3>
        <PedidoStatus
          v-for="(prato, index) in fazendo"
          :key="index"
          :status="false"
          :mesa="prato.mesa"
          :prato="prato.prato"
          :obs="prato.obs"
          @alterStatus="alter(prato, 1, index)"
        />
        <PedidoOnlineStatus
          v-for="(prato, index) in fazendoOnline"
          :key="prato.key"
          :status="false"
          :prato="prato.prato"
          :codigo="prato.codigo"
          @alterStatus="alterDelivery(prato, 1, index)"
        />
      </div>
      <div>
        <h3>Feito</h3>
        <PedidoStatus
          v-for="(prato, index) in feito"
          :key="prato.key"
          :status="true"
          :mesa="prato.mesa"
          :prato="prato.prato"
          :obs="prato.obs"
          @alterStatus="alter(prato, 2, index)"
        />
        <PedidoOnlineStatus
          v-for="(prato, index) in feitoOnline"
          :key="index"
          :status="true"
          :prato="prato.prato"
          :codigo="prato.codigo"
          :qtde="prato.qtde"
          @alterStatus="alterDelivery(prato, 2, index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PedidoStatus from "../components/PedidoStatus";
import PedidoOnlineStatus from "../components/PedidoOnlineStatus";
import {
  getCozinha,
  alterStatus,
  alterStatusOnline,
} from "../services/pdvService";

export default {
  name: "Cozinha",
  components: {
    PedidoStatus,
    PedidoOnlineStatus,
  },
  data() {
    return {
      fazendo: [],
      feito: [],
      fazendoOnline: [],
      feitoOnline: [],
    };
  },
  async mounted() {
    let index = 0;
    const mesas = await getCozinha();
    mesas.forEach((mesa) => {
      mesa.pedidos.forEach((pedido) => {
        if (mesa.mesa) {
          if (pedido.feitos > 0)
            for (let i = 0; i < pedido.feitos; i++) {
              this.feito.push({
                mesa: mesa.mesa[0],
                mesa_id: mesa._id,
                prato: pedido.item.titulo,
                obs: pedido.observacao,
                id: pedido._id,
                qtde: pedido.qtde,
              });
            }
          if (pedido.qtde > pedido.feitos) {
            for (let i = 0; i < pedido.qtde - pedido.feitos; i++) {
              this.fazendo.push({
                mesa: mesa.mesa[0],
                mesa_id: mesa._id,
                prato: pedido.item.titulo,
                obs: pedido.observacao,
                id: pedido._id,
                qtde: pedido.qtde,
              });
            }
          }
        } else {
          if (pedido.feitos > 0)
            for (let i = 0; i < pedido.feitos; i++) {
              index += 1;
              this.feitoOnline.push({
                key: (Math.random() + 1).toString(36).substring(7),
                status: true,
                prato: pedido.item.titulo,
                id: pedido._id,
                qtde: pedido.qtde,
                codigo: mesa.codigo,
                pedidoId: mesa._id,
              });
            }
          if (pedido.qtde > pedido.feitos) {
            for (let i = 0; i < pedido.qtde - pedido.feitos; i++) {
              index += 1;
              this.fazendoOnline.push({
                key: (Math.random() + 1).toString(36).substring(7),
                status: false,
                prato: pedido.item.titulo,
                id: pedido._id,
                qtde: pedido.qtde,
                codigo: mesa.codigo,
                pedidoId: mesa._id,
              });
            }
          }
        }
      });
    });
  },
  methods: {
    async alter(prato, array, index) {
      const caminho = array;
      const data = {
        mesa: prato.mesa_id,
        pedido: prato.id,
        caminho,
      };
      const res = await alterStatus(data);
      if (res) {
        if (array == 1) {
          this.fazendo.splice(index, 1);
          this.feito.push(prato);
        } else {
          this.feito.splice(index, 1);
          this.fazendo.push(prato);
        }
      }
    },
    async alterDelivery(prato, array, index) {
      const caminho = array;
      const data = {
        id: prato.pedidoId,
        pedido: prato.id,
        caminho,
      };
      const res = await alterStatusOnline(data);
      if (res) {
        if (array == 1) {
          this.fazendoOnline.splice(index, 1);
          this.feitoOnline.push(prato);
        } else {
          this.feitoOnline.splice(index, 1);
          this.fazendoOnline.push(prato);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
#pedidos-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .pedidos-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > * {
      width: 100%;
      margin: 1rem;
    }
  }
}
</style>
