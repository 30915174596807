<template>
  <sui-form id="form-checkout">
    <sui-header dividing>Informações pessoais</sui-header>
    <sui-form-field>
      <label>Email</label>
      <input
        required
        type="email"
        name="cardholderEmail"
        id="form-checkout__cardholderEmail"
      />
    </sui-form-field>
    <sui-form-field>
      <label>Tipo de documento</label>
      <select
        name="identificationType"
        id="form-checkout__identificationType"
      ></select>
    </sui-form-field>
    <sui-form-field>
      <label>Nº do documento</label>
      <input
        required
        type="text"
        name="identificationNumber"
        id="form-checkout__identificationNumber"
      />
    </sui-form-field>
    <sui-header dividing>Informações do cartão de crédito</sui-header>
    <sui-form-field>
      <label>Nome (no cartão de crédito)</label>
      <input
        required
        type="text"
        name="cardholderName"
        id="form-checkout__cardholderName"
      />
    </sui-form-field>
    <sui-form-field>
      <label>Número do cartão de crédito</label>
      <input
        required
        type="text"
        name="cardNumber"
        id="form-checkout__cardNumber"
      />
    </sui-form-field>
    <sui-form-fields>
      <sui-form-field>
        <label>Mês de vencimento</label>
        <input
          required
          type="text"
          name="cardExpirationMonth"
          id="form-checkout__cardExpirationMonth"
          maxlength="2"
        />
      </sui-form-field>
      <sui-form-field>
        <label>Ano de vencimento</label>
        <input
          required
          type="text"
          name="cardExpirationYear"
          id="form-checkout__cardExpirationYear"
          maxlength="2"
        />
      </sui-form-field>
    </sui-form-fields>
    <sui-form-field>
      <label>Código de segurança</label>
      <input
        required
        type="text"
        name="securityCode"
        id="form-checkout__securityCode"
      />
    </sui-form-field>
    <sui-form-field>
      <label>Banco Emissor</label>
      <select name="issuer" id="form-checkout__issuer"></select>
    </sui-form-field>
    <sui-form-field>
      <label>Parcelas</label>
      <select name="installments" id="form-checkout__installments"></select>
    </sui-form-field>
    <sui-button
      type="submit"
      id="form-checkout__submit"
      primary
      class="submit-button"
      :loading="loading"
    >
      Pagar {{ currency(total) }}
    </sui-button>
  </sui-form>
</template>

<script>
import axios from "axios";
import { toast } from "../../helpers";

export default {
  name: "Pagamento",
  props: {
    total: Number,
    restaurantInfo: Object
  },
  data() {
    return {
      pagamento: {},
      loading: false
    };
  },
  methods: {
    currency(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(amount);
    }
  },
  mounted() {
    const self = this;

    const mp = new MercadoPago(self.restaurantInfo.mercadoPagoPublicKey);

    const cardForm = mp.cardForm({
      amount: String(this.total),
      autoMount: true,
      form: {
        id: "form-checkout",
        cardholderName: {
          id: "form-checkout__cardholderName",
          placeholder: "Titular do cartão"
        },
        cardholderEmail: {
          id: "form-checkout__cardholderEmail",
          placeholder: "E-mail"
        },
        cardNumber: {
          id: "form-checkout__cardNumber",
          placeholder: "Número do cartão"
        },
        cardExpirationMonth: {
          id: "form-checkout__cardExpirationMonth",
          placeholder: "Mês de vencimento"
        },
        cardExpirationYear: {
          id: "form-checkout__cardExpirationYear",
          placeholder: "Ano de vencimento"
        },
        securityCode: {
          id: "form-checkout__securityCode",
          placeholder: "Código de segurança"
        },
        installments: {
          id: "form-checkout__installments",
          placeholder: "Parcelas"
        },
        identificationType: {
          id: "form-checkout__identificationType",
          placeholder: "Tipo de documento"
        },
        identificationNumber: {
          id: "form-checkout__identificationNumber",
          placeholder: "Número do documento"
        },
        issuer: {
          id: "form-checkout__issuer",
          placeholder: "Banco emissor"
        }
      },
      callbacks: {
        onFormMounted: error => {
          if (error)
            toast(
              "Ocorreu um erro ao montar o formulário de pagamento, tente novamente."
            );
        },
        onSubmit: async function(e) {
          e.preventDefault();
          self.loading = true;

          const {
            paymentMethodId: payment_method_id,
            issuerId: issuer_id,
            cardholderEmail: email,
            amount,
            token,
            installments,
            identificationNumber,
            identificationType
          } = cardForm.getCardFormData();

          const data = {
            token,
            issuer_id,
            payment_method_id,
            transaction_amount: Number(amount),
            installments: Number(installments),
            description: "Descrição do produto",
            payer: {
              email,
              identification: {
                type: identificationType,
                number: identificationNumber
              }
            }
          };

          self.$emit("formularios", { pagamento: data });
        }
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.submit-button {
  float: right;
}
</style>
