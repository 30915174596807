import Vue from "vue";
import SuiVue from "semantic-ui-vue";

import App from "./App";
import router from "./router";
import store from "./store";

import "./styles/global.scss";
import "semantic-ui-css/semantic.min.css";

Vue.config.productionTip = false;
Vue.use(SuiVue);

new Vue({
  router,
  render: (h) => h(App),
  store,
  // eslint-disable-next-line prettier/prettier
}).$mount("#app");
