<template>
  <sui-grid>
    <sui-grid-row>
      <sui-grid-column class="cadastro-column" data-tooltip="Item">
        <sui-input
          class="cadastro-input"
          v-model="value.item"
          placeholder="Item *"
        />
      </sui-grid-column>
    </sui-grid-row>
    <sui-grid-row>
      <sui-grid-column class="cadastro-column" :width="8" data-tooltip="SKU">
        <sui-input
          class="cadastro-input"
          v-model="value.sku"
          type="number"
          placeholder="SKU *"
        />
      </sui-grid-column>
      <sui-grid-column
        class="cadastro-column"
        :width="4"
        data-tooltip="Unidade"
      >
        <sui-dropdown
          selection
          placeholder="Unidade *"
          :options="unidades"
          v-model="value.unidade"
        />
      </sui-grid-column>
      <sui-grid-column
        class="cadastro-column"
        :width="4"
        data-tooltip="Situação"
      >
        <sui-dropdown
          selection
          placeholder="Situação *"
          :options="situacoes"
          v-model="value.situacao"
        />
      </sui-grid-column>
    </sui-grid-row>
  </sui-grid>
</template>

<script>
export default {
  name: "Geral",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      situacoes: [
        { text: "Ativo", value: "Ativo" },
        { text: "Inativo", value: "Inativo" },
      ],
      unidades: [
        { text: "Kg", value: "Kg" },
        { text: "L", value: "L" },
        { text: "pct", value: "pct" },
        { text: "un", value: "un" },
      ],
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
};
</script>

<style lang="scss" src="../../styles/components/cadastroProdutos.scss" scoped />
