<template>
  <div id="navbar" class="active">
    <img src="../../assets/logo_black.svg" class="black" />
    <img src="../../assets/logo_white.svg" class="white" />

    <img
      src="../../assets/home/hamburguer.svg"
      class="hamburguer"
      @click="active = !active"
    />

    <div :class="['navigation', { 'is-open': active }]">
      <span class="nav-link" @click="scroll(null)">Início</span>
      <span class="nav-link" @click="scroll('how-it-works')">
        Como funciona
      </span>
      <span class="nav-link" @click="scroll('functionalities')">
        Funcionalidades
      </span>
      <router-link class="nav-link" to="/login">
        <Button
          :inverted="screenSize >= 1280 && !isResponsive"
          text="Entrar"
          class="button"
          :secondary="screenSize < 1280 || isResponsive"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from "./Button";

export default {
  name: "Navbar",
  components: {
    Button,
  },
  data: () => ({
    active: false,
    screenSize: 1920,
    isResponsive: false,
  }),
  mounted() {
    window.addEventListener("resize", () => {
      this.screenSize = document.documentElement.clientWidth;
    });

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    )
      this.isResponsive = true;
  },
  methods: {
    scroll(ref) {
      if (!ref) window.scrollTo({ top: 0, behavior: "smooth" });
      else {
        const element = this.$parent.$refs[ref];

        if (element.$el) element.$el.scrollIntoView({ behavior: "smooth" });
        else element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#navbar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 122px;
  width: 100vw;
  align-items: center;
  transition: all 0.3s ease-in;
  z-index: 10000;

  .white {
    display: none;
    width: 50px;
  }

  .hamburguer {
    cursor: pointer;
    display: none;
  }

  .navigation {
    display: flex;
    align-items: center;

    .nav-link {
      text-decoration: none;
      margin: 0 32px;
      cursor: pointer;
      font: 300 14px Inter, sans-serif;
      color: $black;
    }
  }

  @media (max-width: 1279px) {
    background: $primary;
    border-radius: 0 0 5px 5px;
    padding: 13px 32px;

    .hamburguer,
    .white {
      display: block;
    }

    .black {
      display: none;
    }

    .navigation {
      overflow: hidden;
      flex-direction: column;
      align-items: center;
      width: 100%;
      transition: max-height 0.3s ease-in;
      max-height: 0;

      &.is-open {
        max-height: 20rem;

        .button {
          margin-bottom: 1rem;
        }
      }

      .nav-link {
        margin: 1rem 0;
        color: $white;
      }
    }
  }
}
</style>
