<template>
  <div id="produtos-wrapper">
    <h3>Produtos</h3>
    <div>
      <div class="top-segment">
        <div>
          <sui-input
            placeholder="Pesquisar por item"
            v-model="search"
            icon="seach"
          />
        </div>

        <div>
          <sui-button
            icon="trash"
            color="red"
            :disabled="selected.length == 0"
            @click="removeMultiple"
            :loading="loading"
          />
          <sui-button
            icon="plus"
            label-position="left"
            content="Adicionar produto"
            color="teal"
            @click="$router.push('cadastro-produtos')"
          />
        </div>
      </div>

      <sui-table celled>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell></sui-table-header-cell>
            <sui-table-header-cell>Item</sui-table-header-cell>
            <sui-table-header-cell>Código</sui-table-header-cell>
            <sui-table-header-cell>Unidade</sui-table-header-cell>
            <sui-table-header-cell>Estoque</sui-table-header-cell>
            <sui-table-header-cell>Ações</sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>

        <sui-table-body>
          <sui-table-row v-for="produto in produtos" :key="produto._id">
            <sui-table-cell
              ><sui-checkbox
                v-model="selected"
                :value="produto.id_produto._id"
              />
            </sui-table-cell>
            <sui-table-cell>{{ produto.id_produto.item }}</sui-table-cell>
            <sui-table-cell>{{ produto.id_produto.sku }}</sui-table-cell>
            <sui-table-cell>{{ produto.id_produto.unidade }}</sui-table-cell>
            <sui-table-cell>{{ produto.estoque_atual }} </sui-table-cell>
            <sui-table-cell class="options">
              <div
                data-tooltip="Estoque"
                data-inverted
                @click="
                  goToDetails(
                    produto._id,
                    'detalhes-estoque',
                    produto.estoque_atual
                  )
                "
              >
                <i class="dolly icon"></i>
              </div>
              <i
                class="cog icon"
                @click="goToDetails(produto.id_produto._id, 'detalhes-produto')"
              ></i>
            </sui-table-cell>
          </sui-table-row>
        </sui-table-body>
      </sui-table>
    </div>
  </div>
</template>

<script>
import { getProdutos, deleteProdutos } from "../../services/apiService";
import { normalizeAndSearch, toast } from "../../helpers";

export default {
  name: "Produtos",
  components: {},
  data() {
    return {
      selected: [],
      loading: false,
      search: "",
    };
  },
  methods: {
    goToDetails(id, name, storage) {
      if (storage <= 0)
        toast("warning", "Estoque vazio, produto não possui histórico");
      else this.$router.push({ name, params: { id } });
    },
    async removeMultiple() {
      this.loading = true;
      const res = await deleteProdutos(this.selected);
      if (res) this.$store.commit("removeProdutos", this.selected);
      this.loading = false;
    },
  },
  computed: {
    produtos: {
      get() {
        return this.$store.state.api.produtos.data.filter((produto) => {
          return normalizeAndSearch(produto.id_produto.item, this.search);
        });
      },
      set() {},
    },
  },
  async mounted() {
    if (this.$store.state.api.produtos.count == null) await getProdutos();
    this.produtos = this.$store.state.api.produtos.data;
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#produtos-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .top-segment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ui.input {
      width: 30rem;
    }
  }
  .options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    > * {
      cursor: pointer;
    }
  }
}
</style>
