<template>
  <sidebar-menu
    :menu="menu"
    :collapsed="true"
    :showOneChild="true"
    @item-click="handleClick"
  >
    <i slot="toggle-icon" class="ui icon arrows alternate horizontal"></i>
    <i slot="dropdown-icon" class="ui icon caret right"></i>
  </sidebar-menu>
</template>

<script>
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

import { toast } from "../helpers";

export default {
  name: "Sidebar",
  components: {
    SidebarMenu,
  },
  data() {
    return {
      menu: [
        // {
        //   title: "Dashboard",
        //   icon: {
        //     element: "i",
        //     class: "ui icon home",
        //   },
        // },
        {
          title: "Cadastro",
          icon: {
            element: "i",
            class: "ui icon feather",
          },
          child: [
            {
              title: "Produtos",
              href: { name: "produtos" },
              icon: {
                element: "i",
                class: "ui icon drumstick bite",
              },
            },
            {
              title: "Ficha Tecnica",
              href: { name: "cardapio" },
              icon: {
                element: "i",
                class: "ui icon book open",
              },
            },
            {
              title: "Fornecedores",
              href: { name: "fornecedores" },
              icon: {
                element: "i",
                class: "ui icon truck",
              },
            },
            {
              title: "Classificação",
              href: { name: "classificacao" },
              icon: {
                element: "i",
                class: "ui icon layer group",
              },
            },
          ],
        },
        {
          title: "Financeiro",
          icon: {
            element: "i",
            class: "ui icon dollar",
          },
          child: [
            {
              title: "Caixa",
              href: { name: "caixa" },
              icon: {
                element: "i",
                class: "ui icon cash register",
              },
            },
            {
              title: "Gorjetas",
              href: { name: "gorjeta" },
              icon: {
                element: "i",
                class: "ui icon hand holding usd",
              },
            },
          ],
        },
        {
          title: "Estoque",
          href: { name: "estoque" },
          icon: {
            element: "i",
            class: "ui icon warehouse",
          },
        },
        {
          title: "PDV",
          icon: {
            element: "i",
            class: "ui icon store",
          },
          child: [
            {
              title: "Ponto de vendas",
              href: { name: "mesas" },
              icon: {
                element: "i",
                class: "ui icon store",
              },
            },
            {
              title: "Pedidos Online",
              href: { name: "pedidos-online" },
              icon: {
                element: "i",
                class: "ui icon shopping bag",
              },
            },
          ],
        },

        {
          title: "Cozinha",
          href: { name: "cozinha" },
          icon: {
            element: "i",
            class: "ui icon bacon",
          },
        },
        {
          title: "Pedido de Compra",
          href: { name: "entradas" },
          icon: {
            element: "i",
            class: "ui icon shopping basket",
          },
        },
        {
          title: "Configurações",
          icon: {
            element: "i",
            class: "ui icon cog",
          },
          child: [
            {
              title: "Usuários",
              href: { name: "usuarios" },
              icon: {
                element: "i",
                class: "ui icon user",
              },
            },
            {
              title: "Integrações",
              href: { name: "integracoes" },
              icon: {
                element: "i",
                class: "ui icon puzzle piece",
              },
            },
          ],
        },
        {
          title: "Visualização como cliente",
          icon: {
            element: "i",
            class: "ui icon portrait",
          },
        },
        {
          title: "Sair",
          icon: {
            element: "i",
            class: "ui icon sign out alternate",
          },
        },
      ],
    };
  },
  methods: {
    handleClick(e, item, node) {
      if (item.title == "Sair") {
        localStorage.removeItem("tenant");
        localStorage.removeItem("tenantSlug");

        if (localStorage.getItem("role") == "Admin/Permission") {
          localStorage.setItem("role", "Admin");
          this.$router.push("/admin");
        } else {
          localStorage.removeItem("token");
          localStorage.removeItem("role");
          this.$router.push("/login");
        }
      } else if (item.title == "Visualização como cliente") {
        if (localStorage.getItem("onlineEnabled") == "false")
          toast(
            "error",
            "Compras online não habilitadas. Habilite a partir do menu Configurações -> Integrações"
          );
        else this.$router.push(`cliente/${localStorage.getItem("tenantSlug")}`);
      }
    },
  },
};
</script>
