<template>
  <sui-form>
    <sui-form-fields grouped>
      <sui-form-field>
        <sui-checkbox
          label="Retirada no local"
          radio
          value="2"
          v-model="comoReceber"
        />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox
          label="Delivery (sujeito a frete)"
          radio
          value="3"
          v-model="comoReceber"
        />
      </sui-form-field>
    </sui-form-fields>
    <sui-form-field v-show="comoReceber == '2'">
      <label>Quem fará a retirada?</label>
      <input type="text" v-model="nome" placeholder="Nome" />
    </sui-form-field>
  </sui-form>
</template>

<script>
export default {
  name: "ComoReceber",
  data() {
    return {
      comoReceber: "2",
      nome: ""
    };
  },
  watch: {
    comoReceber() {
      this.$emit("recebimento", this.comoReceber);
    },
    nome() {
      this.$emit("formularios", { entrega: { nome: this.nome } });
    }
  }
};
</script>
