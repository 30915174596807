import api from "./api";
import store from "../store";

import { toast } from "../helpers";

// GETs

export const getFornecedores = async () => {
  try {
    const res = await api.get("todos/pessoas-fornecedores", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("set", {
      type: "api",
      name: "fornecedores",
      item: res.data.res,
    });
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getFornecedor = async (id) => {
  try {
    const res = await api.get(`pessoas-fornecedores/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getProdutos = async () => {
  try {
    const res = await api.get("todos/produtos", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("set", {
      type: "api",
      name: "produtos",
      item: res.data.res,
    });
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getProduto = async (id) => {
  try {
    const res = await api.get(`produtos/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getGrupos = async () => {
  try {
    const res = await api.get("todos/grupos", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("set", {
      type: "api",
      name: "grupos",
      item: res.data.res,
    });
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getSubgrupos = async (id) => {
  try {
    const res = await api.get(`subgrupos/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getCardapio = async () => {
  try {
    const res = await api.get("todos/ficha-tecnica", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("set", {
      type: "api",
      name: "cardapio",
      item: res.data.res,
    });
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getFichaTecnica = async (id) => {
  try {
    const res = await api.get(`ficha-tecnica/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getProdutoPorFornecedor = async (id) => {
  try {
    const res = await api.get("produtos-por-fornecedor", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
      params: {
        id,
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getProdutoPorSubgrupo = async (subgrupo) => {
  try {
    const res = await api.get("produtos-por-subgrupo", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
      params: {
        subgrupo,
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getSaldo = async (data1, data2) => {
  try {
    const res = await api.get("pagamento", {
      params: {
        data1,
        data2,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getLastSaldo = async () => {
  try {
    const res = await api.get("caixa/ultimo-saldo", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getEstoque = async () => {
  try {
    const res = await api.get("todos/estoque", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.estoque;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getEstoqueSaidas = async (id) => {
  try {
    const res = await api.get(`estoque/saidas/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getEstoqueSaidasData = async (ids, from, to) => {
  try {
    const res = await api.get(
      `estoque/data/saidas?ids=${ids}&from_date=${from}&to_date=${to}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          tenant: localStorage.getItem("tenant"),
        },
      }
    );
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getHistoricoEstoque = async (id, data1, data2) => {
  try {
    const res = await api.get(`estoque/${id}`, {
      params: {
        data1,
        data2,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getEntradas = async () => {
  try {
    const res = await api.get("todos/entrada", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("set", {
      type: "api",
      name: "entradas",
      item: res.data.res,
    });
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getUsers = async () => {
  try {
    const res = await api.get("admin/todos/user", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("set", {
      type: "api",
      name: "users",
      item: res.data.res,
    });
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getGorjeta = async (date) => {
  try {
    const res = await api.get("gorjeta", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
      params: {
        date,
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getOrderByPay = async (id) => {
  try {
    const res = await api.get(`pagamento/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

// POSTs

export const postProduto = async (data) => {
  try {
    const res = await api.post("produtos", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });

    res.data.estoque.id_produto = res.data.prod;
    res.data = res.data.estoque;

    store.commit("push", {
      type: "api",
      name: "produtos",
      item: res.data,
    });
    toast("success", "Produto cadastrado com sucesso!");
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const postFichaTecnica = async (data) => {
  try {
    const res = await api.post("ficha-tecnica", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("push", {
      type: "api",
      name: "cardapio",
      item: res.data.res,
    });
    toast("success", "Ficha técnica cadastrada com sucesso!");
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const postFornecedores = async (data) => {
  try {
    const res = await api.post("pessoas-fornecedores", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("push", {
      type: "api",
      name: "fornecedores",
      item: res.data.res,
    });
    toast("success", "Fornecedor cadastrado com sucesso!");
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const postMovimentacao = async (data) => {
  try {
    const res = await api.post("caixa", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    toast("success", "Movimentação registrada com sucesso!");
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const postGruposSub = async (route, data) => {
  try {
    const res = await api.post(route, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    toast("success", `${route} cadastrado com sucesso!`);
    if (route == "grupo")
      store.commit("push", {
        type: "api",
        name: "grupos",
        item: res.data.res,
      });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const postEntrada = async (data) => {
  try {
    const res = await api.post("entrada", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    toast("success", "Entrada cadastrado com sucesso!");
    store.commit("push", {
      type: "api",
      name: "entradas",
      item: res.data.res,
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

// DELETEs

export const deleteProdutos = async (ids) => {
  console.log(ids);
  try {
    const res = await api.delete("produtos", {
      params: {
        ids,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const deleteFornecedores = async (ids) => {
  try {
    const res = await api.delete("pessoas-fornecedores", {
      params: {
        ids,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });

    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const deleteUsers = async (ids) => {
  try {
    const res = await api.delete("admin/user", {
      params: {
        ids,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });

    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const deleteFichaTecnica = async (id) => {
  try {
    const res = await api.delete(`ficha-tecnica/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });

    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const deleteGruposSub = async (route, id) => {
  try {
    const res = await api.delete(`${route}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    toast("success", `${route} deletado com sucesso!`);
    if (route == "grupo") store.commit("alterGrupo", res.data.res);
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

// PUTs

export const putProduto = async (data) => {
  try {
    const res = await api.put("produtos", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("alterProduto", data);
    toast("success", "Produto alterado com sucesso!");
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const putFornecedor = async (data) => {
  try {
    const res = await api.put("pessoas-fornecedores", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("alterFornecedor", data);
    toast("success", "Fornecedor alterado com sucesso!");
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const putFichaTecnica = async (data) => {
  try {
    const res = await api.put("ficha-tecnica", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    toast("success", "Prato alterado com sucesso.");
    store.commit("alterCardapio", res.data.res);
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const putFichaTecnicaImg = async (data) => {
  try {
    console.log(data);
    const res = await api.put("ficha-tecnica/imagem", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
        "Content-Type": "multipart/form-data",
      },
    });
    toast("success", "Imagem alterada com sucesso.");
    store.commit("alterCardapio", res.data.res);
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const putDetalhamentoProduto = async (id, padrao) => {
  try {
    const res = await api.put(
      `estoque/detalhamento/${id}`,
      {},
      {
        params: {
          padrao,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          tenant: localStorage.getItem("tenant"),
        },
      }
    );
    return res.data.produto;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const putEntrada = async (id) => {
  try {
    const res = await api.put(
      `entrada/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          tenant: localStorage.getItem("tenant"),
        },
      }
    );
    store.commit("alterEntrada", res.data.entrada);
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const putGruposSub = async (route, data) => {
  try {
    const res = await api.put(route, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    toast("success", `${route} alterado com sucesso!`);
    if (route == "grupo") store.commit("alterGrupo", res.data.res);
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const redefinePassword = async (data) => {
  try {
    const response = await api.put("admin/user", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    toast("success", "Senha trocada!");
  } catch (err) {
    toast("error", e.response.data.error);
  }
};
