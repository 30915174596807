import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

let carrinho = window.localStorage.getItem("cart");
let produto = window.localStorage.getItem("product");

const store = new Vuex.Store({
  state: {
    api: {
      fornecedores: {
        data: [],
        count: null,
      },
      produtos: {
        data: [],
        count: null,
      },
      grupos: {
        data: [],
        count: null,
      },
      marcas: {
        data: [],
        count: null,
      },
      cardapio: {
        data: [],
        count: null,
      },
      entradas: {
        data: [],
        count: null,
      },
      users: {
        data: [],
        count: null,
      },
    },
    pdv: {
      pedidos: {
        data: [],
        count: null,
      },
    },
    currentEstoque: null,
    cliente: {
      carrinho: JSON.parse(carrinho) || [],
    },
    cadastro: {
      produto: JSON.parse(produto) || {},
    },
    fornecedor: {
      cpf_cnpj: "",
      restaurantes: {
        data: [],
        count: null,
      },
      pedido: {
        restaurantId: null,
        produtos: [],
      },
    },
  },
  mutations: {
    set(state, payload) {
      const { type, name, item } = payload;
      state[type][name].data = item;
      state[type][name].count = item.length;
    },
    push(state, payload) {
      const { type, name, item } = payload;
      state[type][name].data.push(item);
      state[type][name].count += 1;
    },
    setCurrentEstoque(state, estoque) {
      state.currentEstoque = estoque;
    },
    removeFornecedores(state, fornecedores) {
      state.api.fornecedores.data = state.api.fornecedores.data.filter(
        (item) => !fornecedores.includes(item._id)
      );
      state.api.fornecedores.count -= fornecedores.length;
    },
    alterFornecedor(state, fornecedor) {
      const index = state.api.fornecedores.data.findIndex(
        (item) => item._id == fornecedor._id
      );
      state.api.fornecedores.data[index] = fornecedor;
    },
    alterProduto(state, produto) {
      const index = state.api.produtos.data.findIndex(
        (item) => item.id_produto._id == produto._id
      );
      state.api.produtos.data[index].id_produto = produto;
    },
    removeProdutos(state, produtos) {
      state.api.produtos.data = state.api.produtos.data.filter(
        (item) => !produtos.includes(item.id_produto._id)
      );
      state.api.produtos.count -= produtos.length;
    },
    alterGrupo(state, grupo) {
      const index = state.api.grupos.data.findIndex(
        (item) => item._id == grupo._id
      );
      state.api.grupos.data[index] = grupo;
    },
    removeGrupo(state, grupo) {
      state.api.grupos.data = state.api.grupos.data.filter(
        (item) => item._id != grupo
      );
      state.api.grupos.count -= 1;
    },
    alterCardapio(state, cardapio) {
      const index = state.api.cardapio.data.findIndex(
        (item) => item._id == cardapio._id
      );
      state.api.cardapio.data[index] = cardapio;
    },
    removeCardapio(state, cardapio) {
      state.api.cardapio.data = state.api.cardapio.data.filter(
        (item) => item._id != cardapio
      );
      state.api.cardapio.count -= 1;
    },
    removeUsers(state, users) {
      state.api.users.data = state.api.users.data.filter(
        (item) => !users.includes(item._id)
      );
      state.api.users.count -= users.length;
    },
    removePedido(state, pedido) {
      state.pdv.pedidos.data = state.pdv.pedidos.data.filter(
        (item) => item._id != pedido
      );
      state.pdv.pedidos.count -= 1;
    },
    alterPedido(state, pedido) {
      const index = state.pdv.pedidos.data
        .map(function(e) {
          return e._id;
        })
        .indexOf(pedido._id);

      if (index != -1) state.pdv.pedidos.data[index] = pedido;
    },
    alterEntrada(state, entrada) {
      const index = state.api.entradas.data.findIndex(
        (item) => item._id == entrada._id
      );
      state.api.entradas.data[index].status = true;
      state.api.entradas.data[index].data_fechamento = entrada.data_fechamento;
    },

    // Carrinho
    addToCart(state, item) {
      const found = state.cliente.carrinho.find(
        (prod) => prod.item._id == item._id
      );
      if (found) found.qtde += 1;
      else
        state.cliente.carrinho.push({
          item,
          qtde: 1,
        });

      this.commit("saveCart");
    },
    removeFromCart(state, item) {
      const store = state.cliente.carrinho;
      const found = store
        .map(function(e) {
          return e.item._id;
        })
        .indexOf(item);
      if (store[found].qtde == 1) store.splice(found, 1);
      else store[found].qtde -= 1;

      this.commit("saveCart");
    },
    saveCart(state) {
      window.localStorage.setItem(
        "cart",
        JSON.stringify(state.cliente.carrinho)
      );
    },
    clearCart(state) {
      window.localStorage.removeItem("cart");
      state.cliente.carrinho = [];
    },
    setProduto(state, payload) {
      state.cadastro.produto = payload;

      window.localStorage.setItem(
        "product",
        JSON.stringify(state.cadastro.produto)
      );
    },
    clearProduto(state) {
      window.localStorage.removeItem("product");
      state.cadastro.produto = [];
    },

    // Fornecedor
    clearFornecedor(state) {
      state.fornecedor.cpf_cnpj = "";
      state.fornecedor.restaurantes.data = [];
      state.fornecedor.restaurantes.count = null;
    },
    clearFornecedorPedido(state) {
      state.fornecedor.pedido.produtos = [];
    },
    setRestaurantId(state, payload) {
      state.fornecedor.pedido.restaurantId = payload;
    },
    addFornecedorPedido(state, payload) {
      state.fornecedor.pedido.produtos.push(payload);
    },
    removeFornecedorPedido(state, payload) {
      let produtos = state.fornecedor.pedido.produtos;
      const index = produtos.findIndex(
        (item) => item.product.id == payload.toString()
      );
      produtos = produtos.splice(index, 1);
    },
    editProduct(state, payload) {
      state.fornecedor.pedido.produtos[payload.index] = payload.product;
    },
  },
  actions: {
    checkProduct({ state }, id) {
      const exists = state.fornecedor.pedido.produtos.findIndex(
        (x) => x.product.id == id
      );

      return exists;
    },
  },
});

export default store;
