<template>
  <div id="some-features">
    <img src="../../assets/home/girl.png" alt="Girl" class="girl" />
    <img
      src="../../assets/home/features_simplified.png"
      alt="Features"
      class="features"
    />
    <img src="../../assets/home/pattern.png" class="pattern" />

    <div class="text">
      <h1>Simplifique e automatize</h1>
      <ul>
        <li>Controle de estoque</li>
        <li>Ponto de vendas</li>
        <li>Vendas online com Mercado Pago</li>
        <li>Cardápio Interativo</li>
        <li>Cálculo de Gorjeta</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SomeFeatures",
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#some-features {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primary-ten;
  height: 360px;
  position: relative;
  margin-top: 168px;
  z-index: 1000;

  .text {
    width: 326px;
    margin-left: 110px;

    h1 {
      font: 700 48px "League Spartan", sans-serif;
      color: $primary;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-type: "- ";
      }
    }
  }

  .girl {
    z-index: 1000;
  }

  .pattern {
    position: absolute;
    margin-right: 300px;
    user-select: none;
    z-index: -1;
  }

  @media (max-width: 1280px) {
    .girl {
      margin-left: -128px;
    }
  }

  @media (max-width: 1279px) {
    .girl {
      display: none;
    }

    .text {
      margin-left: 32px;

      h1 {
        font-size: 36px;

        ul {
          li {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
