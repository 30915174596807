<template lang="html">
  <div id="pedidos-wrapper" v-if="mesa">
    <h3>{{ mesa.apelido }}</h3>
    <h4>
      Mesas:
      <sui-label v-for="n in mesa.mesa" :key="n">
        {{ n }}
      </sui-label>
    </h4>
    <div class="pedido-segment">
      <sui-button
        label-position="left"
        icon="arrow left"
        content="Voltar"
        basic
        @click="$router.push('/mesas')"
      />
      <sui-button
        label-position="left"
        :icon="addActive ? 'minus' : 'plus'"
        :content="
          addActive && !editItemShow ? 'Esconder formulário' : 'Adicionar item'
        "
        color="teal"
        :disabled="editItemShow"
        @click="addActive = !addActive"
      />
      <sui-segment v-show="addActive && !editItemShow">
        <sui-form @submit="addItem">
          <sui-form-fields>
            <sui-form-field width="twelve">
              <label>Produto</label>
              <sui-dropdown
                :options="produtos"
                placeholder="Produto"
                search
                selection
                v-model="produto"
              />
            </sui-form-field>
            <sui-form-field width="four">
              <label>Quantidade</label>
              <input
                placeholder="Qtde"
                type="number"
                :disabled="produto == null ? true : false"
                v-model="newItem.qtde"
                min="1"
              />
            </sui-form-field>
          </sui-form-fields>
          <sui-form-fields>
            <sui-form-field width="twelve">
              <label>Observação</label>
              <input placeholder="Observação" v-model="newItem.observacao" />
            </sui-form-field>
            <sui-form-field width="four">
              <label>Valor</label>
              <input
                placeholder="Valor"
                readonly
                :value="
                  newItem.qtde
                    ? handleCurrency(newItem.qtde * newItem.valor)
                    : 'R$0,00'
                "
              />
            </sui-form-field>
          </sui-form-fields>
          <sui-button
            icon="plus"
            color="teal"
            content="Adicionar"
            label-position="left"
            type="submit"
            :loading="loadingAdd"
          />
        </sui-form>
      </sui-segment>
      <sui-segment v-show="editItemShow">
        <sui-form @submit="editItem">
          <sui-form-field>
            <label>Produto</label>
            <p>{{ selectedItem.item.titulo }}</p>
          </sui-form-field>
          <sui-form-fields>
            <sui-form-field width="eight">
              <label>Quantidade</label>
              <input
                placeholder="Qtde"
                type="number"
                v-model="selectedItem.qtde"
                min="0"
              />
            </sui-form-field>
            <sui-form-field width="eight">
              <label>Valor</label>
              <input
                placeholder="Valor"
                readonly
                :value="
                  selectedItem.qtde
                    ? handleCurrency(
                        selectedItem.qtde * selectedItem.item.valor_venda
                      )
                    : 'R$0,00'
                "
              />
            </sui-form-field>
          </sui-form-fields>
          <sui-button
            icon="times"
            basic
            content="Cancelar"
            label-position="left"
            @click="editItemShow = false"
            type="button"
          />
          <sui-button
            icon="edit"
            color="teal"
            content="Editar"
            label-position="left"
            type="submit"
            :loading="loadingAdd"
          />
        </sui-form>
      </sui-segment>
      <sui-table striped unstackable selectable class="tablemobile">
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell>Cod</sui-table-header-cell>
            <sui-table-header-cell>Qtde</sui-table-header-cell>
            <sui-table-header-cell>Produto</sui-table-header-cell>
            <sui-table-header-cell>Valor</sui-table-header-cell>
            <sui-table-header-cell></sui-table-header-cell>
          </sui-table-row> </sui-table-header
        ><sui-table-body>
          <sui-table-row v-for="pedido in mesa.pedidos" :key="pedido._id"
            ><sui-table-cell>{{ pedido.item.codigo }}</sui-table-cell>
            <sui-table-cell>{{ pedido.qtde }}</sui-table-cell>
            <sui-table-cell>{{ pedido.item.titulo }}</sui-table-cell>
            <sui-table-cell>{{ handleCurrency(pedido.valor) }}</sui-table-cell>
            <sui-table-cell>
              <sui-button
                basic
                icon="edit"
                @click="(selectedItem = pedido), (editItemShow = true)"
              />
              <sui-button
                basic
                icon="ellipsis horizontal"
                @click="(selectedItem = pedido), (obsVisible = true)"
              />
            </sui-table-cell> </sui-table-row></sui-table-body
      ></sui-table>
      <b>Total: {{ handleCurrency(mesa.total) }}</b> <br />

      <sui-button-group class="button-group">
        <sui-button color="teal" content="Pagar" @click="goToPagamento" />
        <sui-button color="teal" basic content="Imprimir" />
        <sui-button
          color="teal"
          content="Finalizar"
          @click="finalizar"
          :loading="loadingFinal"
        />
      </sui-button-group>
    </div>

    <sui-modal v-model="obsVisible" size="mini">
      <sui-modal-header>{{ selectedItem.item.titulo }}</sui-modal-header>
      <sui-modal-content
        ><p v-if="selectedItem.observacao">
          {{ selectedItem.observacao }}
        </p>
        <p v-else>Este pedido não possui nenhuma observação</p>
      </sui-modal-content>
    </sui-modal>
    <sui-modal v-model="cancelModal" size="mini">
      <sui-modal-header>Deletar item?</sui-modal-header>
      <sui-modal-content>
        <p>Você tem certeza que quer remover este item?</p>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button
          content="Cancelar"
          @click="cancelModal = false"
          type="button"
        />
        <sui-button
          color="red"
          content="Deletar"
          @click="deleteItem"
          type="button"
          :loading="loadingAdd"
        />
      </sui-modal-actions>
    </sui-modal>
  </div>
  <div v-else>
    <sui-loader active />
  </div>
</template>

<script>
import {
  getPedidos,
  getMesa,
  addPedido,
  finalizarMesa,
  editPedido,
} from "../../services/pdvService";
import { getCardapio } from "../../services/apiService";
import { currency, toast } from "../../helpers";

export default {
  name: "Mesas",
  data() {
    return {
      mesa: null,
      addActive: false,
      newItem: {},
      produtos: [],
      produto: null,
      newItemCod: null,
      obsVisible: false,
      selectedItem: {
        item: {},
      },
      loadingAdd: false,
      loadingFinal: false,
      editItemShow: false,
      cancelModal: false,
    };
  },
  watch: {
    produto(newValue, oldValue) {
      if (!newValue) return;
      const item = this.produtos.find((x) => x.value == newValue);
      this.newItem = {
        qtde: 1,
        item: item.value,
        valor: item.cost,
      };
      this.newItemCod = item.cod;
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    let mesa;
    // if (this.$store.state.pdv.pedidos.count == null) await getPedidos();
    await getPedidos();
    mesa = this.$store.state.pdv.pedidos.data.find((x) => x._id == id);

    this.mesa = mesa;

    if (this.$store.state.api.cardapio.count == null) await getCardapio();
    this.$store.state.api.cardapio.data.forEach((item) => {
      this.produtos.push({
        text: `${item.codigo} - ${item.titulo}`,
        value: item._id,
        cost: item.valor_venda,
        cod: item.codigo,
      });
    });
  },
  methods: {
    handleCurrency(amount) {
      return currency(amount);
    },
    async addItem(e) {
      e.preventDefault();
      this.loadingAdd = true;
      if (Object.keys(this.newItem).length === 0) {
        toast("error", "Insira um produto!");
        return;
      }

      this.newItem.valor = this.newItem.valor * this.newItem.qtde;
      this.newItem.qtde = parseInt(this.newItem.qtde);
      const data = {
        id: this.$route.params.id,
        pedido: this.newItem,
      };
      const pedido = await addPedido(data);
      this.$store.commit("alterPedido", pedido);

      this.mesa = pedido;
      for (const prop of Object.getOwnPropertyNames(this.newItem))
        delete this.newItem[prop];

      this.produto = "";
      this.addActive = null;
      this.loadingAdd = false;
    },
    async finalizar() {
      this.loadingFinal = true;
      const { id } = this.$route.params;
      const res = await finalizarMesa(id);
      this.loadingFinal = false;
      if (res) this.$router.push({ name: "mesas" });
    },
    goToPagamento() {
      const { id } = this.$route.params;
      this.$router.push({ name: "pagamento", params: { id } });
    },
    async editItem(e) {
      e.preventDefault();

      if (this.selectedItem.qtde == 0 && !this.cancelModal) {
        this.cancelModal = true;
        return;
      }

      this.loadingAdd = true;

      const pedidos = this.mesa.pedidos.map((pedido) => {
        return {
          ...pedido,
          item: pedido.item._id,
          valor: pedido.qtde * pedido.item.valor_venda,
        };
      });

      const total = pedidos
        .map((item) => item.valor)
        .reduce((prev, curr) => prev + curr, 0);

      const newMesa = { ...this.mesa, pedidos, total, id: this.mesa._id };

      const res = await editPedido(newMesa);

      if (res) this.mesa = res;
      this.loadingAdd = false;
      this.editItemShow = false;
    },
    async deleteItem(e) {
      e.preventDefault();
      const selected = this.selectedItem;
      const index = this.mesa.pedidos
        .map(function(item) {
          return item._id;
        })
        .indexOf(selected._id);

      let pedidos = this.mesa.pedidos;
      pedidos.splice(index, 1);

      pedidos = pedidos.map((pedido) => {
        return {
          ...pedido,
          item: pedido.item._id,
          valor: pedido.qtde * pedido.item.valor_venda,
        };
      });

      const total = pedidos
        .map((item) => item.valor)
        .reduce((prev, curr) => prev + curr, 0);

      const newMesa = { ...this.mesa, pedidos, total, id: this.mesa._id };

      const res = await editPedido(newMesa);

      if (res) this.mesa = res;
      this.loadingAdd = false;
      this.editItemShow = false;
      this.cancelModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#pedidos-wrapper {
  padding: 2rem;
  h3,
  h4 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  b,
  .button-group {
    float: right;
  }
  .top-segment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    .ui.input {
      width: 30rem;
    }
  }
  .pedido-segment {
    margin: auto;
    width: 50%;
  }

  table {
    table-layout: fixed;
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  @media screen and(max-width: 768px) {
    .pedido-segment {
      width: 100%;
    }
    .tablemobile {
      overflow-x: auto;
      display: block;
    }
    .footer-buttons {
      > * {
        margin: 0.1rem;
      }
    }
  }
}
</style>
