<template>
  <div id="home">
    <Navbar />

    <Hero />

    <TryUs imgShow />

    <SomeFeatures />

    <WhyChooseUs ref="how-it-works" />

    <div id="features-detailed" ref="functionalities">
      <FeatureDetails
        title="Acompanhe todos pedidos de mesa"
        content="Faça o lançamento, visualização e edição de todo os pedidos de forma simples."
        :vector="1"
        img="orders"
      />
      <FeatureDetails
        title="100% Online"
        content="Os garçons podem ter acesso as mesas na palma da mão, sem precisar entregar a comanda ao balcão. Não precisa de instalação e funciona em múltiplos dispositivos simultaneamente."
        :vector="2"
        img="phone"
        secondary
      />
      <FeatureDetails
        title="Cálculo de gorgetas automático"
        content="Informe suas gorgetas por mesa no sistema e gere um relatório detalhado ao final de cada mês."
        :vector="3"
        img="calc"
      />
    </div>

    <Clients />

    <div id="try-us-2">
      <img src="../assets/home/chef.png" alt="Chef" />

      <TryUs class="try" />
    </div>

    <Footer />

    <!-- <img src="../assets/home/top-left.svg" class="top-left-img unselectable" />
    <img
      src="../assets/home/top-right.svg"
      class="top-right-img unselectable"
    />
    <img
      src="../assets/home/bottom-left.svg"
      class="bottom-left-img unselectable"
    /> -->
  </div>
</template>

<script>
import Navbar from "../components/Home/Navbar";
import Hero from "../components/Home/Hero";
import TryUs from "../components/Home/TryUs";
import SomeFeatures from "../components/Home/SomeFeatures";
import WhyChooseUs from "../components/Home/WhyChooseUs";
import Clients from "../components/Home/Clients";
import Footer from "../components/Home/Footer";

import FeatureDetails from "../components/Home/FeatureDetails";

export default {
  name: "Home",
  components: {
    Navbar,
    Hero,
    TryUs,
    SomeFeatures,
    FeatureDetails,
    Clients,
    Footer,
    WhyChooseUs,
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";

#home {
  font-family: Inter, sans-serif;
  font-size: 16px;
  background: $white;
  color: $black;
  position: relative;
  max-width: 100vw;
  /* overflow-x: hidden; */

  margin: 0;

  #features-detailed {
    margin: 0 190px;
    margin-top: 130px;
  }

  #try-us-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      margin-left: 76px;
    }

    .try {
      margin-right: 116px;
    }

    @media (max-width: 1279px) {
      flex-direction: column;
      width: 100%;

      .try {
        width: 100%;
        margin: 0;
      }

      img {
        margin: 0;
        margin-bottom: -150px;
      }
    }
  }

  .top-left-img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .top-right-img {
    position: absolute;
    top: 0;
    right: 0;
  }

  .bottom-left-img {
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .unselectable {
    user-select: none;
  }
}
</style>
