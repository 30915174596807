<template>
  <textarea
    :placeholder="placeholder"
    rows="8"
    :value="value"
    @input="$emit('input', $event.target.value)"
    :required="required"
  ></textarea>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    placeholder: String,
    value: String,
    required: Boolean,
  },
};
</script>

<style lang="scss" scoped>
textarea {
  outline: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  padding: 0.67857143em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  line-height: 1.21428571em;
  font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
  resize: none;
  width: 100%;

  ::placeholder {
    color: rgba(191, 191, 191, 0.87);
  }
  :focus {
    border-color: #85b7d9;
    color: rgba(0, 0, 0, 0.8);
  }
  :focus::placeholder {
    color: rgba(115, 115, 115, 0.87);
  }
}
</style>
