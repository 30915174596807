<template>
  <div id="auth-wrapper">
    <sui-form @submit="handleLogin">
      <h3>Login</h3>
      <sui-form-field>
        <input
          placeholder="Email"
          icon-position="left"
          type="email"
          icon="user"
          v-model="login.email"
          required
      /></sui-form-field>
      <sui-form-field>
        <label v-show="caps">O caps lock está ativado!</label>
        <div id="password-container">
          <input
            placeholder="Senha"
            icon-position="left"
            icon="lock"
            required
            v-model="login.senha"
            @keydown="handleKeyDown"
            :type="seePassword ? 'text' : 'password'"
          />
          <sui-icon
            name="eye"
            class="toggle-password"
            size="large"
            :color="seePassword ? 'grey' : 'black'"
            @click="seePassword = !seePassword"
          />
        </div>
      </sui-form-field>

      <router-link to="/registrar">
        Ainda não possui cadastro? Clique aqui!
      </router-link>

      <sui-button primary type="submit" :loading="loading">Login</sui-button>
    </sui-form>
  </div>
</template>

<script>
import api from "../services/api";
export default {
  name: "Auth",
  data() {
    return {
      login: { email: "", senha: "" },
      loading: false,
      caps: false,
      seePassword: false,
    };
  },
  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      const role = localStorage.getItem("role");

      if (role == "Admin") this.$router.push("/admin");
      else if (role == "Fornecedor") {
        const cpf_cnpj = localStorage.getItem("cpf_cnpj");
        if (cpf_cnpj) this.$router.push("/fornecedor/restaurantes");
      } else {
        const tenant = localStorage.getItem("tenant");
        const tenantSlug = localStorage.getItem("tenantSlug");
        if (tenant && tenantSlug) this.$router.push("/mesas");
      }
    }
  },
  methods: {
    async handleLogin(e) {
      e.preventDefault();
      this.loading = true;
      try {
        const data = { email: this.login.email, senha: this.login.senha };
        const response = await api.post("admin/login", data);
        if (response) {
          let data = response.data;
          localStorage.setItem("token", data.token);
          localStorage.setItem("role", data.user.role);

          if (localStorage.getItem("role") == "Fornecedor") {
            localStorage.setItem("cpf_cnpj", data.user.fornecedor.cpf_cnpj);
            this.$router.push("/fornecedor/restaurantes");
          }

          if (localStorage.getItem("role") != "Admin") {
            localStorage.setItem("tenant", data.user.restaurantId._id);
            localStorage.setItem("tenantSlug", data.user.restaurantId.slug);
            this.$router.push("/mesas");
          }

          this.$router.push("/admin");
        }
      } catch (err) {
        this.loading = false;
        $("body").toast({
          class: "error",
          position: "bottom left",
          message: err.response.data.error,
        });
        // console.clear();
      }
    },
    handleKeyDown(e) {
      this.caps = !!e.getModifierState("CapsLock");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/global.scss";

#auth-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h3 {
    margin-bottom: 2rem;
  }
  p {
    cursor: pointer;
  }
  label {
    color: red;
  }
  .ui.form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  input,
  .ui.button {
    width: 20rem;
  }
  button {
    margin-top: 1rem;
  }

  #password-container {
    display: flex;
    align-items: center;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    width: 20rem;

    input {
      border: 0;
    }

    .toggle-password {
      cursor: pointer;
      margin-right: 1rem;
    }
  }
}
</style>
