<template>
  <sui-table basic="very" celled>
    <sui-table-body>
      <sui-table-row
        v-for="item in this.$store.state.cliente.carrinho"
        :key="item.item._id"
      >
        <sui-table-cell>{{ item.item.titulo }}</sui-table-cell>
        <sui-table-cell>{{
          currency(item.qtde * item.item.valor_venda)
        }}</sui-table-cell>
      </sui-table-row>
      <sui-table-row>
        <sui-table-cell
          ><sui-icon name="motorcycle" />
          <b>Frete</b>
        </sui-table-cell>
        <sui-table-cell
          ><b>{{ currency(frete) }}</b></sui-table-cell
        >
      </sui-table-row>
      <sui-table-row>
        <sui-table-cell
          ><sui-icon name="money bill wave" />
          <b>Total</b>
        </sui-table-cell>
        <sui-table-cell
          ><b>{{ currency(total) }}</b></sui-table-cell
        >
      </sui-table-row>
    </sui-table-body>
  </sui-table>
</template>

<script>
export default {
  name: "Total",
  props: {
    frete: Number
  },
  data() {
    return {
      total: 0
    };
  },
  methods: {
    currency(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(amount);
    }
  },
  mounted() {
    this.$store.state.cliente.carrinho.forEach(item => {
      this.total += item.qtde * item.item.valor_venda;
    });
    this.total += this.frete; //frete
    this.$emit("valorTotal", this.total);
  }
};
</script>
