<template>
  <div id="feature-details" :class="secondary ? 'secondary' : ''">
    <div class="imgs">
      <img
        :src="require(`../../assets/home/feature-details/${img}.png`)"
        class="detail-img"
      />

      <img
        :src="
          require(`../../assets/home/feature-details/vectors/v${vector}.svg`)
        "
        class="vector"
      />
    </div>
    <div class="text">
      <h1>{{ title }}</h1>
      <p>{{ content }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureDetails",
  props: {
    secondary: { type: Boolean, default: false },
    title: String,
    content: String,
    img: String,
    vector: { type: Number, default: 1 },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#feature-details {
  display: flex;
  margin-bottom: 40px;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .imgs {
    margin-left: 0;
    margin-right: 90px;

    position: relative;

    .detail-img {
      position: relative;
      top: 0;
      left: 0;
    }

    .vector {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .text {
    max-width: 600px;

    h1 {
      font: 700 48px "League Spartan", sans-serif;
    }

    p {
      font-size: 24px;
      opacity: 0.6;
    }
  }

  &.secondary {
    flex-direction: row-reverse;

    .imgs {
      margin-right: 0;
    }
  }

  @media (max-width: 1280px) {
    .text {
      h1 {
        font-size: 36px !important;
      }

      p {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 1279px) {
    flex-direction: column;
    text-align: center;

    &.secondary {
      flex-direction: column;
    }

    .text {
      width: 100vw;
      margin: 0 32px;
      margin-top: 28px;
      margin-bottom: 40px;

      p {
        font-size: 16px;
        margin: 0 32px;
      }
    }

    .imgs {
      transform: scale(0.8);
      margin: 0;
      margin-bottom: 28px;
    }
  }
}
</style>
