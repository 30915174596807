<template>
  <sui-form>
    <sui-form-fields grouped>
      <sui-form-field>
        <sui-checkbox
          label="Pagamento ao receber (dinheiro ou cartão)"
          radio
          value="6"
          v-model="comoPagar"
        />
      </sui-form-field>
      <sui-form-field>
        <sui-checkbox
          label="Pagamento online (cartão)"
          radio
          value="7"
          v-model="comoPagar"
        />
      </sui-form-field>
    </sui-form-fields>
  </sui-form>
</template>

<script>
export default {
  name: "ComoPagar",
  data() {
    return {
      comoPagar: "6"
    };
  },
  watch: {
    comoPagar() {
      this.$emit("pagamento", this.comoPagar);
    }
  }
};
</script>
