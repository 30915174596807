<template>
  <div>
    <div class="fornecedores">
      <div v-if="!value.fornecedores" class="no-fornecedor">
        <p>
          Esse produto ainda não possui nenhum fornecedor. Clique no botão
          abaixo e cadastre agora mesmo.
        </p>
        <sui-button
          content="Adicionar fornecedor"
          icon="plus"
          label-position="right"
          color="teal"
          @click="isModalVisible = true"
        />
      </div>

      <sui-table celled basic="very" v-else>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell
              v-for="column in this.tableColumns"
              :key="column"
              >{{ column }}</sui-table-header-cell
            >
          </sui-table-row>
        </sui-table-header>
        <sui-table-body>
          <sui-table-row
            v-for="fornecedor in value.fornecedores"
            :key="fornecedor.codigo"
          >
            <sui-table-cell>{{ fornecedor.nome_razao }}</sui-table-cell>
            <sui-table-cell>{{ fornecedor.codigo_fornecedor }}</sui-table-cell>
            <sui-table-cell>{{
              fornecedor.descricao_fornecedor
            }}</sui-table-cell>
          </sui-table-row>
        </sui-table-body>
        <sui-table-footer>
          <sui-table-row>
            <sui-table-header-cell colspan="3">
              <sui-button
                color="teal"
                floated="right"
                icon="plus"
                label-position="left"
                content="Adicionar fornecedor"
                @click="isModalVisible = true"
              />
            </sui-table-header-cell>
          </sui-table-row>
        </sui-table-footer>
      </sui-table>
    </div>

    <sui-modal v-model="isModalVisible" size="small">
      <sui-modal-header>Adicionar fornecedor</sui-modal-header>
      <sui-modal-content class="fornecedor-form">
        <sui-dropdown
          selection
          :placeholder="selectedFornecedor.nome_razao"
          @click="getFornecedores"
        >
          <sui-dropdown-menu>
            <sui-dropdown-item
              v-for="fornecedor in $store.state.api.fornecedores.data"
              :key="fornecedor.codigo"
              @click="selectFornecedor(fornecedor)"
              >{{ fornecedor.nome_razao }}</sui-dropdown-item
            >
          </sui-dropdown-menu>
        </sui-dropdown>
        <sui-input
          placeholder="Código"
          disabled
          v-model="selectedFornecedor.codigo_fornecedor"
        />
        <sui-input
          placeholder="Descrição"
          v-model="selectedFornecedor.descricao_fornecedor"
        />
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click="isModalVisible = false">Cancelar</sui-button>
        <sui-button color="teal" @click="addFornecedor">Salvar</sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { getFornecedores } from "../../services/apiService";

export default {
  name: "Geral",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fornecedores: [],
      tableColumns: ["Fornecedor", "Código", "Descrição"],
      isModalVisible: false,
      selectedFornecedor: {
        nome_razao: "Fornecedor",
        id_fornecedor: null,
        codigo_fornecedor: null,
        descricao_fornecedor: null,
      },
    };
  },
  methods: {
    selectFornecedor(fornecedor) {
      this.selectedFornecedor = {
        nome_razao: fornecedor.nome_razao,
        id_fornecedor: fornecedor._id,
        codigo_fornecedor: fornecedor.codigo,
      };
    },
    addFornecedor() {
      if (!this.value.fornecedores) this.value.fornecedores = [];
      this.value.fornecedores.push(this.selectedFornecedor);
      this.isModalVisible = false;
      this.$emit("input", this.value);
    },
    async getFornecedores() {
      await getFornecedores();
    },
  },
};
</script>

<style lang="scss" src="../../styles/components/cadastroProdutos.scss" scoped />
