<template>
  <div id="footer">
    <div class="logo">
      <img src="../../assets/logo_white.svg" />
      <p>© 2022 Corneria Erp</p>
    </div>

    <div class="navigation">
      <span class="nav-link" @click="scroll(null)">Início</span>
      <span class="nav-link" @click="scroll('how-it-works')">
        Como funciona
      </span>
      <span class="nav-link" @click="scroll('functionalities')">
        Funcionalidades
      </span>
      <router-link class="nav-link" to="/login">
        <Button secondary text="Entrar" />
      </router-link>
    </div>
  </div>
</template>

<script>
import Button from "./Button";

export default {
  name: "Footer",
  components: { Button },
  methods: {
    scroll(ref) {
      if (!ref) document.body.scrollTop = 0;
      else {
        const element = this.$parent.$refs[ref];

        if (element.$el) element.$el.scrollIntoView({ behavior: "smooth" });
        else element.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#footer {
  display: flex;
  justify-content: space-between;
  padding: 30px 122px;
  background: $primary;
  margin-top: 86px;

  .logo {
    p {
      margin-top: 18px;
      color: $white;
    }
  }

  .navigation {
    display: flex;
    align-items: center;

    .nav-link {
      list-style-type: none;
      margin: 0 32px;
      cursor: pointer;
      font: 300 14px Inter, sans-serif;
      color: $white;
    }
  }

  @media (max-width: 1279px) {
    justify-content: center;

    .logo {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .navigation {
      display: none;
    }
  }
}
</style>
