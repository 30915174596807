<template>
  <div id="ficha-fornecedor-wrapper">
    <h3>Ficha de Fornecedor</h3>
    <form @submit="alterFornecedor">
      <div>
        <h3>Dados Cadastrais</h3>
        <sui-grid>
          <sui-grid-row>
            <sui-grid-column :width="4"
              ><sui-dropdown
                placeholder="Tipo de pessoa *"
                selection
                v-model="cadastro.tipo_pessoa"
                :options="tipo_pessoa_options"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input
                placeholder="CPF/CNPJ *"
                v-model="cadastro.cpf_cnpj"
                required
            /></sui-grid-column>
            <sui-grid-column :width="8"
              ><sui-input
                placeholder="Nome/Razão *"
                required
                v-model="cadastro.nome_razao"
            /></sui-grid-column>
          </sui-grid-row>
          <sui-grid-row>
            <sui-grid-column :width="4"
              ><sui-input placeholder="Fantasia" v-model="cadastro.fantasia"
            /></sui-grid-column>
            <sui-grid-column :width="2"
              ><sui-input
                placeholder="Código *"
                v-model="cadastro.codigo"
                required
            /></sui-grid-column>
            <sui-grid-column :width="6">
              <sui-dropdown
                placeholder="Contribuinte"
                selection
                v-model="cadastro.str_contribuinte_icms"
                :options="tipo_contribuinte"
              />
            </sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input
                placeholder="Número do contribuinte"
                v-model="cadastro.num_contribuinte_icms"
            /></sui-grid-column>
          </sui-grid-row>
        </sui-grid>
      </div>
      <sui-divider />
      <div>
        <h3>Endereço</h3>
        <sui-grid>
          <sui-grid-row>
            <sui-grid-column :width="4"
              ><sui-input placeholder="CEP" v-model="cadastro.cep"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input placeholder="Estado" v-model="cadastro.estado"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input placeholder="Cidade" v-model="cadastro.cidade"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input placeholder="Bairro" v-model="cadastro.bairro"
            /></sui-grid-column>
          </sui-grid-row>
          <sui-grid-row>
            <sui-grid-column :width="6"
              ><sui-input placeholder="Rua" v-model="cadastro.rua"
            /></sui-grid-column>
            <sui-grid-column :width="2"
              ><sui-input placeholder="Número" v-model="cadastro.numero"
            /></sui-grid-column>
            <sui-grid-column :width="8"
              ><sui-input
                placeholder="Complemento"
                v-model="cadastro.complemento"
            /></sui-grid-column>
          </sui-grid-row>
        </sui-grid>
      </div>
      <sui-divider />
      <div>
        <h3>Contato</h3>
        <sui-grid>
          <sui-grid-row>
            <sui-grid-column :width="4"
              ><sui-input
                placeholder="Responsável"
                v-model="cadastro.responsavel"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input placeholder="Telefone" v-model="cadastro.telefone"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input placeholder="E-mail" v-model="cadastro.email"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input placeholder="Website" v-model="cadastro.website"
            /></sui-grid-column>
          </sui-grid-row>
        </sui-grid>
      </div>
      <sui-divider />
      <div>
        <h3>Dados Adicionais</h3>
        <sui-grid>
          <sui-grid-row>
            <sui-grid-column :width="6"
              ><sui-dropdown
                placeholder="Tipo de contato"
                selection
                v-model="cadastro.tipo_contato"
                :options="tipo_contato"
            /></sui-grid-column>
            <sui-grid-column :width="6"
              ><sui-dropdown
                placeholder="Situação"
                selection
                v-model="cadastro.situacao"
                :options="tipo_situacao"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input
                required
                placeholder="Tempo de reposição *"
                v-model="cadastro.tempo_reposicao"
            /></sui-grid-column>
          </sui-grid-row>
        </sui-grid>
      </div>
      <sui-divider />
      <div>
        <h3>Observações</h3>
        <TextArea v-model="cadastro.observacao"></TextArea>
      </div>
      <sui-button
        icon="save"
        label-position="left"
        content="Salvar"
        color="teal"
        class="salvar"
        type="submit"
        :loading="loading"
      />
    </form>
  </div>
</template>

<script>
import { getFornecedor, putFornecedor } from "../../services/apiService";

import TextArea from "../../components/TextArea";

export default {
  name: "DetalhesFornecedor",
  components: {
    TextArea,
  },
  data() {
    return {
      tipo_pessoa_options: [
        { text: "Física", value: "Física" },
        { text: "Jurídica", value: "Jurídica" },
      ],
      tipo_contribuinte: [
        { text: "Contribuinte ICMS", value: "Contribuinte ICMS" },
        {
          text: "Contribuinte isento de Inscrição no Cadastro de Contribuintes",
          value:
            "Contribuinte isento de Inscrição no Cadastro de Contribuintes",
        },
        {
          text:
            "Não contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes",
          value:
            "Não contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes",
        },
      ],
      tipo_contato: [
        { text: "Cliente", value: "Cliente" },
        { text: "Fornecedor", value: "Fornecedor" },
        { text: "Outros", value: "Outros" },
      ],
      tipo_situacao: [
        { text: "Ativo", value: "Ativo" },
        { text: "Inativo", value: "Inativo" },
      ],
      cadastro: {},
      loading: false,
    };
  },
  methods: {
    async alterFornecedor(e) {
      e.preventDefault();
      this.loading = true;
      const res = await putFornecedor(this.cadastro);
      this.loading = false;
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    let fornecedor;
    if (this.$store.state.api.fornecedores.count == null)
      fornecedor = await getFornecedor(id);
    else
      fornecedor = this.$store.state.api.fornecedores.data.find(
        (x) => x._id == id
      );
    this.cadastro = fornecedor;
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#ficha-fornecedor-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .ui.input,
  .ui.dropdown {
    width: 100%;
  }
  .salvar {
    float: right;
    margin-top: 1rem;
  }
}
</style>
