<template>
  <div id="hero">
    <div class="texts">
      <h1>
        Cornéria automatiza e facilita a <span>gestão de restaurantes</span>
      </h1>
      <p>
        Tenha mais controle e organização sobre seu estoque, vendas online e
        delivery. <br />
        Tudo em um só lugar!
      </p>
      <Button text="Comece já" big />
    </div>
    <img src="../../assets/home/hero.png" alt="Hero Image" />
  </div>
</template>

<script>
import Button from "./Button.vue";

export default {
  name: "Hero",
  components: { Button },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#hero {
  display: flex;
  position: relative;
  margin-left: 164px;

  .texts {
    width: 590px;
    margin-top: 195px;

    h1 {
      font: 700 64px "League Spartan", sans-serif;

      span {
        color: $primary;
      }
    }

    p {
      width: 400px;
      margin: 20px 0;
    }
  }

  img {
    position: absolute;
    right: 0;
  }

  @media (max-width: 1439px) {
    .texts {
      h1 {
        font-size: 48px;
      }

      p {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 0 32px;

    img {
      position: relative;
      height: 296px;
    }

    .texts {
      width: 100%;
      margin: 0;

      h1 {
        font-size: 36px;
      }

      p {
        font-size: 14px;
      }
    }
  }
}
</style>
