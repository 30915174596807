<template>
  <div id="detalhes-estoque-wrapper">
    <h3>{{ info.name }}</h3>
    <sui-form class="date-form" @submit="getData" v-if="!loadingPage">
      <sui-form-fields fields="three">
        <sui-form-field width="six">
          <sui-label basic>Data de início</sui-label>
          <sui-input type="date" v-model="datas.data1" required />
        </sui-form-field>
        <sui-form-field width="six">
          <sui-label basic>Data de fim</sui-label>
          <sui-input type="date" v-model="datas.data2" required />
        </sui-form-field>
        <sui-button icon="search" primary type="submit" :loading="loading" />
      </sui-form-fields>
    </sui-form>

    <div v-else>
      <sui-loader active />
    </div>

    <GChart
      type="LineChart"
      :data="chartData"
      :options="chartOptions"
      v-if="hasChart"
    />
    <p v-else-if="!hasChart && !loadingPage">
      Produto ainda não possui histórico
    </p>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
import { format } from "date-fns";
import {
  getHistoricoEstoque,
  putDetalhamentoProduto
} from "../../services/apiService";
import { date } from "../../helpers";

export default {
  name: "DetalhesEstoque",
  components: { GChart },
  data() {
    return {
      loading: false,
      loadingPage: false,
      historico: [],
      info: {
        name: "Estoque",
        eMax: null,
        eMin: null,
        pr: null
      },
      datas: { data1: null, data2: null },
      chartData: [
        ["Data", "Quantidade", "Emax", "Emin", "Ponto de resuprimento"]
      ],
      hasChart: false,
      chartOptions: {
        hAxis: {
          title: "Tempo"
        },
        vAxis: {
          title: "Quantidade"
        },
        colors: ["#00933b", "#9fd3f4", "#e81e03", "#efb706"],
        series: {
          3: { lineDashStyle: [10, 2] }
        }
      }
    };
  },
  async mounted() {
    this.loadingPage = true;

    let { currentEstoque } = this.$store.state;
    if (!currentEstoque || currentEstoque._id != this.$route.params.id)
      currentEstoque = await putDetalhamentoProduto(this.$route.params.id, 7);
    this.info.name = currentEstoque.id_produto.item;
    this.info.eMax = currentEstoque.estoque_maximo;
    this.info.eMin = currentEstoque.estoque_minimo;
    this.info.pr = currentEstoque.ponto_resuprimento;

    let dateThreeMonthsOld = new Date();
    dateThreeMonthsOld.setMonth(dateThreeMonthsOld.getMonth() - 3);
    dateThreeMonthsOld = format(dateThreeMonthsOld, "yyyy-MM-dd");

    let today = new Date();
    today = format(today, "yyyy-MM-dd");

    const res = await getHistoricoEstoque(
      this.$route.params.id,
      dateThreeMonthsOld,
      today
    );

    if (res) {
      this.loadingPage = false;

      console.log(res);

      if (res.length == 0) return;
      res.forEach(item => {
        this.chartData.push([
          date(item.data, false),
          item.estoque,
          this.info.eMax,
          this.info.eMin,
          this.info.pr
        ]);
      });
      this.hasChart = true;
    }
  },
  methods: {
    async getData(e) {
      e.preventDefault();
      this.loading = true;
      const res = await getHistoricoEstoque(
        this.$route.params.id,
        this.datas.data1,
        this.datas.data2
      );
      res.forEach(item => {
        this.chartData.push([
          date(item.data, false),
          item.estoque,
          this.info.eMax,
          this.info.eMin,
          this.info.pr
        ]);
      });
      this.loading = false;
      this.hasChart = true;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#detalhes-estoque-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .date-form {
    .fields {
      align-items: flex-end;
    }
  }
}
</style>
