import api from "./api";
import { toast } from "../helpers";

export const postRestaurante = async (data) => {
  try {
    const res = await api.post("admin/tenant", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    toast("success", "Restaurante criado com sucesso!");
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getRestaurantes = async () => {
  try {
    const res = await api.get("admin/restaurants", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return res.data.tenants;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const putKey = async (data) => {
  try {
    const res = await api.put("admin/keys", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    toast("success", "Alterações feitas com sucesso!");
    return res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getFornecedores = async () => {
  try {
    const res = await api.get("admin/fornecedores", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};
