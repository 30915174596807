<template>
  <sui-segment raised>
    <div class="seg-header">
      <h4>{{ title }}</h4>
      <sui-icon
        name="plus"
        v-show="!isItem"
        style="cursor:pointer"
        @click="clickIcon(1)"
      />
    </div>
    <sui-divider />
    <sui-segment
      v-for="(item, index) in arr"
      :key="item.value"
      class="item-seg"
      @click="clickSegment(item.value, index)"
      :color="clickedIndex == index ? 'teal' : undefined"
    >
      <b>{{ item.text }}</b>
      <div v-show="!isItem">
        <sui-icon name="pen" @click="clickIcon(2, item.value)" />
        <sui-icon name="trash" @click="clickIcon(3, item.value)" />
      </div>
    </sui-segment>
  </sui-segment>
</template>

<script>
export default {
  name: "ClassificacaoSegment",
  props: {
    title: String,
    arr: Array,
    isItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clickedIndex: null
    };
  },
  methods: {
    clickSegment(id, index) {
      if (index != this.clickedIndex && !this.isItem) {
        this.clickedIndex = index;
        this.$emit("segmentClicked", id);
      }
    },
    clickIcon(option, id) {
      this.$emit("iconClicked", { option, id });
    }
  },
  watch: {
    arr(newVal) {
      if (newVal.length == 0) this.clickedIndex = null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";

.seg-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.item-seg {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.raised-seg {
  color: $dark-primary-color;
}
</style>
