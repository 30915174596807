<template>
  <div
    id="details"
    :class="selected ? 'selected' : ''"
    @click="$emit('switch')"
  >
    <img :src="require(`../../assets/home/carousel/${img}.png`)" />
    <h1>{{ title }}</h1>
    <div class="divider"></div>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "WhyChooseUsDetails",
  props: {
    title: String,
    description: String,
    img: String,
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#details {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $primary-ten;
  height: 515px;

  border-radius: 44px;
  box-shadow: 0px 0px 24px rgba(81, 115, 240, 0.1);
  border: 10px solid $white;
  padding: 20px 52px 42px;
  cursor: pointer;

  max-width: 349px;

  .divider {
    opacity: 0.1;
    width: calc(100% + 104px);
    border: 2px dashed $grey;
    margin: 24px 0;
  }

  h1 {
    font: 700 24px "League Spartan", sans-serif;
    color: $black;
    text-align: center;
  }

  p {
    font-size: 14px;
  }

  &.selected {
    background: $primary;
    padding: 20px 62.5px 33.5px;
    height: 515px;

    .divider {
      border-color: $white;
    }

    h1,
    p {
      color: $white;
    }

    h1 {
      font-size: 28px;
    }
  }
}
</style>
