<template>
  <div id="restaurantes-wrapper">
    <h3>
      <sui-icon name="arrow left" @click="$router.push('/admin')" />
      Restaurantes
    </h3>
    <div>
      <sui-table striped>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell>Restaurante</sui-table-header-cell>
            <sui-table-header-cell text-align="right">
              Ações
            </sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>

        <sui-table-body>
          <sui-table-row v-for="res in restaurantes" :key="res._id">
            <sui-table-cell>{{ res.nome_razao }}</sui-table-cell>
            <sui-table-cell text-align="right">
              <sui-button
                data-tooltip="Acessar restaurante"
                data-inverted=""
                icon="arrow right"
                v-show="res.canAdminAccess"
                @click="accessRestaurant(res)"
              />
            </sui-table-cell>
          </sui-table-row>
        </sui-table-body>
      </sui-table>
    </div>
  </div>
</template>

<script>
import { getRestaurantes } from "../../services/adminService";

export default {
  name: "ListRestaurantes",
  data() {
    return {
      restaurantes: [],
    };
  },
  async mounted() {
    this.restaurantes = await getRestaurantes();
  },
  methods: {
    accessRestaurant(restaurant) {
      if (restaurant.canAdminAccess) {
        localStorage.setItem("role", "Admin/Permission");
        localStorage.setItem("tenant", restaurant._id);
        localStorage.setItem("tenantSlug", restaurant.slug);
        this.$router.push("/mesas");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#restaurantes-wrapper {
  margin: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .icon {
    cursor: pointer;
  }
}
</style>
