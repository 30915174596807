/* eslint-disable prettier/prettier */
import Vue from "vue";
import Router from "vue-router";

import Home from "./pages/Home.vue";

import Auth from "./pages/Auth.vue";
import Registrar from "./pages/Registrar.vue";
import PedidoCompra from "./pages/PedidoCompra.vue";
import Caixa from "./pages/Caixa.vue";
import Estoque from "./pages/Estoque.vue";
import Cozinha from "./pages/Cozinha.vue";
import Gorjeta from "./pages/Gorjeta.vue";
import Classificacao from "./pages/Classificacao.vue";
import Integracoes from "./pages/Integracoes.vue";

import CadastroFichaTecnica from "./pages/Cadastros/CadastroFichaTecnica.vue";
import CadastroProdutos from "./pages/Cadastros/CadastroProdutos.vue";
import CadastroFornecedor from "./pages/Cadastros/CadastroFornecedor.vue";
import CadastroUsuario from "./pages/Cadastros/CadastroUsuario.vue";

import Fornecedores from "./pages/Listagens/Fornecedores.vue";
import Produtos from "./pages/Listagens/Produtos.vue";
import Cardapio from "./pages/Listagens/Cardapio.vue";
import Entradas from "./pages/Listagens/Entradas.vue";
import Usuarios from "./pages/Listagens/Usuarios.vue";

import DetalhesFornecedor from "./pages/Detalhes/DetalhesFornecedor.vue";
import DetalhesProduto from "./pages/Detalhes/DetalhesProduto.vue";
import DetalhesFichaTecnica from "./pages/Detalhes/DetalhesFichaTecnica.vue";
import DetalhesEstoque from "./pages/Detalhes/DetalhesEstoque.vue";
import DetalhesSaidas from "./pages/Detalhes/DetalhesSaidas.vue";

import Mesas from "./pages/PDV/Mesas.vue";
import Pedidos from "./pages/PDV/Pedidos.vue";
import Pagamento from "./pages/PDV/Pagamento.vue";
import PedidosOnline from "./pages/PDV/PedidosOnline.vue";

import CategoriasPublico from "./pages/Cliente/CategoriasPublico.vue";
import CardapioPublico from "./pages/Cliente/CardapioPublico.vue";

import Admin from "./pages/Admin/Admin.vue";
import CadastrarRestaurante from "./pages/Admin/CadastrarRestaurante.vue";
import CadastrarFornecedor from "./pages/Admin/CadastrarFornecedor.vue";
import ListRestaurantes from "./pages/Admin/ListRestaurantes.vue";
import ListFornecedores from "./pages/Admin/ListFornecedores.vue";

import Restaurantes from "./pages/Fornecedor/Restaurantes.vue";
import RestauranteDetalhe from "./pages/Fornecedor/RestauranteDetalhe.vue";
import PedidoDetalhes from "./pages/Fornecedor/PedidoDetalhes.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Home,
      name: "home",
    },
    // Public pages
    {
      path: "/login",
      name: "login",
      component: Auth,
    },
    {
      path: "/registrar",
      name: "registrar",
      component: Registrar,
    },
    {
      path: "/cliente/:tenant",
      name: "cliente-categoria",
      component: CategoriasPublico,
    },
    {
      path: "/cliente/:tenant/cardapio/:categoria",
      name: "cliente-cardapio",
      component: CardapioPublico,
    },
    // Admin pages
    {
      path: "/admin",
      name: "admin",
      component: Admin,
    },
    {
      path: "/admin/novo-restaurante",
      name: "novo-restaurante",
      component: CadastrarRestaurante,
    },
    {
      path: "/admin/novo-fornecedor",
      name: "novo-fornecedor",
      component: CadastrarFornecedor,
    },
    {
      path: "/admin/restaurantes",
      name: "admin-restaurantes",
      component: ListRestaurantes,
    },
    {
      path: "/admin/fornecedores",
      name: "admin-fornecedores",
      component: ListFornecedores,
    },
    // Supplier Pages
    {
      path: "/fornecedor/restaurantes",
      name: "fornecedor-restaurantes",
      component: Restaurantes,
    },
    {
      path: "/fornecedor/restaurantes/:id",
      name: "fornecedor-restaurante-detalhe",
      component: RestauranteDetalhe,
    },
    {
      path: "/fornecedor/detalhes-pedido",
      name: "fornecedor-detalhes-pedido",
      component: PedidoDetalhes,
    },
    // Private pages
    {
      path: "/cadastro-ficha-tecnica",
      name: "cadastro-ficha-tecnica",
      component: CadastroFichaTecnica,
    },
    {
      path: "/cadastro-produtos",
      name: "cadastro-produtos",
      component: CadastroProdutos,
    },
    {
      path: "/cadastro-fornecedor",
      name: "cadastro-fornecedor",
      component: CadastroFornecedor,
    },
    {
      path: "/fornecedores",
      name: "fornecedores",
      component: Fornecedores,
    },
    {
      path: "/fornecedores/:id",
      name: "detalhes-fornecedor",
      component: DetalhesFornecedor,
    },
    {
      path: "/produtos",
      name: "produtos",
      component: Produtos,
    },
    {
      path: "/produtos/:id",
      name: "detalhes-produto",
      component: DetalhesProduto,
    },
    {
      path: "/cardapio",
      name: "cardapio",
      component: Cardapio,
    },
    {
      path: "/cardapio/:id",
      name: "detalhes-cardapio",
      component: DetalhesFichaTecnica,
    },
    {
      path: "/mesas",
      name: "mesas",
      component: Mesas,
    },
    {
      path: "/mesas/:id",
      name: "detalhes-mesa",
      component: Pedidos,
    },
    {
      path: "/pagamento/:id",
      name: "pagamento",
      component: Pagamento,
    },
    {
      path: "/pedidos-online",
      name: "pedidos-online",
      component: PedidosOnline,
    },
    {
      path: "/entradas",
      name: "entradas",
      component: Entradas,
    },
    {
      path: "/pedido-compra",
      name: "pedido-compra",
      component: PedidoCompra,
    },
    {
      path: "/caixa",
      name: "caixa",
      component: Caixa,
    },
    {
      path: "/estoque",
      name: "estoque",
      component: Estoque,
    },
    {
      path: "/estoque/:id",
      name: "detalhes-estoque",
      component: DetalhesEstoque,
    },
    {
      path: "/saidas/:id",
      name: "detalhes-saidas",
      component: DetalhesSaidas,
    },
    {
      path: "/usuarios",
      name: "usuarios",
      component: Usuarios,
    },
    {
      path: "/cadastro-usuario",
      name: "cadastro-usuarios",
      component: CadastroUsuario,
    },
    {
      path: "/cozinha",
      name: "cozinha",
      component: Cozinha,
    },
    {
      path: "/gorjeta",
      name: "gorjeta",
      component: Gorjeta,
    },
    {
      path: "/classificacao",
      name: "classificacao",
      component: Classificacao,
    },
    {
      path: "/integracoes",
      name: "integracoes",
      component: Integracoes,
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "login",
    "cliente-categoria",
    "cliente-cardapio",
    "registrar",
    "home",
  ];
  const adminPages = [
    "admin",
    "novo-restaurante",
    "novo-fornecedor",
    "admin-restaurantes",
    "admin-fornecedores",
  ];
  const fornecedorPages = [
    "fornecedor-restaurantes",
    "fornecedor-restaurante-detalhe",
    "fornecedor-detalhes-pedido",
  ];

  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) next("/login");

  const adminRequired = adminPages.includes(to.name);
  const fornecedorRequired = !fornecedorPages.includes(to.name);

  const role = localStorage.getItem("role");

  if (!adminRequired && role == "Admin") next("/admin");

  if (!adminRequired && fornecedorRequired && role == "Fornecedor")
    next("/fornecedor/restaurantes");

  if (adminRequired && role != "Admin") next("/mesas");

  next();
});

export default router;
