<template>
  <div id="detalhes-estoque-wrapper">
    <h3>{{ info }}</h3>

    <div class="tables" v-if="!loadingPage && saidas.length > 0">
      <div class="">
        <h4>Saídas</h4>
        <sui-table striped collapsing celled class="saidas-table">
          <sui-table-header>
            <sui-table-row>
              <sui-table-header-cell>Data</sui-table-header-cell>
              <sui-table-header-cell>Quantidade</sui-table-header-cell>
            </sui-table-row>
          </sui-table-header>

          <sui-table-body>
            <sui-table-row v-for="saida in saidas" :key="saida._id">
              <sui-table-cell>{{ saida.data }}</sui-table-cell>
              <sui-table-cell
                >{{ saida.quantidade }} {{ unidade }}</sui-table-cell
              >
            </sui-table-row>
          </sui-table-body>
        </sui-table>
      </div>

      <div class="">
        <h4>Entradas</h4>
        <sui-table striped collapsing celled class="saidas-table">
          <sui-table-header>
            <sui-table-row>
              <sui-table-header-cell>Data</sui-table-header-cell>
              <sui-table-header-cell>Quantidade</sui-table-header-cell>
            </sui-table-row>
          </sui-table-header>

          <sui-table-body>
            <sui-table-row v-for="entrada in entradas" :key="entrada._id">
              <sui-table-cell>{{ handleDate(entrada.data) }}</sui-table-cell>
              <sui-table-cell>
                {{ entrada.quantidade }} {{ unidade }}
              </sui-table-cell>
            </sui-table-row>
          </sui-table-body>
        </sui-table>
      </div>
    </div>

    <div v-else-if="loadingPage">
      <sui-loader active />
    </div>

    <p v-else>
      Produto ainda não possui histórico
    </p>
  </div>
</template>

<script>
import { format } from "date-fns";

import { getEstoqueSaidas } from "../../services/apiService";

export default {
  name: "DetalhesSaidas",
  data() {
    return {
      loadingPage: false,
      saidas: [],
      entradas: [],
      info: "Saídas",
      unidade: "",
    };
  },
  async mounted() {
    this.loadingPage = true;

    const currentEstoque = await getEstoqueSaidas(this.$route.params.id);

    this.info = currentEstoque.id_produto.item;
    this.saidas = currentEstoque.saidas;
    this.unidade = currentEstoque.id_produto.unidade;

    currentEstoque.entradas.forEach((entrada) => {
      const quantidade = entrada.produtos.find(
        (x) => x.produto == currentEstoque.id_produto._id
      ).quantidade;
      this.entradas.push({
        data: entrada.data_fechamento,
        quantidade: quantidade,
      });
    });

    this.loadingPage = false;
  },
  methods: {
    handleDate(date) {
      return format(new Date(date), "dd/MM/yyyy");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#detalhes-estoque-wrapper {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }

  .tables {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
</style>
