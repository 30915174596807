<template>
  <sui-modal v-model="showModal" size="tiny">
    <sui-modal-header>
      Produto: {{ estoque.id_produto.item }}
    </sui-modal-header>
    <sui-modal-content scrolling>
      <sui-form>
        <sui-form-field>
          <label>Estoque atual</label>
          <div class="ui right labeled input">
            <input :value="estoque.estoque_atual" readonly />
            <div class="ui basic label">Kg/L/Un</div>
          </div>
        </sui-form-field>
        <sui-form-field>
          <label>Consumo</label>
          <div class="ui right labeled input">
            <input :value="estoque.consumo" readonly />
            <div class="ui basic label">Kg/L/Un</div>
          </div>
        </sui-form-field>
        <sui-form-field>
          <label>Fator de segurança</label>
          <input :value="estoque.fator_seguranca || 0.5" readonly />
        </sui-form-field>
      </sui-form>
      <sui-divider />
      <h4>Estocagem ideal</h4>
      <sui-form>
        <sui-form-fields fields="two">
          <sui-form-field width="eight">
            <label>Estoque mínimo</label>
            <div class="ui right labeled input">
              <input :value="estoque.estoque_minimo" readonly />
              <div class="ui basic label">Kg/L/Un</div>
            </div>
          </sui-form-field>
          <sui-form-field width="eight">
            <label>Estoque médio</label>
            <div class="ui right labeled input">
              <input :value="estoque.estoque_medio" readonly />
              <div class="ui basic label">Kg/L/Un</div>
            </div>
          </sui-form-field>
        </sui-form-fields>
        <sui-form-field>
          <label>Ponto de resuprimento</label>
          <div class="ui right labeled input">
            <input :value="estoque.ponto_resuprimento" readonly />
            <div class="ui basic label">Kg/L/Un</div>
          </div>
        </sui-form-field>
        <sui-form-field>
          <label>Lote econômico de compras</label>
          <div class="ui right labeled input">
            <input :value="estoque.lote_economico_compras" readonly />
            <div class="ui basic label">Kg/L/Un</div>
          </div>
        </sui-form-field>
        <sui-form-field>
          <label>Estoque máximo</label>
          <div class="ui right labeled input">
            <input :value="estoque.estoque_maximo" readonly />
            <div class="ui basic label">Kg/L/Un</div>
          </div>
        </sui-form-field>
      </sui-form>
    </sui-modal-content>

    <sui-modal-actions>
      <slot></slot>
    </sui-modal-actions>
  </sui-modal>
</template>

<script>
export default {
  name: "EstoqueDetalhes",
  props: {
    estoque: Object,
    show: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showModal: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("toggle-modal", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
