<template>
  <button
    :class="[
      secondary ? 'secondary' : 'primary',
      inverted ? 'inverted' : '',
      big ? 'big' : '',
    ]"
    @click="$emit('click')"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  props: {
    text: String,
    secondary: { type: Boolean, default: false },
    inverted: { type: Boolean, default: false },
    big: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

button {
  padding: 12px 46px;
  border-radius: 100px;
  border: 0;
  cursor: pointer;
  color: white;
  font: 500 14px "Inter", sans-serif;
  transition: 0.3s;

  &.big {
    font-size: 18px;
    padding: 16px 68px;
  }

  &.primary {
    background: $primary;

    &.inverted {
      border: 1px solid $primary;
      color: $primary;
    }
  }

  &.secondary {
    background: $secondary;

    &.inverted {
      border: 1px solid $secondary;
      color: $secondary;
    }
  }

  &:hover {
    filter: brightness(0.9);
  }

  &.inverted {
    background: rgba(0, 0, 0, 0);
  }
}
</style>
