<template>
  <div id="try-us" :class="[imgShow ? 'primary' : 'secondary']">
    <div class="texts">
      <div class="main">
        <h1>Experimente nossa praticidade e qualidade</h1>
        <h2>Começe seu <span>período de avaliação</span></h2>
      </div>
      <div class="content">
        <p>
          2 meses de teste grátis com suporte. Para você aproveitar todos os
          nossos benefícios
        </p>
        <Button text="Começar" secondary @click="$router.push('/login')" />
      </div>
    </div>
    <img src="../../assets/home/chef.png" alt="Chef" v-show="imgShow" />
  </div>
</template>

<script>
import Button from "./Button.vue";

export default {
  name: "TryUs",
  components: { Button },
  props: {
    imgShow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#try-us {
  background: $primary;
  border-radius: 24px;

  .texts {
    color: #fff;

    h1,
    h2,
    p {
      font-family: "League Spartan", sans-serif;
    }

    h1 {
      font-weight: 700;
      font-size: 48px;
    }

    h2 {
      font-weight: 600;
      font-size: 36px;

      span {
        color: $secondary;
      }
    }

    p {
      font-size: 24px;
    }

    @media (max-width: 1366px) {
      h1 {
        font-size: 36px;
      }

      h2 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }
    }
  }

  &.primary {
    margin-top: 124px;
    padding: 35px 174px;
    position: relative;
    overflow: hidden;

    .texts {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .main {
        max-width: 390px;
      }

      .content {
        max-width: 380px;
      }
    }

    img {
      position: absolute;
      top: 0;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      text-align: center;
    }

    @media (max-width: 1366px) {
      padding: 35px 0 35px 80px;
    }

    @media (max-width: 1280px) {
      img {
        height: 170%;
      }
    }

    @media (max-width: 1279px) {
      display: flex;
      flex-direction: row-reverse;
      padding-left: 0;

      .texts {
        flex-direction: column;
        margin-left: 200px;

        h1 {
          font-size: 24px;
        }

        h2 {
          font-size: 18px;
        }
      }

      img {
        width: 305px;
        margin-left: -60px;
        height: 100%;
      }
    }
  }

  &.secondary {
    margin-top: 84px;
    padding: 72px;
    width: 550px;

    @media (max-width: 1279px) {
      text-align: center;

      .texts {
        h1 {
          font-size: 32px;
        }

        h2 {
          font-size: 18px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
