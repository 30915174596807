<template>
  <div :style="style" class="container">
    <p><b>Mesa:</b> {{ mesa }}</p>
    <p><b>Prato:</b> {{ prato }}</p>
    <p><b>Observação:</b> {{ obs }}</p>
    <sui-icon
      :name="`arrow ${status ? 'left' : 'right'}`"
      link
      @click="$emit('alterStatus')"
    />
  </div>
</template>

<script>
export default {
  name: "PedidoStatus",
  props: {
    status: Boolean,
    mesa: Number,
    prato: String,
    obs: String,
  },
  computed: {
    style() {
      return `background-color: ${this.status ? "#94cfcb" : "#d59091"}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 0.9rem;
  padding: 1rem 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
}
</style>
