<template>
  <div>
    <sui-grid>
      <sui-grid-row>
        <sui-grid-column class="cadastro-column">
          <sui-input
            class="cadastro-input"
            v-model="value.item"
            placeholder="Item *"
            data-tooltip="Item"
          />
        </sui-grid-column>
      </sui-grid-row>
      <sui-grid-row>
        <sui-grid-column class="cadastro-column" :width="6">
          <sui-dropdown
            selection
            placeholder="Grupo *"
            :options="grupos"
            v-model="value.grupo"
            class="dropdown-classificacao"
          />
          <sui-button icon="plus" @click="openModal('grupo')" basic />
        </sui-grid-column>
        <sui-grid-column class="cadastro-column" :width="6">
          <sui-dropdown
            selection
            placeholder="Subgrupo *"
            v-model="value.subgrupo"
            :options="subgrupos"
            class="dropdown-classificacao"
          />
          <sui-button icon="plus" @click="openModal('subgrupo')" basic />
        </sui-grid-column>
        <sui-grid-column class="cadastro-column" :width="4">
          <sui-input placeholder="Marca" v-model="value.marca" />
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>

    <sui-modal v-model="isModalVisible" size="small">
      <sui-modal-header>Adicionar {{ tipoModal }}</sui-modal-header>
      <sui-modal-content>
        <sui-form @submit="addNew">
          <sui-form-fields :fields="tipoModal == 'subgrupo' ? 2 : 1">
            <sui-form-field v-show="tipoModal == 'subgrupo'">
              <sui-dropdown
                selection
                placeholder="Grupo"
                :options="grupos"
                v-model="createNew.idGrupo"
              />
            </sui-form-field>
            <sui-form-field :width="tipoModal == 'subgrupo' ? 8 : 16">
              <sui-input placeholder="Nome" v-model="createNew.nome" />
            </sui-form-field>
          </sui-form-fields>
          <div class="right-buttons">
            <sui-button @click="isModalVisible = false" type="button"
              >Cancelar</sui-button
            >
            <sui-button primary type="submit">Salvar</sui-button>
          </div>
        </sui-form>
      </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
import {
  getGrupos,
  getSubgrupos,
  getProdutos,
  postGruposSub,
} from "../../services/apiService";

export default {
  name: "Classificacao",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tipoModal: "",
      isModalVisible: false,
      grupo: null,
      subgrupo: null,
      marca: null,
      grupos: [],
      subgrupos: [],
      marcas: [],
      createNew: {
        nome: "",
      },
      grupo: "",
    };
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
    "value.grupo": async function(newVal) {
      this.grupo = newVal;
      await this.getSubgrupos(newVal);
    },
  },
  methods: {
    openModal(tipo) {
      this.tipoModal = tipo;
      this.isModalVisible = true;
    },
    async getSubgrupos(grupoVal) {
      const self = this;
      const subgrupos = await getSubgrupos(grupoVal);
      this.subgrupos = [];
      subgrupos.forEach((subgrupo) => {
        self.subgrupos.push({ text: subgrupo.nome, value: subgrupo._id });
      });
    },
    emitChange(e, key) {
      this.$emit("inputChange", { value: e, key });
    },
    async addNew(e) {
      e.preventDefault();
      const res = await postGruposSub(this.tipoModal, this.createNew);
      if (res && this.tipoModal == "grupo")
        this.grupos.push({ text: res.nome, value: res._id });
      else if (res && this.tipoModal == "subgrupo")
        await this.getSubgrupos(this.grupo);

      this.createNew.nome = "";
      this.grupo = null;
      this.isModalVisible = false;
    },
  },
  async mounted() {
    const self = this;
    if (this.$store.state.api.grupos.count == null) await getGrupos();
    this.$store.state.api.grupos.data.forEach((grupo) => {
      self.grupos.push({ text: grupo.nome, value: grupo._id });
    });

    // Marcas
    if (this.$store.state.api.produtos.count == null) await getProdutos();
    let marcas = this.$store.state.api.produtos.data.map((marca) => {
      return { value: marca.id_produto.marca, text: marca.id_produto.marca };
    });
    marcas = marcas.filter((marca) => {
      return marca.value != undefined;
    });
    this.marcas = marcas;
  },
};
</script>

<style lang="scss" src="../../styles/components/cadastroProdutos.scss" scoped />
