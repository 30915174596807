import api from "./api";
import store from "../store";
import { toast } from "../helpers";

export const getPedidos = async () => {
  try {
    const res = await api.get("todos/pedido", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
      params: {
        finalizado: false,
      },
    });
    store.commit("set", {
      type: "pdv",
      name: "pedidos",
      item: res.data.res,
    });
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getPedidosOnline = async () => {
  try {
    const res = await api.get("todos/pedido-online", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getCozinha = async () => {
  try {
    const res = await api.get("todos/cozinha", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getMesa = async (id) => {
  try {
    const res = await api.get(`pedido/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const addPedido = async (data) => {
  try {
    const res = await api.put("pedido/adicionar", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const editPedido = async (data) => {
  try {
    const res = await api.put("pedido", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const alterStatus = async (data) => {
  try {
    const res = await api.put("cozinha", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const alterStatusOnline = async (data) => {
  try {
    const res = await api.put("pedido-online", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const addMesa = async (data) => {
  try {
    const res = await api.post("pedido", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("push", {
      type: "pdv",
      name: "pedidos",
      item: res.data.res,
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const mergeMesa = async (data) => {
  try {
    const response = await api.put("/pedido/juntar", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    store.commit("push", {
      type: "pdv",
      name: "pedidos",
      item: response.data.mesa1,
    });
    toast("success", "Mesas unificadas");
    return response.data;
  } catch (err) {
    toast("error", e.response.data.error);
  }
};

export const finalizarMesa = async (id) => {
  try {
    const res = await api.put(
      `pedido/finalizar/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          tenant: localStorage.getItem("tenant"),
        },
      }
    );
    toast("success", "Mesa finalizada com sucesso!");
    store.commit("removePedido", res.data.pedido._id);
    return res.data;
  } catch (e) {
    toast(
      "error",
      "Ocorreu um erro ao finalizar a mesa, cheque se os pagamentos foram feitos"
    );
  }
};

export const pagar = async (data) => {
  try {
    const res = await api.put("pedido/pagar", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    toast("success", "Pagamento feito com sucesso!");
    store.commit("alterPedido", res.data.pedido);
    return res.data.pedido;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const finalizar1Online = async (data) => {
  try {
    const res = await api.put("pedido-online/finalizar1", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    toast("success", "Pedido finalizado com sucesso!");
    return res.data.pedido;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const finalizar2Online = async (data) => {
  try {
    const res = await api.put("pedido-online/finalizar2", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        tenant: localStorage.getItem("tenant"),
      },
    });
    toast("success", "Pedido finalizado com sucesso!");
    return res.data.pedido;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};
