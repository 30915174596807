<template lang="html">
  <div id="estoque-wrapper">
    <h3>Estoque</h3>
    <div class="ui basic segment">
      <div class="ui two column very relaxed grid">
        <div class="left aligned column">
          <sui-dropdown
            inline
            placeholder="Status"
            selection
            :options="status"
            v-model="selectedStatus"
          />
        </div>
        <div class="right aligned column">
          <sui-button
            :icon="locked ? 'lock' : 'lock open'"
            @click="locked = !locked"
          />
          <sui-dropdown
            inline
            placeholder="Grupo"
            selection
            :options="grupos"
            v-model="selectedGrupo"
          />
          <sui-dropdown
            inline
            right
            floated
            placeholder="Subgrupo"
            selection
            :options="subgrupos"
            v-model="selectedSubgrupo"
          />
        </div>
      </div>
      <sui-divider vertical />
    </div>

    <p v-if="estoque.length == 0 && !loading">Nenhum produto cadastrado</p>
    <div v-else-if="estoque.length == 0 && loading"><sui-loader active /></div>
    <GChart
      type="ColumnChart"
      :data="chartData"
      :options="chartOptions"
      :events="chartEvents"
      ref="gChart"
      v-else
      v-show="chartData.length > 1"
    />

    <sui-button
      content="Exportar entradas e saídas"
      color="teal"
      icon="download"
      label-position="left"
      v-show="chartData.length > 1"
      @click="openExportModal"
    />

    <EstoqueDetalhes
      :estoque="selectedEstoque"
      :show="selected"
      v-if="selectedEstoque"
      @toggle-modal="selected = !selected"
    >
      <sui-button
        content="Histórico de saídas"
        color="teal"
        @click="goToDetails(selectedEstoque, 'detalhes-saidas')"
      />
      <sui-button
        content="Histórico"
        primary
        @click="goToDetails(selectedEstoque, 'detalhes-estoque')"
      />
    </EstoqueDetalhes>

    <sui-modal v-model="exportModal">
      <sui-modal-header>
        Exportar entradas e saídas dos seguintes produtos:
      </sui-modal-header>
      <sui-modal-content scrolling>
        <sui-segment>
          <ul>
            <li
              v-for="(item, index) in chartData"
              :key="index"
              v-show="index != 0"
            >
              {{ item[0] }}
            </li>
          </ul>
        </sui-segment>

        <sui-form class="date-form">
          <sui-form-fields fields="three">
            <sui-form-field width="eight">
              <sui-label basic>Data de início</sui-label>
              <sui-input type="date" v-model="datas.data2" required />
            </sui-form-field>
            <sui-form-field width="eight">
              <sui-label basic>Data de fim</sui-label>
              <sui-input type="date" v-model="datas.data1" required />
            </sui-form-field>
          </sui-form-fields>
          <sui-button
            icon="download"
            label-position="left"
            primary
            type="button"
            content="Exportar"
            @click="exportPdf"
            :loading="exportLoading"
          />
        </sui-form>
      </sui-modal-content>
    </sui-modal>

    <VueHtml2pdf
      :manual-pagination="true"
      :enable-download="true"
      ref="pdfComponent"
    >
      <section slot="pdf-content" ref="content" id="content">
        <h2>Histórico de entradas e saídas</h2>

        <div v-for="produto in pdfdata">
          <h3>{{ produto.nome }}</h3>
          <div class="tables">
            <div class="">
              <h4>Saídas</h4>
              <sui-table striped collapsing celled class="saidas-table">
                <sui-table-header>
                  <sui-table-row>
                    <sui-table-header-cell>Data</sui-table-header-cell>
                    <sui-table-header-cell>Quantidade</sui-table-header-cell>
                  </sui-table-row>
                </sui-table-header>

                <sui-table-body>
                  <sui-table-row
                    v-for="saida in produto.saidas"
                    :key="saida._id"
                  >
                    <sui-table-cell>{{ saida.data }}</sui-table-cell>
                    <sui-table-cell>
                      {{ saida.quantidade }} {{ produto.unidade }}
                    </sui-table-cell>
                  </sui-table-row>
                </sui-table-body>
              </sui-table>

              <span>
                <b>Total:</b>
                {{ produto.saidasQuant }}
              </span>
            </div>

            <div class="">
              <h4>Entradas</h4>
              <sui-table striped collapsing celled class="saidas-table">
                <sui-table-header>
                  <sui-table-row>
                    <sui-table-header-cell>Data</sui-table-header-cell>
                    <sui-table-header-cell>Quantidade</sui-table-header-cell>
                  </sui-table-row>
                </sui-table-header>

                <sui-table-body>
                  <sui-table-row
                    v-for="entrada in produto.entradas"
                    :key="entrada.id"
                  >
                    <sui-table-cell>{{
                      handleDate(entrada.data)
                    }}</sui-table-cell>
                    <sui-table-cell>
                      {{ entrada.quantidade }} {{ produto.unidade }}
                    </sui-table-cell>
                  </sui-table-row>
                </sui-table-body>
              </sui-table>

              <span>
                <b>Total:</b>
                {{ produto.entradasQuant }}
              </span>
            </div>
          </div>
        </div>
      </section>
    </VueHtml2pdf>

    <div id="pdf"></div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
import VueHtml2pdf from "vue-html2pdf";

import EstoqueDetalhes from "../components/Modais/EstoqueDetalhes.vue";
import {
  getEstoque,
  putDetalhamentoProduto,
  getGrupos,
  getSubgrupos,
  getEstoqueSaidasData,
} from "../services/apiService";
import { currency } from "../helpers";
import { format } from "date-fns";

export default {
  name: "Estoque",
  components: { GChart, EstoqueDetalhes, VueHtml2pdf },
  watch: {
    selectedStatus(newValue) {
      this.resetChartData();

      if (this.locked == false) {
        if (newValue == 1) {
          this.estoque.forEach((produto) => {
            this.pushToChartData(produto);
          });
        }
        if (newValue == 2) {
          this.estoque.forEach((produto) => {
            if (produto.id_produto.situacao == "Ativo") {
              this.pushToChartData(produto);
            }
          });
        }
        if (newValue == 3) {
          this.estoque.forEach((produto) => {
            if (produto.id_produto.situacao == "Inativo") {
              this.pushToChartData(produto);
            }
          });
        }
        this.selectedGrupo = null;
      }

      if (this.locked == true) {
        this.selectedSubgrupo = null;

        if (newValue == 1) {
          this.estoque.forEach((produto) => {
            if (produto.id_produto.grupo == this.selectedGrupo) {
              this.pushToChartData(produto);
            }
          });
        }
        if (newValue == 2) {
          this.estoque.forEach((produto) => {
            if (
              produto.id_produto.situacao == "Ativo" &&
              produto.id_produto.grupo == this.selectedGrupo
            ) {
              this.pushToChartData(produto);
            }
          });
        }
        if (newValue == 3) {
          this.estoque.forEach((produto) => {
            if (
              produto.id_produto.situacao == "Inativo" &&
              produto.id_produto.grupo == this.selectedGrupo
            ) {
              this.pushToChartData(produto);
            }
          });
        }
      }
    },
    async selectedGrupo(newValue) {
      this.resetChartData();
      this.selectedSubgrupo = null;
      this.subgrupos.splice(0, this.subgrupos.length);
      if (this.selectedGrupo != null) {
        const subgrupos = await getSubgrupos(newValue);

        subgrupos.forEach((subgrupo) => {
          this.subgrupos.push({ text: subgrupo.nome, value: subgrupo._id });
        });

        this.estoque.forEach((produto) => {
          if (produto.id_produto.grupo == newValue) {
            this.pushToChartData(produto);
          }
        });
      }
    },
    selectedSubgrupo(newValue) {
      this.resetChartData();
      this.estoque.forEach((produto) => {
        if (produto.id_produto.subgrupo == newValue) {
          this.pushToChartData(produto);
        }
      });
    },
  },
  data() {
    return {
      selectedStatus: 2,
      selectedGrupo: null,
      selectedSubgrupo: null,
      locked: false,
      status: [
        {
          text: "Todos",
          value: 1,
        },
        {
          text: "Ativo",
          value: 2,
        },
        {
          text: "Inativo",
          value: 3,
        },
      ],
      grupos: [],
      subgrupos: [],
      chartOptions: {
        isStacked: true,
        chart: {
          title: "Estoque",
          subtitle: "Estoque atual",
        },
        legend: { position: "top", maxLines: 3 },
        colors: ["#e81e03", "#00933b", "#9fd3f4"],
      },
      chartData: [
        [
          "Produto",
          "Estoque mínimo",
          "Estoque atual",
          "Potencial de estocagem ideal",
        ],
      ],
      exportLoading: false,
      chartEvents: {
        select: async () => {
          const chart = this.$refs.gChart.chartObject;
          const selection = chart.getSelection()[0];
          if (selection) {
            this.selected = true;
            this.selectedLoading = true;
            const selectedEstoque = this.estoque[selection.row];
            if (
              !this.selectedEstoque ||
              selectedEstoque._id != this.selectedEstoque._id
            ) {
              const res = await putDetalhamentoProduto(selectedEstoque._id, 7);
              this.estoque[selection.row + 1] = this.selectedEstoque = res;

              this.chartData[selection.row + 1][1] = res.estoque_minimo;
              this.chartData[selection.row + 1][2] = res.estoque_atual;
              this.chartData[selection.row + 1][3] = res.estoque_maximo;
            }
            this.selectedLoading = false;
          }
        },
      },
      estoque: [],
      selectedEstoque: null,
      selected: false,
      selectedLoading: false,
      loading: false,
      exportModal: false,
      datas: { data1: null, data2: null },
      pdfdata: [],
    };
  },
  async mounted() {
    this.loading = true;
    const estoque = await getEstoque();
    if (estoque.length > 0) {
      this.estoque = estoque;
      estoque.forEach((produto) => {
        if (produto.id_produto.situacao == "Ativo") {
          this.pushToChartData(produto);
        }
      });
    }
    if (this.$store.state.api.grupos.count == null) await getGrupos();

    this.$store.state.api.grupos.data.forEach((grupo) => {
      this.grupos.push({ text: grupo.nome, value: grupo._id });
    });

    this.loading = false;
  },
  methods: {
    async goToDetails(selected, route) {
      await this.$store.commit("setCurrentEstoque", selected);
      this.$router.push({
        name: route,
        params: { id: selected._id },
      });
    },
    handleCurrency(amount) {
      return currency(amount);
    },
    pushToChartData(produto) {
      this.chartData.push([
        produto.id_produto.item,
        produto.estoque_minimo ? produto.estoque_minimo : 0,
        produto.estoque_atual ? produto.estoque_atual : 0,
        produto.estoque_medio ? produto.estoque_medio : 0,
      ]);
    },
    resetChartData() {
      this.chartData = [
        [
          "Produto",
          "Estoque mínimo",
          "Estoque atual",
          "Potencial de estocagem ideal",
        ],
      ];
    },
    openExportModal() {
      const data1 = new Date();
      const data2 = new Date().setMonth(data1.getMonth() - 1);

      this.datas.data1 = format(data1, "yyyy-MM-dd");
      this.datas.data2 = format(data2, "yyyy-MM-dd");

      this.exportModal = true;
    },
    handleDate(date) {
      return format(new Date(date), "dd/MM/yyyy");
    },
    async exportPdf() {
      this.exportLoading = true;
      const productsNames = [];

      for (let i = 1; i < this.chartData.length; i++)
        productsNames.push(this.chartData[i][0]);

      const products = this.estoque
        .filter((item) => {
          if (productsNames.includes(item.id_produto.item)) return item;
        })
        .map((item) => item._id);

      const data = await getEstoqueSaidasData(
        JSON.stringify(products),
        this.datas.data2,
        this.datas.data1
      );

      data.map((estoque) => {
        const info = {
          nome: estoque.id_produto.item,
          unidade: estoque.id_produto.unidade,
          saidas: estoque.saidas,
          entradas: [],
        };

        let entradasQuant = 0;
        let saidasQuant = 0;

        estoque.entradas.forEach((entrada) => {
          const quantidade = entrada.produtos.find(
            (x) => x.produto == estoque.id_produto._id
          ).quantidade;
          info.entradas.push({
            data: entrada.data_fechamento,
            quantidade: quantidade,
            id: entrada._id,
          });
          entradasQuant += quantidade;
        });

        estoque.saidas.forEach((saida) => {
          saidasQuant += saida.quantidade;
        });

        info.entradasQuant = entradasQuant;
        info.saidasQuant = saidasQuant;

        this.pdfdata.push(info);
      });

      this.exportLoading = false;
      this.$refs.pdfComponent.generatePdf();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
#estoque-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
}

#content {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .tables {
    margin: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around !important;
  }
}
</style>
