<template>
  <div id="cadastro-wrapper">
    <form class="criar_prato" @submit="montar_prato" v-if="!adding">
      <sui-grid :columns="4">
        <sui-grid-row class="columns">
          <sui-grid-column :width="3">
            <sui-input
              data-tooltip="Código"
              data-position="bottom left"
              placeholder="Código"
              v-model="cadastro.codigo"
              type="number"
              transparent
              class="inputs"
            />
            <sui-input
              data-tooltip="Título"
              data-position="bottom left"
              placeholder="Título *"
              v-model="cadastro.titulo"
              type="text"
              transparent
              class="inputs"
              required
            />
          </sui-grid-column>
          <sui-grid-column :width="3">
            <sui-input
              data-position="bottom left"
              data-tooltip="Valor de Venda"
              placeholder="Valor de Venda *"
              v-model="cadastro.valor_venda"
              type="number"
              transparent
              class="inputs"
              required
              step="0.01"
            />
            <sui-input
              data-position="bottom left"
              data-tooltip="Tempo de preparo"
              placeholder="Tempo de Preparo"
              v-model="cadastro.tempo_preparo"
              type="number"
              transparent
              class="inputs"
            />
          </sui-grid-column>
          <sui-grid-column :width="7">
            <sui-input
              data-position="bottom left"
              data-tooltip="Descrição"
              v-model="cadastro.descricao_prato"
              placeholder="Descrição"
              transparent
              class="inputs textarea"
            />
            <sui-input
              data-position="bottom left"
              data-tooltip="Categoria"
              v-model="cadastro.categoria"
              placeholder="Categoria *"
              transparent
              required
              class="inputs textarea"
            />
          </sui-grid-column>
          <sui-grid-column :width="3">
            <sui-button color="teal" type="submit" :loading="loading"
              >Salvar</sui-button
            >
          </sui-grid-column>
        </sui-grid-row>
      </sui-grid>
    </form>
    <div v-else class="editar_prato_header">
      <h3>Código {{ cadastro.codigo }}</h3>
      <h3>Título: {{ cadastro.titulo }}</h3>
    </div>

    <div class="criar_ficha" v-show="montado">
      <div class="buttons-choice" v-if="type == 0">
        <sui-button
          color="teal"
          content="Adicionar ingrediente"
          icon="utensils"
          label-position="left"
          size="large"
          @click="addSomething(1)"
        />
        <sui-button
          color="teal"
          content="Adicionar etapa de preparo"
          icon="list ol"
          label-position="left"
          size="large"
          @click="addSomething(2)"
        />
      </div>

      <sui-form v-if="type == 1" @submit="add">
        <h3>Adicionar ingrediente</h3>
        <sui-form-fields fields="three" class="form-field">
          <sui-form-field>
            <label>Grupo</label>
            <sui-dropdown
              selection
              search
              placeholder="Grupo"
              :options="grupos"
              v-model="grupo"
            />
          </sui-form-field>
          <sui-form-field>
            <label>Subgrupo</label>
            <sui-dropdown
              selection
              search
              placeholder="Subgrupo"
              :options="subgrupos"
              v-model="subgrupo"
            />
          </sui-form-field>
          <sui-form-field>
            <label>Item</label>
            <sui-dropdown
              selection
              search
              placeholder="Item"
              :options="items"
              v-model="produto.produto"
            />
          </sui-form-field>
        </sui-form-fields>
        <sui-form-fields fields="three" class="form-field">
          <sui-form-field>
            <label>Peso Líquido Operacional</label>
            <sui-input
              v-model="produto.peso_liquido_operacional"
              type="number"
              step="any"
              transparent
              class="inputs"
            />
          </sui-form-field>
          <sui-form-field>
            <label>Peso Pronto</label>
            <sui-input
              v-model="produto.peso_pronto"
              step="any"
              type="number"
              transparent
              class="inputs"
            />
          </sui-form-field>
          <sui-form-field class="form-buttons">
            <sui-button type="button" @click="cancelAdd">Cancelar</sui-button>
            <sui-button type="submit" color="teal">Adicionar</sui-button>
          </sui-form-field>
        </sui-form-fields>
      </sui-form>

      <sui-form v-else-if="type == 2" @submit="add">
        <h3>Adicionar etapa de preparo</h3>
        <sui-form-fields fields="two">
          <sui-form-field>
            <label>Tempo (em minutos)</label>
            <input type="number" v-model="passo.tempo" />
          </sui-form-field>
          <sui-form-field>
            <label>Descrição</label>
            <TextArea v-model="passo.descricao"></TextArea>
          </sui-form-field>
        </sui-form-fields>

        <sui-form-field class="form-buttons">
          <sui-button type="button" @click="cancelAdd">Cancelar</sui-button>
          <sui-button type="submit" color="teal">Adicionar</sui-button>
        </sui-form-field>
      </sui-form>
    </div>

    <div class="ficha-wrapper">
      <h3 v-show="produtos.length != 0">Ingredientes</h3>
      <p v-for="produto in produtos" :key="produto.produto._id">
        {{ produto.produto.item }}
        {{
          handleWeight(
            produto.peso_liquido_operacional,
            produto.produto.unidade
          )
        }}
      </p>
      <h3 v-show="passos.length != 0">Modo de preparo</h3>
      <p v-for="passo in passos" :key="passo.descricao">
        {{ handleTime(passo.tempo) }}
        - {{ passo.descricao }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  getGrupos,
  getSubgrupos,
  postFichaTecnica,
  putFichaTecnica,
  getProdutoPorSubgrupo,
} from "../../services/apiService";

import TextArea from "../../components/TextArea";

export default {
  nome: "CadastroFichaTecnica",
  props: {
    cod: {
      default: null,
      type: String,
    },
  },
  components: {
    TextArea,
  },
  data() {
    return {
      // cadastro prato
      cadastro: {},
      // cadastro ficha tecnica
      produtos: [],
      passos: [],
      produto: {},
      passo: {},
      grupos: [],
      subgrupos: [],
      items: [],
      grupo: null,
      subgrupo: null,
      loading: null,
      adding: false,
      type: 0,
      montado: false,
    };
  },
  watch: {
    async grupo(newVal) {
      const self = this;
      const subgrupos = await getSubgrupos(newVal);
      subgrupos.forEach((subgrupo) => {
        self.subgrupos.push({ text: subgrupo.nome, value: subgrupo._id });
      });
    },
    async subgrupo(newVal, oldVal) {
      const self = this;
      const items = await getProdutoPorSubgrupo(newVal);
      items.forEach((item) => {
        self.items.push({
          text: item.item,
          value: `{ "item": "${item.item}", "_id": "${item._id}", "unidade": "${item.unidade}" }`,
        });
      });
    },
  },
  methods: {
    add(e) {
      e.preventDefault();
      const newItem = JSON.parse(
        JSON.stringify(this.type == 1 ? this.produto : this.passo)
      );

      if (this.type == 1) {
        newItem.produto = JSON.parse(newItem.produto);
        this.produtos.push(newItem);
      } else this.passos.push(newItem);
      this.cancelAdd();
    },
    async adicionar_ficha_tecnica(e) {
      e.preventDefault();
      const newItem = JSON.parse(JSON.stringify(this.item_ficha_tecnica));
      console.log(this.cadastro);
      await this.ficha.push(newItem);
      for (const key in this.item_ficha_tecnica)
        this.item_ficha_tecnica[key] = null;
    },
    async montar_prato(e) {
      e.preventDefault();
      this.loading = true;
      if (!this.montado) {
        this.cadastro = await postFichaTecnica(this.cadastro);
        this.montado = true;
        this.$router.push(`/cardapio/${this.cadastro._id}`);
      } else {
        const produtos = JSON.parse(JSON.stringify(this.produtos));
        produtos.forEach((produto) => {
          produto.produto = produto.produto._id;
        });
        const data = {
          id: this.cadastro._id,
          ...this.cadastro,
          produtos,
          passos: this.passos,
        };
        await putFichaTecnica(data);
      }
      this.loading = false;
    },
    addSomething(type) {
      this.adding = true;
      this.type = type;

      if (this.type == 1)
        for (const key in this.produto) {
          this.grupo = null;
          this.subgrupo = null;
          this.produto[key] = null;
        }
      else for (const key in this.passo) this.passo[key] = null;
    },
    cancelAdd() {
      this.adding = false;
      this.type = 0;
    },
    handleWeight(value, un) {
      return `${value} ${un}`;
    },
    handleTime(value) {
      if (!value) return;
      const hours = value / 60;
      const rhours = Math.floor(hours);
      if (rhours == 0) return `${value} min`;
      let minutes = (hours - rhours) * 60;
      minutes = Math.round(minutes);
      if (minutes == 0) return `${rhours} horas`;
      return `${rhours} horas e ${minutes} min`;
    },
  },
  async mounted() {
    const self = this;
    if (this.$store.state.api.grupos.count == null) await getGrupos();
    this.$store.state.api.grupos.data.forEach((grupo) => {
      self.grupos.push({ text: grupo.nome, value: grupo._id });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/global.scss";

#cadastro-wrapper {
  width: 100vw;
  .columns > * {
    display: flex;
    flex-direction: column;
    > * {
      margin: 1rem;
    }
  }
  h3 {
    margin-bottom: 1rem;
  }
  .inputs {
    border-bottom: 1px solid $semantic-input-color;
    width: 100%;
  }
  .inputs.textarea {
    word-break: break-word;
  }
  .criar_prato,
  .editar_prato_header {
    width: 75%;
    background-color: $primary-gray;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.2);
    padding: 1rem;
  }
  .editar_prato_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    h3 {
      color: #fff;
      margin: 1rem;
    }
  }
  .criar_ficha {
    padding: 1rem;
    width: 75%;
    color: #ffffff;
    background-color: $dark-primary-color;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 0.8rem 0;
  }
  .buttons-choice {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  label {
    color: #fff;
  }
  .form-field {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .ficha-wrapper {
    padding: 2rem;

    h3 {
      color: $secondary-text-color;
    }
  }
}
</style>
