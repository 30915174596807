<template>
  <div id="caixa-wrapper">
    <h1>Saldo: {{ handleCurrency(saldoTotal) }}</h1>

    <sui-card-group :items-per-row="6" class="cards">
      <sui-card v-for="card in cards" :key="card.key" class="card">
        <sui-card-content class="card-header">
          <sui-header>{{ card.title }}</sui-header>
        </sui-card-content>
        <sui-card-content>
          <sui-header size="small">{{ handleCurrency(card.total) }}</sui-header>
          <sui-divider horizontal>Líquido</sui-divider>
          {{ handleCurrency(card.liquido) }}
        </sui-card-content>
      </sui-card>
    </sui-card-group>

    <div class="register-div">
      <h2>Novo registro</h2>
      <sui-form class="register-form" @submit="sendRegistro">
        <sui-form-fields grouped>
          <sui-form-field v-for="checkbox in checkboxes" :key="checkbox.value">
            <sui-checkbox
              :label="checkbox.text"
              radio
              :value="checkbox.value"
              v-model="newRegistro.movimentacao"
            />
          </sui-form-field>
        </sui-form-fields>
        <div class="ui labeled input">
          <div class="ui label">R$</div>
          <input
            type="number"
            step="0.01"
            placeholder="Valor"
            v-model="newRegistro.valor"
          />
        </div>
        <sui-input placeholder="Observações" v-model="newRegistro.observacao" />
        <sui-button
          primary
          content="Salvar"
          type="submit"
          :loading="loadingMov"
        />
      </sui-form>
    </div>

    <div class="history">
      <h2>Histórico</h2>

      <sui-form class="date-form" @submit="getData">
        <sui-form-fields fields="three">
          <sui-form-field width="eight">
            <sui-label basic>Data de início</sui-label>
            <sui-input type="date" v-model="datas.data1" required />
          </sui-form-field>
          <sui-form-field width="eight">
            <sui-label basic>Data de fim</sui-label>
            <sui-input type="date" v-model="datas.data2" required />
          </sui-form-field>
          <sui-button
            icon="search"
            primary
            type="submit"
            :loading="loadingSearch"
          />
        </sui-form-fields>
      </sui-form>

      <sui-segment class="history-div">
        <sui-segment
          class="historico-card"
          v-for="card in pagamentos.slice().reverse()"
          :key="card._id"
          stacked
        >
          <sui-grid :columns="3">
            <sui-grid-row>
              <sui-grid-column v-if="card.usuario">
                Usuário: {{ card.usuario.nome }}
              </sui-grid-column>
              <sui-grid-column v-else>
                Usuário: Online
              </sui-grid-column>
              <sui-grid-column>
                Data/Hora: {{ handleDate(card.data) }}
              </sui-grid-column>
              <sui-grid-column>
                <sui-button
                  content="Itens vendidos"
                  icon="list"
                  label-position="left"
                  @click="seePayDetails(card._id)"
                />
              </sui-grid-column>
            </sui-grid-row>
            <sui-grid-row>
              <sui-grid-column>
                Valor: {{ handleCurrency(card.valor) }}
              </sui-grid-column>
              <sui-grid-column>
                Forma:
                {{ handlePaymentForm(card.forma_pagamento) }}
              </sui-grid-column>
              <sui-grid-column>
                Observação: {{ card.observacao }}
              </sui-grid-column>
            </sui-grid-row>
          </sui-grid>
        </sui-segment>
      </sui-segment>
    </div>

    <sui-modal v-model="modalOpen" size="small">
      <sui-modal-header>
        Histórico do pedido - <i>{{ orderDetails.apelido }}</i>
      </sui-modal-header>
      <sui-modal-content>
        <div v-for="pedido in orderDetails.pedidos" :key="pedido._id">
          <b>Item: </b>{{ pedido.item.titulo }} -
          <b>Quantidade: </b>
          {{ pedido.qtde }} -
          {{ handleCurrency(pedido.valor || pedido.item.valor_venda) }}
          <sui-divider />
        </div>
      </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
import { currency, date } from "../helpers";
import {
  getSaldo,
  getLastSaldo,
  postMovimentacao,
  getOrderByPay,
} from "../services/apiService";

export default {
  name: "Caixa",
  data() {
    return {
      cards: [
        { title: "Crédito", key: "credito", total: 0, liquido: 0 },
        { title: "Débito", key: "debito", total: 0, liquido: 0 },
        { title: "Vale refeição", key: "vale", total: 0, liquido: 0 },
        { title: "Dinheiro", key: "dinheiro", total: 0, liquido: 0 },
        { title: "Outros", key: "outros", total: 0, liquido: 0 },
        { title: "Total", key: "total", total: 0, liquido: 0 },
      ],
      checkboxes: [
        { text: "Abertura/Fechamento", value: "abertura_fechamento" },
        { text: "Aporte", value: "aporte" },
        { text: "Retirada", value: "retirada" },
      ],
      newRegistro: {
        movimentacao: "abertura_fechamento",
      },
      datas: {
        data1: null,
        data2: null,
      },
      saldoTotal: 0,
      pagamentos: [],
      loadingSearch: false,
      loadingMov: false,
      payDetails: {},
      orderDetails: {},
      modalOpen: false,
    };
  },
  methods: {
    handleCurrency(amount) {
      return currency(amount);
    },
    handleDate(data) {
      return date(data);
    },
    handlePaymentForm(payment) {
      return this.cards.find((x) => x.key == payment).title;
    },
    async getData(e) {
      e.preventDefault();
      this.loadingSearch = true;

      console.log(this.datas);

      const res = await getSaldo(this.datas.data1, this.datas.data2);

      this.handleSaldo(res);
      this.loadingSearch = false;
    },
    async sendRegistro(e) {
      e.preventDefault();
      this.loadingMov = true;
      await postMovimentacao(this.newRegistro);
      this.newRegistro.valor = null;
      this.loadingMov = false;
    },
    async handleSaldo(res) {
      this.saldoTotal = res.saldoTotal;
      this.pagamentos = res.pagamentos;

      for (let i in res.saldo) {
        let total = true;
        if (i.includes("_liquido")) {
          total = false;
          i = i.replace("_liquido", "");
        }

        let card = this.cards.find((x) => x.key == i);
        if (card)
          total
            ? (card.total = res.saldo[i])
            : (card.liquido = res.saldo[`${i}_liquido`]);
      }
    },
    async seePayDetails(id) {
      const data = await getOrderByPay(id);
      this.orderDetails = data;
      this.modalOpen = true;
    },
  },
  async mounted() {
    const res = await getLastSaldo();
    if (Object.keys(res).length != 0) await this.handleSaldo(res);
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
#caixa-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  > * {
    margin: 1rem;
  }

  h1,
  h2 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .card {
      .card-header {
        background-color: #2185d0;

        .header {
          color: #fff;
        }
      }
    }
  }

  .register-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .register-form {
      width: 70%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      .register-fields {
        display: flex;
        width: 100%;
      }
    }
  }

  .history {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .date-form {
      width: 60rem;

      .fields {
        align-items: flex-end;
      }
    }

    .history-div {
      max-height: 45rem;
      overflow-x: hidden;
      overflow-y: auto;
      text-align: justify;
    }
  }
}
</style>
