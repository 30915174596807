<template>
  <div id="fornecedores-wrapper">
    <h3>Entradas</h3>
    <div>
      <div class="top-segment">
        <sui-input
          placeholder="Pesquisar por código ou fornecedor"
          icon="search"
          v-model="search"
        />

        <div>
          <sui-button
            icon="plus"
            label-position="left"
            content="Cadastrar pedido de compra"
            color="teal"
            @click="$router.push('pedido-compra')"
          />
        </div>
      </div>

      <sui-table celled selectable>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell>Código</sui-table-header-cell>
            <sui-table-header-cell>Fornecedor</sui-table-header-cell>
            <sui-table-header-cell>Data do Pedido</sui-table-header-cell>
            <sui-table-header-cell>Data de Chegada</sui-table-header-cell>
            <sui-table-header-cell>Status</sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>

        <sui-table-body>
          <sui-table-row
            v-for="entrada in entradas"
            :key="entrada._id"
            @click="openModal(entrada._id)"
          >
            <sui-table-cell>{{ entrada.codigo }}</sui-table-cell>
            <sui-table-cell>{{ entrada.fornecedor.nome_razao }}</sui-table-cell>
            <sui-table-cell>{{
              handleDate(entrada.data_abertura)
            }}</sui-table-cell>
            <sui-table-cell>{{
              handleDate(entrada.data_fechamento)
            }}</sui-table-cell>
            <sui-table-cell
              ><sui-icon
                :name="entrada.status ? 'check' : 'clock outline'"
                :color="entrada.status ? 'green' : 'yellow'"
            /></sui-table-cell>
          </sui-table-row>
        </sui-table-body>
      </sui-table>
    </div>

    <sui-modal v-model="modalVisible" v-if="modalVisible" size="tiny">
      <sui-modal-header>
        <div class="modal-header-alignment">
          <h4>
            Pedido dia: {{ handleDate(selected.data_abertura) }} <br />
            Entregue dia: {{ handleDate(selected.data_fechamento) }} <br />
            Fornecedor:
            {{ selected.fornecedor.nome_razao }}<br />
          </h4>
          <sui-button
            content="Editar"
            color="teal"
            icon="wrench"
            label-position="left"
            basic
            :disabled="selected.status"
          />
        </div>
      </sui-modal-header>
      <sui-modal-content>
        <sui-table>
          <sui-table-header>
            <sui-table-header-cell>Item</sui-table-header-cell>
            <sui-table-header-cell>Quantidade</sui-table-header-cell>
            <sui-table-header-cell>Valor</sui-table-header-cell>
          </sui-table-header>
          <sui-table-body>
            <sui-table-row
              v-for="produto in selected.produtos"
              :key="produto._id"
            >
              <sui-table-cell v-if="produto.produto">{{
                produto.produto.item
              }}</sui-table-cell>
              <sui-table-cell v-else class="deleted-product"
                ><b>PRODUTO DELETADO</b></sui-table-cell
              >
              <sui-table-cell
                >{{ produto.quantidade
                }}{{ produto.produto.unidade }}</sui-table-cell
              >
              <sui-table-cell>{{
                handleCurrency(produto.quantidade * produto.custo_produto)
              }}</sui-table-cell>
            </sui-table-row>
          </sui-table-body>
        </sui-table>
      </sui-modal-content>
      <sui-modal-actions>
        <div class="modal-footer">
          <h3>Total: {{ handleCurrency(totalSelected) }}</h3>
          <sui-button
            :content="
              selected.status ? 'Pedido já entregue' : 'Marcar como entregue'
            "
            color="teal"
            icon="check"
            label-position="left"
            @click="handleEntrada"
            :loading="loading"
            :disabled="selected.status"
          />
        </div>
      </sui-modal-actions>
    </sui-modal>

    <sui-modal>
      <sui-modal-content> </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
import { getEntradas, putEntrada } from "../../services/apiService";
import { normalizeAndSearch, date, currency } from "../../helpers";

export default {
  name: "Entradas",
  components: {},
  data() {
    return {
      loading: false,
      search: "",
      modalVisible: false,
      selected: {},
      totalSelected: 0
    };
  },
  methods: {
    goToDetails(id) {
      this.$router.push({ name: "detalhes-fornecedor", params: { id } });
    },
    handleDate(data) {
      if (data) return date(data, true);
    },
    handleCurrency(amount) {
      return currency(amount);
    },
    openModal(id) {
      this.totalSelected = 0;
      this.selected = this.entradas.find(x => x._id == id);
      this.selected.produtos.forEach(prod => {
        this.totalSelected += prod.quantidade * prod.custo_produto;
      });
      this.modalVisible = true;
    },
    async handleEntrada() {
      this.loading = true;
      await putEntrada(this.selected._id);
      this.loading = false;
      this.modalVisible = false;
    }
  },
  async mounted() {
    if (this.$store.state.api.entradas.count == null) await getEntradas();
  },
  computed: {
    entradas() {
      return this.$store.state.api.entradas.data.filter(entrada => {
        return (
          normalizeAndSearch(entrada.fornecedor.nome_razao, this.search) ||
          `${entrada.codigo}`.includes(this.search)
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#fornecedores-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  h4 {
    color: $secondary-text-color;
  }
  .top-segment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ui.input {
      width: 30rem;
    }
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .modal-header-alignment {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .deleted-product {
    color: $estoque-vermelho;
  }
}
</style>
