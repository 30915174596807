<template>
  <div id="fornecedores-wrapper">
    <h3>Fornecedores</h3>
    <div>
      <div class="top-segment">
        <sui-input
          placeholder="Pesquisar por nome, CPF ou CNPJ"
          icon="search"
          v-model="search"
        />

        <div>
          <sui-button
            icon="trash"
            color="red"
            :disabled="selected.length == 0"
            @click="removeMultiple"
            :loading="loading"
          />
          <sui-button
            icon="plus"
            label-position="left"
            content="Adicionar fornecedor"
            color="teal"
            @click="$router.push('cadastro-fornecedor')"
          />
        </div>
      </div>

      <sui-table celled selectable>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell></sui-table-header-cell>
            <sui-table-header-cell>Código</sui-table-header-cell>
            <sui-table-header-cell>Nome</sui-table-header-cell>
            <sui-table-header-cell>CPF/CNPJ</sui-table-header-cell>
            <sui-table-header-cell>Cidade</sui-table-header-cell>
            <sui-table-header-cell>Telefone</sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>

        <sui-table-body>
          <sui-table-row
            v-for="fornecedor in fornecedores"
            :key="fornecedor._id"
          >
            <sui-table-cell
              ><sui-checkbox v-model="selected" :value="fornecedor._id" />
            </sui-table-cell>
            <sui-table-cell @click="goToDetails(fornecedor._id)">{{
              fornecedor.codigo
            }}</sui-table-cell>
            <sui-table-cell @click="goToDetails(fornecedor._id)">{{
              fornecedor.nome_razao
            }}</sui-table-cell>
            <sui-table-cell @click="goToDetails(fornecedor._id)">{{
              fornecedor.cpf_cnpj
            }}</sui-table-cell>
            <sui-table-cell @click="goToDetails(fornecedor._id)">{{
              fornecedor.cidade
            }}</sui-table-cell>
            <sui-table-cell @click="goToDetails(fornecedor._id)">{{
              fornecedor.telefone
            }}</sui-table-cell>
          </sui-table-row>
        </sui-table-body>
      </sui-table>
    </div>
  </div>
</template>

<script>
import { getFornecedores, deleteFornecedores } from "../../services/apiService";
import { normalizeAndSearch } from "../../helpers";

export default {
  name: "Fornecedores",
  components: {},
  data() {
    return {
      selected: [],
      loading: false,
      search: ""
    };
  },
  methods: {
    goToDetails(id) {
      this.$router.push({ name: "detalhes-fornecedor", params: { id } });
    },
    async removeMultiple() {
      this.loading = true;
      const res = await deleteFornecedores(this.selected);
      if (res) this.$store.commit("removeFornecedores", this.selected);
      this.loading = false;
    }
  },
  async mounted() {
    if (this.$store.state.api.fornecedores.count == null)
      await getFornecedores();
  },
  computed: {
    fornecedores() {
      return this.$store.state.api.fornecedores.data.filter(fornecedor => {
        return (
          normalizeAndSearch(fornecedor.cpf_cnpj, this.search) ||
          normalizeAndSearch(fornecedor.nome_razao, this.search)
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#fornecedores-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .top-segment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ui.input {
      width: 30rem;
    }
  }
}
</style>
