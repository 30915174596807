<template>
  <div>
    <VueHtml2pdf
      :manual-pagination="true"
      :enable-download="true"
      ref="pdfComponent"
    >
      <section slot="pdf-content" ref="content" id="content">
        <h2>Restaurante {{ name }}</h2>

        <sui-table basic="very" celled>
          <sui-table-header>
            <sui-table-row>
              <sui-table-header-cell>Produto</sui-table-header-cell>
              <sui-table-header-cell>Quantidade</sui-table-header-cell>
              <sui-table-header-cell>Preço unitário</sui-table-header-cell>
              <sui-table-header-cell>Preço total</sui-table-header-cell>
            </sui-table-row>
          </sui-table-header>
          <sui-table-body>
            <sui-table-row
              v-for="item in this.$store.state.fornecedor.pedido.produtos"
              :key="item.product.id"
            >
              <sui-table-cell>{{ item.product.name }}</sui-table-cell>
              <sui-table-cell>
                {{ item.quantity }} {{ item.product.un }}
              </sui-table-cell>
              <sui-table-cell>{{ currency(item.price) }}</sui-table-cell>
              <sui-table-cell>
                Total: {{ currency(item.quantity * item.price) }}
              </sui-table-cell>
            </sui-table-row>
          </sui-table-body>
        </sui-table>

        <h3>Total:{{ currency(total) }}</h3>
      </section>
    </VueHtml2pdf>
    <sui-button content="Gerar PDF" @click="generatePDF" color="teal" />
    <div id="pdf"></div>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "PedidoDetalhes",
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      total: 0,
      name: "",
    };
  },
  methods: {
    currency(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        maximumFractionDigits: 2,
      }).format(amount);
    },
    generatePDF() {
      this.$refs.pdfComponent.generatePdf();
    },
  },
  mounted() {
    this.name = this.$route.query.name;

    this.$store.state.fornecedor.pedido.produtos.map((prod) => {
      this.total += prod.quantity * prod.price;
    });
  },
};
</script>

<style lang="scss" scoped>
#content {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
