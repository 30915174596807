<template>
  <div id="cadastro-restaurante-wrapper">
    <h3>Painel Adminstrativo</h3>
    <sui-divider />
    <sui-segment>
      <h3>Restaurantes</h3>
      <sui-button
        content="Ver restaurantes"
        color="teal"
        icon="expand arrows alternate"
        label-position="left"
        @click="$router.push('/admin/restaurantes')"
      />
      <sui-button
        content="Cadastrar restaurante"
        primary
        icon="plus"
        label-position="left"
        @click="$router.push({ name: 'novo-restaurante' })"
      />
    </sui-segment>
    <sui-segment>
      <h3>Fornecedores</h3>
      <sui-button
        content="Ver fornecedores"
        color="teal"
        icon="expand arrows alternate"
        label-position="left"
        @click="$router.push('/admin/fornecedores')"
      />
      <sui-button
        content="Cadastrar fornecedor"
        primary
        icon="plus"
        label-position="left"
        @click="$router.push({ name: 'novo-fornecedor' })"
      />
    </sui-segment>
    <sui-button
      content="Sair"
      color="red"
      icon="sign-out"
      label-position="left"
      @click="signOut"
    />
  </div>
</template>

<script>
import TextArea from "../../components/TextArea";
import { toast } from "../../helpers";
import axios from "axios";

export default {
  name: "CadastrarRestaurante",
  methods: {
    signOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      this.$router.push("/login");
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#cadastro-restaurante-wrapper {
  margin: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
}
</style>
