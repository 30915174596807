<template>
  <sui-form>
    <sui-form-field>
      <label>Nome</label>
      <input placeholder="Nome" v-model="endereco.nome" />
    </sui-form-field>
    <sui-form-field>
      <label>CEP</label>
      <input placeholder="CEP" maxlength="8" v-model="endereco.cep" />
    </sui-form-field>
    <sui-form-field>
      <label>Rua</label>
      <input placeholder="Rua" v-model="endereco.rua" />
    </sui-form-field>
    <sui-form-field>
      <label>Bairro</label>
      <input placeholder="Bairro" v-model="endereco.bairro" />
    </sui-form-field>
    <sui-form-field>
      <label>Número</label>
      <input placeholder="Número" v-model="endereco.numero" />
    </sui-form-field>
  </sui-form>
</template>

<script>
import axios from "axios";
import { toast } from "../../helpers";

export default {
  name: "Delivery",
  props: {
    entrega: Object
  },
  data() {
    return {
      endereco: {
        nome: "",
        cep: "",
        bairro: "",
        rua: "",
        numero: ""
      }
    };
  },
  watch: {
    "endereco.cep": async function(val) {
      if (val.length == 8) {
        let res = await axios.get(`https://viacep.com.br/ws/${val}/json`);
        res = res.data;
        if (!res.erro) {
          this.endereco.estado = res.uf;
          this.endereco.cidade = res.localidade;
          this.endereco.bairro = res.bairro;
          this.endereco.rua = res.logradouro;
        } else {
          toast("error", "CEP inválido, tente outro");
          this.endereco.estado = "";
          this.endereco.cidade = "";
          this.endereco.bairro = "";
          this.endereco.rua = "";
        }
      }
    },
    endereco: {
      handler() {
        this.$emit("formularios", { entrega: this.endereco });
      },
      deep: true
    }
  },
  mounted() {
    if (Object.keys(this.entrega).length !== 0) this.endereco = this.entrega;
  }
};
</script>
