<template>
  <div id="produtos-wrapper">
    <h3>Usuários</h3>
    <div>
      <div class="top-segment">
        <div>
          <sui-input
            placeholder="Pesquisar por nome, email ou cargo"
            v-model="search"
            icon="seach"
          />
        </div>

        <div>
          <sui-button
            icon="trash"
            color="red"
            :disabled="selected.length == 0"
            @click="removeMultiple"
            :loading="loading"
          />
          <sui-button
            icon="plus"
            label-position="left"
            content="Cadastrar usuário"
            color="teal"
            @click="$router.push('cadastro-usuario')"
          />
        </div>
      </div>

      <sui-table celled>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell></sui-table-header-cell>
            <sui-table-header-cell>Nome</sui-table-header-cell>
            <sui-table-header-cell>Cargo</sui-table-header-cell>
            <sui-table-header-cell>Email</sui-table-header-cell>
            <sui-table-header-cell>Ações</sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>

        <sui-table-body>
          <sui-table-row v-for="user in users" :key="user._id">
            <sui-table-cell
              ><sui-checkbox v-model="selected" :value="user._id" />
            </sui-table-cell>
            <sui-table-cell>{{ user.nome }}</sui-table-cell>
            <sui-table-cell>{{ user.role }}</sui-table-cell>
            <sui-table-cell>{{ user.email }}</sui-table-cell>

            <sui-table-cell class="options">
              <div
                data-tooltip="Redefinir Senha"
                data-inverted
                @click="toggle(user)"
              >
                <i class="address card outline icon"></i>
              </div>
            </sui-table-cell>
          </sui-table-row>
        </sui-table-body>
      </sui-table>
    </div>

    <sui-modal v-model="open" size="tiny">
      <sui-modal-header
        >Redefinir a senha do usuário: {{ selectedUser.nome }}</sui-modal-header
      >
      <sui-modal-content image>
        <sui-form class="reset-form" @submit="redefinePassword">
          <sui-form-field>
            <label>Nova Senha</label>
            <sui-input
              required
              v-model="senha.password"
              placeholder="Senha Nova"
              icon-position="left"
              icon="lock"
              type="password"
              fluid
              style="width: 30rem"
          /></sui-form-field>
          <sui-form-field>
            <label>Confirmação de Nova Senha</label>
            <sui-input
              required
              v-model="senha.confpassword"
              placeholder="Confirmação de Senha Nova"
              icon-position="left"
              icon="lock"
              type="password"
              fluid
          /></sui-form-field>
        </sui-form>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button
          positive
          @click="redefinePassword"
          type="submit"
          :espera="espera"
        >
          Confirmar Alterações
        </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import {
  getUsers,
  deleteUsers,
  redefinePassword,
} from "../../services/apiService";
import { normalizeAndSearch, toast } from "../../helpers";

export default {
  name: "Usuarios",
  components: {},
  data() {
    return {
      search: "",
      selected: [],
      senha: {},
      loading: false,
      open: false,
      selectedUser: {},
      espera: false,
    };
  },
  methods: {
    toggle(user) {
      this.selectedUser = user;
      this.open = !this.open;
    },

    async redefinePassword(e) {
      this.espera = true;
      e.preventDefault();
      const data = {
        id: this.selectedUser._id,
        senha: this.senha.password,
      };
      if (this.senha.password != this.senha.confpassword) {
        toast("error", "As senhas devem ser iguais");
        return;
      }
      await redefinePassword(data);
      this.espera = false;
      this.open = !this.open;
    },

    async removeMultiple() {
      this.loading = true;
      const res = await deleteUsers(this.selected);
      if (res) this.$store.commit("removeUsers", this.selected);
      this.loading = false;
    },
  },
  computed: {
    users: {
      get() {
        return this.$store.state.api.users.data.filter((user) => {
          return (
            normalizeAndSearch(user.nome, this.search) ||
            normalizeAndSearch(user.role, this.search) ||
            normalizeAndSearch(user.email, this.search)
          );
        });
      },
      set() {},
    },
  },
  async mounted() {
    if (this.$store.state.api.users.count == null) await getUsers();
    this.users = this.$store.state.api.users.data;
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#produtos-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .top-segment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .ui.input {
      width: 30rem;
    }
  }
  .options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    > * {
      cursor: pointer;
    }
  }
}
</style>
