<template>
  <div id="cardapio-wrapper">
    <h3>Cardápio</h3>
    <div>
      <div class="top-segment">
        <sui-input
          placeholder="Pesquisar por item, código ou categoria"
          icon="search"
          v-model="search"
        />

        <div>
          <sui-button
            icon="plus"
            label-position="left"
            content="Adicionar item"
            color="teal"
            @click="$router.push('cadastro-ficha-tecnica')"
          />
        </div>
      </div>

      <sui-card-group :items-per-row="5" stackable>
        <sui-card class="item-card" v-for="item in cardapio" :key="item._id">
          <img
            :src="item.imageUrl"
            @click="goToDetails(item._id)"
            v-if="item.imageUrl"
          />
          <img
            src="../../assets/food.jpg"
            @click="goToDetails(item._id)"
            v-else
          />
          <sui-card-content @click="goToDetails(item._id)">
            <sui-card-header>
              {{ item.titulo }}
            </sui-card-header>
            <sui-card-meta>
              {{ item.categoria }}
              <sui-label
                size="mini"
                v-for="ingrediente in item.ficha"
                :key="ingrediente._id"
              >
                {{ ingrediente.item }}
              </sui-label>
            </sui-card-meta>
            <sui-card-description>
              {{ item.descricao_prato }}
              <sui-label v-show="item.produtos.length == 0" color="yellow">
                Sem itens cadastrados
              </sui-label>
            </sui-card-description>
          </sui-card-content>
          <sui-card-content extra>
            {{ currency(item.valor_venda) }}
            <sui-icon
              name="trash"
              slot="right"
              color="red"
              @click="setDelete(item._id)"
            />
          </sui-card-content>
        </sui-card>
      </sui-card-group>
    </div>

    <sui-modal size="tiny" v-model="deleteVisible">
      <sui-modal-header>Confirmação</sui-modal-header>
      <sui-modal-content>
        Você tem certeza que deseja excluir esse item?
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button @click="deleteVisible = false">Cancelar</sui-button>
        <sui-button color="red" @click="deleteFicha" :loading="loading">
          Excluir
        </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { getCardapio, deleteFichaTecnica } from "../../services/apiService";
import { normalizeAndSearch } from "../../helpers";

export default {
  name: "Cardapio",
  components: {},
  data() {
    return {
      selected: [],
      deleteVisible: false,
      deleteId: null,
      loading: false,
      search: "",
    };
  },
  methods: {
    goToDetails(id) {
      this.$router.push({ name: "detalhes-cardapio", params: { id } });
    },
    currency(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(amount);
    },
    setDelete(id) {
      this.deleteVisible = true;
      this.deleteId = id;
    },
    async deleteFicha() {
      this.loading = true;
      const res = await deleteFichaTecnica(this.deleteId);
      if (res) this.$store.commit("removeCardapio", this.deleteId);
      this.loading = false;
      this.deleteVisible = false;
    },
  },
  async mounted() {
    if (this.$store.state.api.cardapio.count == null) await getCardapio();
  },
  computed: {
    cardapio() {
      return this.$store.state.api.cardapio.data.filter((item) => {
        return (
          normalizeAndSearch(item.titulo, this.search) ||
          normalizeAndSearch(item.categoria, this.search) ||
          `${item.codigo}`.includes(this.search)
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#cardapio-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }

  img {
    height: 100px;
    object-fit: cover;
  }

  .top-segment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;

    .ui.input {
      width: 30rem;
    }
  }
  .options {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    > * {
      cursor: pointer;
    }
  }
  .item-card {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .top-segment {
      flex-direction: column;

      .ui.input {
        width: auto;
      }
    }
  }
}
</style>
