import api from "./api";
import { toast } from "../helpers";

export const getCardapio = async (tenantSlug, categoria) => {
  try {
    const res = await api.get("cardapio", {
      params: { categoria },
      headers: {
        tenant: tenantSlug,
        searchbyslug: true,
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getCategorias = async (tenantSlug) => {
  try {
    const res = await api.get("categorias", {
      headers: {
        tenant: tenantSlug,
        searchbyslug: true,
      },
    });
    return res.data.res;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getRestaurantInfo = async (tenantSlug) => {
  try {
    const res = await api.get("tenant", {
      headers: {
        tenant: tenantSlug,
      },
    });
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const postPedido = async (data, tenantSlug) => {
  try {
    const res = await api.post("pedido-online", data, {
      headers: {
        tenant: tenantSlug,
        searchbyslug: true,
      },
    });
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const postPedidoLoc = async (data, tenantSlug) => {
  try {
    const res = await api.post("pedido-online-loc", data, {
      headers: {
        tenant: tenantSlug,
        searchbyslug: true,
      },
    });
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};
