<template>
  <footer class="footer-wrapper">
    <!-- <div class="upper-footer">
      <div class="logo-info">
        <img src="../assets/logo.png" />
        <p>
          Falco Desenvolvimento de Websites LTDA <br />
          CNPJ: 31.483.476/0001-54<br />
          Contato: contato@falcodev.com.br
        </p>
      </div>

      <div class="links">
        Links úteis
        <a>Termos de uso</a>
      </div>
    </div>
    <sui-divider /> -->
    <div class="down-footer">
      <a
        href="https://www.instagram.com/falcodev/"
        target="_blank"
        rel="noopener noreferrer"
        ><sui-icon name="instagram" size="large"
      /></a>
      <p>
        Feito com <sui-icon name="heart" color="red" size="small" /> por
        <a
          href="https://falcodev.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
          Falcodev
        </a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
footer {
  background-color: $primary-gray;
  display: flex;
  flex-direction: column;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  // height: 15rem;

  img {
    margin-right: 1rem;
    padding-bottom: 1rem;
    width: 5rem;
  }

  .logo-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .upper-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 1rem 0;

    .links {
      display: flex;
      flex-direction: column;
      align-items: center;

      a {
        cursor: pointer;
        text-decoration: inherit;
        color: inherit;
        transition: 0.1s;
        margin: 1rem;
      }

      a:hover {
        color: $divider-color;
      }
    }
  }

  .down-footer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    padding-top: 1rem;

    a {
      margin-bottom: 0.2rem;
      text-decoration: none;
      color: #fff;
    }

    p {
      a {
        color: $light-primary-color;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .upper-footer {
      flex-direction: column-reverse;
    }
    .logo-info {
      flex-direction: column;
    }
  }
}
</style>
