<template>
  <div id="detalhamento">
    <h2>
      <sui-icon name="arrow left" @click="goBackModal = true" />
      Restaurante: {{ name }}
    </h2>
    <sui-loader active v-if="loading" />
    <GChart
      type="ColumnChart"
      :data="chartData"
      :options="chartOptions"
      :events="chartEvents"
      ref="gChartForn"
      v-else
    />

    <EstoqueDetalhes
      :estoque="selectedEstoque"
      :show="selected"
      v-if="selectedEstoque"
      @toggle-modal="selected = !selected"
    >
      <sui-button
        content="Adicionar ao pedido"
        color="teal"
        @click="addSomething = true"
      />
    </EstoqueDetalhes>

    <sui-button
      primary
      icon="clipboard"
      circular
      size="huge"
      class="order-button"
      @click="checkPedidoModal = true"
    />

    <!-- [MODAL] Mostrar pedido -->
    <sui-modal v-model="checkPedidoModal" size="tiny">
      <sui-modal-header>Pedido atual</sui-modal-header>
      <sui-modal-content
        v-if="this.$store.state.fornecedor.pedido.produtos.length != 0"
      >
        <sui-table basic="very" celled>
          <sui-table-body>
            <sui-table-row
              v-for="item in this.$store.state.fornecedor.pedido.produtos"
              :key="item.product.id"
            >
              <sui-table-cell>{{ item.product.name }}</sui-table-cell>
              <sui-table-cell>
                {{ item.quantity }} {{ item.product.un }}</sui-table-cell
              >
              <sui-table-cell>{{ currency(item.price) }}</sui-table-cell>
              <sui-table-cell>
                Total: {{ currency(item.quantity * item.price) }}
              </sui-table-cell>
              <sui-table-cell>
                <sui-icon name="pencil" @click="editItem(item.product.id)" />
                <sui-icon name="times" @click="removePedido(item.product.id)" />
              </sui-table-cell>
            </sui-table-row>
          </sui-table-body>
        </sui-table>
        <b>Total: {{ currency(total) }}</b>
      </sui-modal-content>
      <sui-modal-content v-else>
        Você não possui nada no pedido, comece adicionando algo.
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button
          content="Exportar"
          color="teal"
          :disabled="this.$store.state.fornecedor.pedido.produtos.length == 0"
          @click="generatePDF"
        />
      </sui-modal-actions>
    </sui-modal>

    <!-- [MODAL] Adicionar produto ao pedido -->
    <sui-modal v-model="addSomething" v-if="selectedEstoque" size="tiny">
      <sui-modal-header>Adicionar ao pedido</sui-modal-header>
      <sui-modal-content>
        <sui-segment>
          <p><b>Ítem: </b>{{ selectedEstoque.id_produto.item }}</p>
          <p>
            <b>Estoque atual:</b>{{ selectedEstoque.estoque_atual }}
            {{ selectedEstoque.id_produto.unidade }} |
            <b>Quantidade indicada para reposição:</b>
            {{ selectedEstoque.estoque_medio }}
            {{ selectedEstoque.id_produto.unidade }}
          </p>
        </sui-segment>
        <sui-form @submit.prevent="addOrder">
          <sui-form-field>
            <label>Quantidade</label>
            <input
              placeholder="Quantidade"
              v-model="itemToAdd.quantity"
              required
            />
          </sui-form-field>
          <sui-form-field>
            <label>Preço</label>
            <input
              placeholder="Preço"
              v-model="itemToAdd.price"
              type="number"
              required
            />
          </sui-form-field>
          <sui-button content="Adicionar" color="teal" type="submit" required />
        </sui-form>
        <sui-segment>
          <p>
            <b>Total: </b>{{ currency(itemToAdd.quantity * itemToAdd.price) }}
          </p>
        </sui-segment>
      </sui-modal-content>
    </sui-modal>

    <!-- [MODAL] Voltar para lista de restaurantes -->
    <sui-modal v-model="goBackModal" size="mini">
      <sui-modal-header>Tem certeza que quer voltar?</sui-modal-header>
      <sui-modal-content>
        Você perderá o progresso do pedido para esse restaurante.
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button content="Cancelar" @click="goBackModal = false" />
        <sui-button content="Voltar" color="red" @click="goBack" />
      </sui-modal-actions>
    </sui-modal>

    <VueHtml2pdf
      :manual-pagination="true"
      :enable-download="true"
      :filename="`Pedido Restaurante ${name} - ${new Date()}`"
      ref="pdfComponent"
    >
      <section slot="pdf-content">
        <PedidoPDF :total="total" />
      </section>
    </VueHtml2pdf>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts";
import VueHtml2pdf from "vue-html2pdf";

import { toast } from "../../helpers";

import EstoqueDetalhes from "../../components/Modais/EstoqueDetalhes.vue";
import PedidoPDF from "../../components/PedidoPDF.vue";

import { getRestauranteDetalhes } from "../../services/fornecedorService";

export default {
  name: "RestauranteDetalhe",
  components: { GChart, VueHtml2pdf, EstoqueDetalhes, PedidoPDF },
  data() {
    return {
      chartOptions: {
        isStacked: true,
        chart: {
          title: "Estoque",
          subtitle: "Estoque atual",
        },
        legend: { position: "top", maxLines: 3 },
        colors: ["#e81e03", "#00933b", "#9fd3f4"],
      },
      chartData: [
        [
          "Produto",
          "Estoque mínimo",
          "Estoque atual",
          "Potencial de estocagem ideal",
        ],
      ],
      chartEvents: {
        select: () => {
          const chart = this.$refs.gChartForn.chartObject;
          const selection = chart.getSelection()[0];
          this.selectedEstoque = this.estoque[selection.row];
          this.selected = true;
        },
      },
      name: null,
      estoque: [],
      loading: false,
      selected: false,
      selectedEstoque: null,
      goBackModal: false,
      addSomething: false,
      itemToAdd: {
        product: {
          id: null,
          name: null,
          un: null,
        },
        quantity: null,
        price: null,
      },
      checkPedidoModal: false,
      total: 0,
    };
  },
  methods: {
    goBack() {
      this.$store.commit("clearFornecedorPedido");
      this.$router.push("/fornecedor/restaurantes");
    },
    currency(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        maximumFractionDigits: 2,
      }).format(amount);
    },
    async addOrder() {
      const { _id, item, unidade } = this.selectedEstoque.id_produto;
      this.itemToAdd.product = { id: _id, name: item, un: unidade };

      const exists = await this.$store.dispatch("checkProduct", _id);
      if (exists >= 0) {
        this.$store.commit("editProduct", {
          index: exists,
          product: this.itemToAdd,
        });
        this.addSomething = false;
        this.itemToAdd = {
          product: {
            id: null,
            name: null,
          },
          quantity: null,
          price: null,
        };
        return;
      }

      this.$store.commit("addFornecedorPedido", this.itemToAdd);
      toast("success", `${item} adicionado com sucesso!`);
      this.addSomething = false;
      this.selected = false;
      this.itemToAdd = {
        product: {
          id: null,
          name: null,
        },
        quantity: null,
        price: null,
      };
      this.$store.state.fornecedor.pedido.produtos.map((prod) => {
        this.total += prod.quantity * prod.price;
      });
    },
    generatePDF() {
      this.$refs.pdfComponent.generatePdf();
    },
    removePedido(id) {
      this.$store.commit("removeFornecedorPedido", id);
    },
    editItem(id) {
      this.itemToAdd = this.$store.state.fornecedor.pedido.produtos.find(
        (x) => x.product.id == id
      );
      this.addSomething = true;
      this.$store.state.fornecedor.pedido.produtos.map((prod) => {
        this.total += prod.quantity * prod.price;
      });
    },
  },
  async mounted() {
    this.loading = true;
    this.name = this.$route.query.name;
    const estoque = await getRestauranteDetalhes(
      localStorage.getItem("cpf_cnpj"),
      this.$route.params.id
    );

    estoque.forEach((produto) => {
      this.chartData.push([
        produto.id_produto.item,
        produto.estoque_minimo ? produto.estoque_minimo : 0,
        produto.estoque_atual ? produto.estoque_atual : 0,
        produto.estoque_medio ? produto.estoque_medio : 0,
      ]);
    });
    this.estoque = estoque;
    this.loading = false;

    this.$store.commit("setRestaurantId", this.$route.params.id);
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#detalhamento {
  padding-top: 2rem;

  .icon {
    cursor: pointer;
  }

  h2 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }

  .order-button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 8rem;
    margin-right: 4rem;
  }
}
</style>
