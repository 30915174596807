<template>
  <div>
    <Item
      v-for="item in this.$store.state.cliente.carrinho"
      :key="item.item._id"
      :nome="item.item.titulo"
      :qtde="item.qtde"
      :valor="item.item.valor_venda"
      :id="item.item._id"
    />
  </div>
</template>

<script>
import Item from "./Item";

export default {
  name: "Items",
  components: { Item }
};
</script>
