<template lang="html">
  <div id="gorjeta-wrapper">
    <h3>Gorjeta</h3>
    <div class="content">
      <div class="date-select">
        Selecione um mês: <sui-input type="month" required v-model="date" />
      </div>

      <p v-if="!loading && gorjetas.length == 0">
        Nenhum registro presente.
      </p>
      <div v-else-if="loading"><sui-loader active /></div>
      <sui-table striped collapsing celled v-else>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell>Nome</sui-table-header-cell>
            <sui-table-header-cell>Valor</sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>
        <sui-table-body>
          <sui-table-row v-for="gorjeta in gorjetas" :key="gorjeta._id">
            <sui-table-cell>{{ gorjeta.user.nome }}</sui-table-cell>
            <sui-table-cell>{{ handleCurrency(gorjeta.total) }}</sui-table-cell>
          </sui-table-row>
        </sui-table-body>
      </sui-table>
    </div>
  </div>
</template>

<script>
import { getGorjeta } from "../services/apiService";
import { currency } from "../helpers";

export default {
  name: "Gorjeta",
  data() {
    return {
      date: null,
      loading: false,
      gorjetas: []
    };
  },
  methods: {
    handleCurrency(amount) {
      return currency(amount);
    }
  },
  watch: {
    date: async function(val) {
      this.loading = true;
      this.gorjetas = await getGorjeta(this.date);
      this.gorjetas = this.gorjetas.filter(e => {
        return e._id != null;
      });
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
#gorjeta-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .date-select {
      margin-bottom: 1rem;
    }
  }
}
</style>
