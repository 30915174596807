<template>
  <sui-card id="item-wrapper">
    <sui-card-content>
      <sui-card-header>{{ nome }} </sui-card-header>
      <sui-card-meta>
        <span>{{ qtde }}x </span>
      </sui-card-meta>
      <sui-card-description class="value-delete"
        >{{ currency(valor * qtde) }}
        <sui-icon name="trash" color="red" @click="deleteOne" />
      </sui-card-description>
    </sui-card-content>
  </sui-card>
</template>

<script>
export default {
  name: "Item",
  props: {
    qtde: Number,
    nome: String,
    valor: Number,
    id: String
  },
  methods: {
    currency(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(amount);
    },
    deleteOne() {
      this.$store.commit("removeFromCart", this.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#item-wrapper {
  width: 100%;

  .value-delete {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>
