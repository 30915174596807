<template>
  <div>
    {{ restaurantInfo.rua }}, {{ restaurantInfo.numero }}
    <br />
    {{ restaurantInfo.complemento }}
    <br />
    {{ restaurantInfo.bairro }} - {{ restaurantInfo.cidade }}
    <br />
    {{ restaurantInfo.cep }}
  </div>
</template>

<script>
export default {
  name: "Retirada",
  props: {
    restaurantInfo: Object
  }
};
</script>
