<template lang="html">
  <div id="pagamento-wrapper" v-if="mesa">
    <h3>{{ mesa.apelido }}</h3>
    <h4>
      Mesas:
      <sui-label v-for="n in mesa.mesa" :key="n">
        {{ n }}
      </sui-label>
    </h4>

    <sui-segment class="pagamento-segment">
      <sui-form @submit="pagar">
        <sui-form-fields fields="three">
          <sui-form-field>
            <label>Total</label>
            <input :value="handleCurrency(mesa.total)" readonly type="text" />
          </sui-form-field>
          <sui-form-field>
            <label>Pagar valor total</label>
            <sui-checkbox toggle label="Valor total" v-model="toggleTotal" />
          </sui-form-field>
          <sui-form-field v-show="!toggleTotal">
            <label>Valor</label>
            <div class="ui labeled input">
              <div class="ui label">R$</div>
              <input type="number" v-model="pay.valor" step="0.1" />
            </div>
          </sui-form-field>
        </sui-form-fields>
        <h4>Gorjeta</h4>
        <sui-form-fields fields="two">
          <sui-form-field>
            <label>{{
              toggleGorjeta ? "Valor proporcional" : "Valor personalizado"
            }}</label>
            <sui-checkbox
              toggle
              :label="toggleGorjeta ? handleCurrency(pay.gorjeta) : ''"
              v-model="toggleGorjeta"
            />
          </sui-form-field>
          <sui-form-field v-show="!toggleGorjeta">
            <label>Valor</label>
            <div class="ui labeled input">
              <div class="ui label">R$</div>
              <input type="number" v-model="pay.gorjeta" step="0.1" />
            </div>
          </sui-form-field>
        </sui-form-fields>
        <h4>Forma de pagamento</h4>
        <sui-form-field>
          <sui-dropdown
            :options="payment"
            placeholder="Forma de pagamento"
            selection
            v-model="pay.forma_pagamento"
            required
          />
        </sui-form-field>
        <h4>Observações</h4>
        <sui-form-field>
          <TextArea placeholder="Observações" v-model="pay.observacao" />
        </sui-form-field>

        <div class="form-buttons">
          <sui-button content="Cancelar" @click="cancel" />
          <sui-button
            :content="
              'Pagar ' +
                handleCurrency(
                  parseFloat(pay.valor || 0) + parseFloat(pay.gorjeta || 0)
                )
            "
            color="teal"
            icon="money bill"
            label-position="left"
            type="submit"
            :loading="loading"
          />
        </div>
      </sui-form>
    </sui-segment>
  </div>
</template>

<script>
import { getMesa, pagar, getPedidos } from "../../services/pdvService";
import { currency, toast } from "../../helpers";

import TextArea from "../../components/TextArea";

export default {
  name: "Pagamento",
  data() {
    return {
      mesa: null,
      toggleGorjeta: false,
      toggleTotal: true,
      pay: {
        valor: null,
        gorjeta: 0,
      },
      payment: [
        { text: "Crédito", value: "credito" },
        { text: "Débito", value: "debito" },
        { text: "Dinheiro", value: "dinheiro" },
        { text: "Vale refeição", value: "vale" },
        { text: "Outros", value: "outros" },
      ],
      loading: false,
    };
  },
  watch: {
    toggleGorjeta(newValue) {
      if (!newValue) this.pay.gorjeta = 0;
      else this.pay.gorjeta = this.pay.valor * 0.1;
    },
    "pay.valor": function(newValue) {
      if (this.pay.gorjeta) this.pay.gorjeta = newValue * 0.1;
    },
    toggleTotal(newValue) {
      if (newValue) this.pay.valor = this.mesa.total;
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    let mesa;
    if (this.$store.state.pdv.pedidos.count == null) await getPedidos();
    mesa = this.$store.state.pdv.pedidos.data.find((x) => x._id == id);

    this.mesa = mesa;
    this.pay.valor = mesa.total;
    this.pay.gorjeta = 0;
  },
  methods: {
    handleCurrency(amount) {
      return currency(amount);
    },
    async pagar(e) {
      e.preventDefault();
      this.loading = true;
      if (!this.pay.forma_pagamento) {
        toast("error", "Insira uma forma de pagamento!");
        this.loading = false;
        return;
      }
      const { id } = this.$route.params;
      const data = {
        id,
        pagamento: this.pay,
      };
      await pagar(data);
      this.mesa = this.$store.state.pdv.pedidos.data.find((x) => x._id == id);
      if (this.mesa.total == 0)
        this.$router.push({ name: "detalhes-mesa", params: { id } });

      this.toggleGorjeta = this.toggleTotal = true;
      this.loading = true;
    },
    cancel() {
      const { id } = this.$route.params;
      this.$router.push({ name: "detalhes-mesa", params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#pagamento-wrapper {
  padding: 2rem;
  h3,
  h4 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .pagamento-segment {
    margin: auto;
    width: 50%;
  }
  .total-checkbox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and(max-width: 768px) {
    .pagamento-segment {
      width: 100% !important;
    }
  }
}
</style>
