import api from "./api";
import { toast } from "../helpers";
import store from "../store";

export const getRestaurantes = async (cpf_cnpj) => {
  try {
    const res = await api.get(`fornecedor/restaurantes/${cpf_cnpj}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    store.commit("set", {
      type: "fornecedor",
      name: "restaurantes",
      item: res.data,
    });
  } catch (e) {
    toast("error", e.response.data.error);
  }
};

export const getRestauranteDetalhes = async (cnpj, id) => {
  try {
    const res = await api.get(`fornecedor/produtos/${cnpj}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return res.data;
  } catch (e) {
    toast("error", e.response.data.error);
  }
};
