<template>
  <div id="cadastro-fornecedor-wrapper">
    <h3>Cadastro de Fornecedor</h3>
    <sui-form @submit="sendForm">
      <sui-header dividing>Dados do Usuário</sui-header>
      <sui-form-fields fields="three">
        <sui-form-field>
          <label>
            Nome
          </label>
          <sui-input
            required
            v-model="cadastro.nome"
            placeholder="Nome"
            icon-position="left"
            icon="user"
        /></sui-form-field>
        <sui-form-field>
          <label>
            Email
          </label>
          <sui-input
            required
            v-model="cadastro.email"
            placeholder="Email"
            icon-position="left"
            icon="at"
            type="email"
          />
        </sui-form-field>
        <sui-form-field>
          <label>
            Senha
          </label>
          <sui-input
            required
            v-model="cadastro.senha"
            placeholder="Senha"
            icon-position="left"
            icon="lock"
            type="password"
          />
        </sui-form-field>
      </sui-form-fields>

      <sui-header dividing>Dados Cadastrais</sui-header>
      <sui-form-fields fields="three">
        <sui-form-field
          ><sui-dropdown
            placeholder="Tipo de pessoa *"
            selection
            v-model="fornecedor.tipo_pessoa"
            :options="tipo_pessoa_options"
        /></sui-form-field>
        <sui-form-field
          ><sui-input
            placeholder="CPF/CNPJ *"
            v-model="fornecedor.cpf_cnpj"
            required
            maxlength="14"
        /></sui-form-field>
        <sui-form-field
          ><sui-input
            placeholder="Nome/Razão *"
            v-model="fornecedor.nome_razao"
            required
        /></sui-form-field>
      </sui-form-fields>
      <sui-form-fields fields="three">
        <sui-form-field
          ><sui-input placeholder="Fantasia" v-model="fornecedor.fantasia"
        /></sui-form-field>
        <sui-form-field>
          <sui-dropdown
            placeholder="Contribuinte"
            selection
            v-model="fornecedor.str_contribuinte_icms"
            :options="tipo_contribuinte"
          />
        </sui-form-field>
        <sui-form-field
          ><sui-input
            placeholder="Número do contribuinte"
            v-model="fornecedor.num_contribuinte_icms"
        /></sui-form-field>
      </sui-form-fields>
      <sui-header dividing>Endereço</sui-header>
      <sui-form-fields fields="four">
        <sui-form-field
          ><sui-input placeholder="CEP" v-model="fornecedor.cep" maxlength="8"
        /></sui-form-field>
        <sui-form-field
          ><sui-input placeholder="Estado" v-model="fornecedor.estado" readonly
        /></sui-form-field>
        <sui-form-field
          ><sui-input placeholder="Cidade" v-model="fornecedor.cidade" readonly
        /></sui-form-field>
        <sui-form-field
          ><sui-input placeholder="Bairro" v-model="fornecedor.bairro" readonly
        /></sui-form-field>
      </sui-form-fields>
      <sui-form-fields fields="three">
        <sui-form-field
          ><sui-input placeholder="Rua" v-model="fornecedor.rua" readonly
        /></sui-form-field>
        <sui-form-field
          ><sui-input placeholder="Número" v-model="fornecedor.numero"
        /></sui-form-field>
        <sui-form-field
          ><sui-input placeholder="Complemento" v-model="fornecedor.complemento"
        /></sui-form-field>
      </sui-form-fields>
      <sui-header dividing>Contato</sui-header>
      <sui-form-fields fields="four">
        <sui-form-field
          ><sui-input placeholder="Responsável" v-model="fornecedor.responsavel"
        /></sui-form-field>
        <sui-form-field
          ><sui-input placeholder="Telefone" v-model="fornecedor.telefone"
        /></sui-form-field>
        <sui-form-field
          ><sui-input placeholder="E-mail" v-model="fornecedor.email"
        /></sui-form-field>
        <sui-form-field
          ><sui-input placeholder="Website" v-model="fornecedor.website"
        /></sui-form-field>
      </sui-form-fields>
      <sui-header dividing>Dados Adicionais</sui-header>
      <sui-form-fields fields="three">
        <sui-form-field
          ><sui-dropdown
            placeholder="Tipo de contato"
            selection
            v-model="fornecedor.tipo_contato"
            :options="tipo_contato"
        /></sui-form-field>
        <sui-form-field
          ><sui-dropdown
            placeholder="Situação"
            selection
            v-model="fornecedor.situacao"
            :options="tipo_situacao"
        /></sui-form-field>
        <sui-form-field
          ><sui-input
            placeholder="Tempo de reposição em dias"
            v-model="fornecedor.tempo_reposicao"
        /></sui-form-field>
      </sui-form-fields>
      <sui-header dividing>Observações</sui-header>
      <TextArea v-model="fornecedor.observacao"></TextArea>
      <sui-button
        color="teal"
        class="cadastrar"
        type="submit"
        content="Cadastrar"
        :loading="loading"
      />
    </sui-form>
  </div>
</template>

<script>
import TextArea from "../../components/TextArea";
import { toast } from "../../helpers";
import { postFornecedores } from "../../services/apiService";
import api from "../../services/api";

export default {
  name: "CadastrarFornecedor",
  components: {
    TextArea,
  },
  data() {
    return {
      tipo_pessoa_options: [
        { text: "Física", value: "Física" },
        { text: "Jurídica", value: "Jurídica" },
      ],
      tipo_contribuinte: [
        { text: "Contribuinte ICMS", value: "Contribuinte ICMS" },
        {
          text: "Contribuinte isento de Inscrição no Cadastro de Contribuintes",
          value:
            "Contribuinte isento de Inscrição no Cadastro de Contribuintes",
        },
        {
          text:
            "Não contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes",
          value:
            "Não contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes",
        },
      ],
      tipo_contato: [
        { text: "Cliente", value: "Cliente" },
        { text: "Fornecedor", value: "Fornecedor" },
        { text: "Outros", value: "Outros" },
      ],
      tipo_situacao: [
        { text: "Ativo", value: "Ativo" },
        { text: "Inativo", value: "Inativo" },
      ],
      cadastro: {
        role: "Fornecedor",
      },
      fornecedor: {
        cep: "",
        estado: "",
        cidade: "",
        bairro: "",
        rua: "",
      },
      loading: false,
    };
  },
  methods: {
    async sendForm(e) {
      e.preventDefault();
      this.loading = true;
      if (!this.fornecedor.tipo_pessoa) {
        toast("error", "Preencha o tipo de pessoa");
        return;
      }
      const data = {
        ...this.cadastro,
        fornecedor: { ...this.fornecedor },
      };
      try {
        const response = await api.post("admin/register", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (response) {
          toast("success", `Fornecedor cadastrado com sucesso!`);
          this.$router.push("/admin");
        }
      } catch (err) {
        toast("error", `Ocorreu um erro ao cadastrar o fornecedor`);
        this.$router.push("/admin");
      }
    },
  },
  watch: {
    "cadastro.cep": async function(val) {
      if (val.length == 8) {
        let res = await axios.get(`https://viacep.com.br/ws/${val}/json`);
        res = res.data;
        if (!res.erro) {
          this.cadastro.estado = res.uf;
          this.cadastro.cidade = res.localidade;
          this.cadastro.bairro = res.bairro;
          this.cadastro.rua = res.logradouro;
        } else {
          toast("error", "CEP inválido, tente outro");
          this.cadastro.estado = "";
          this.cadastro.cidade = "";
          this.cadastro.bairro = "";
          this.cadastro.rua = "";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#cadastro-fornecedor-wrapper {
  margin: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .ui.input,
  .ui.dropdown {
    width: 100%;
  }
  .cadastrar {
    float: right;
    margin-top: 1rem;
  }
}
</style>
