<template>
  <div id="cadastro-wrapper">
    <div class="header">
      <div class="header-info">
        <form class="criar_prato" @submit="montar_prato" v-if="!adding">
          <sui-grid :columns="5">
            <sui-grid-row class="columns">
              <sui-grid-column :width="4">
                <sui-input
                  data-tooltip="Código"
                  data-position="bottom left"
                  placeholder="Código *"
                  v-model="cadastro.codigo"
                  type="number"
                  transparent
                  class="inputs"
                  readonly
                />
                <sui-input
                  data-tooltip="Título"
                  data-position="bottom left"
                  placeholder="Título *"
                  v-model="cadastro.titulo"
                  type="text"
                  transparent
                  class="inputs"
                  required
                />
              </sui-grid-column>
              <sui-grid-column :width="4">
                <sui-input
                  data-position="bottom left"
                  data-tooltip="Valor de Venda"
                  placeholder="Valor de Venda *"
                  v-model="cadastro.valor_venda"
                  type="number"
                  transparent
                  class="inputs"
                  required
                  step="0.01"
                />
                <sui-input
                  data-position="bottom left"
                  data-tooltip="Tempo de preparo"
                  placeholder="Tempo de Preparo"
                  v-model="cadastro.tempo_preparo"
                  type="number"
                  transparent
                  class="inputs"
                />
              </sui-grid-column>
              <sui-grid-column :width="4">
                <sui-input
                  data-position="bottom left"
                  data-tooltip="Descrição"
                  v-model="cadastro.descricao_prato"
                  placeholder="Descrição"
                  transparent
                  class="inputs textarea"
                />
                <sui-input
                  data-position="bottom left"
                  data-tooltip="Categoria"
                  v-model="cadastro.categoria"
                  placeholder="Categoria"
                  transparent
                  class="inputs textarea"
                />
              </sui-grid-column>
              <sui-grid-column :width="4">
                <sui-button color="teal" type="submit" :loading="loading"
                  >Salvar</sui-button
                >
              </sui-grid-column>
            </sui-grid-row>
          </sui-grid>
        </form>
        <div v-else class="editar_prato_header">
          <h3>Código {{ cadastro.codigo }}</h3>
          <h3>Título: {{ cadastro.titulo }}</h3>
        </div>

        <div class="criar_ficha">
          <div class="buttons-choice" v-if="type == 0">
            <sui-button
              color="teal"
              content="Adicionar ingrediente"
              icon="utensils"
              label-position="left"
              size="large"
              @click="addSomething(1)"
            />
            <sui-button
              color="teal"
              content="Adicionar etapa de preparo"
              icon="list ol"
              label-position="left"
              size="large"
              @click="addSomething(2)"
            />
          </div>

          <sui-form v-if="type == 1" @submit="add">
            <h3>Adicionar ingrediente</h3>
            <sui-form-fields fields="three" class="form-field">
              <sui-form-field>
                <label>Grupo</label>
                <sui-dropdown
                  selection
                  search
                  placeholder="Grupo"
                  :options="grupos"
                  v-model="grupo"
                />
              </sui-form-field>
              <sui-form-field>
                <label>Subgrupo</label>
                <sui-dropdown
                  selection
                  search
                  placeholder="Subgrupo"
                  :options="subgrupos"
                  v-model="subgrupo"
                />
              </sui-form-field>
              <sui-form-field>
                <label>Item</label>
                <sui-dropdown
                  selection
                  search
                  placeholder="Item"
                  :options="items"
                  v-model="produto.produto"
                />
              </sui-form-field>
            </sui-form-fields>
            <sui-form-fields fields="three" class="form-field">
              <sui-form-field>
                <label v-if="!un">Peso Líquido Operacional</label>
                <label v-else>Quantidade</label>
                <sui-input
                  v-model="produto.peso_liquido_operacional"
                  type="number"
                  step="any"
                  transparent
                  class="inputs"
                  required
                />
              </sui-form-field>
              <sui-form-field v-show="!un">
                <label>Peso Pronto</label>
                <sui-input
                  v-model="produto.peso_pronto"
                  step="any"
                  type="number"
                  transparent
                  class="inputs"
                />
              </sui-form-field>
              <sui-form-field class="form-buttons">
                <sui-button type="button" @click="cancelAdd">
                  Cancelar
                </sui-button>
                <sui-button type="submit" color="teal">Adicionar</sui-button>
              </sui-form-field>
            </sui-form-fields>
          </sui-form>

          <sui-form v-else-if="type == 2" @submit="add">
            <h3>Adicionar etapa de preparo</h3>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Tempo (em minutos)</label>
                <input type="number" v-model="passo.tempo" />
              </sui-form-field>
              <sui-form-field>
                <label>Descrição</label>
                <TextArea v-model="passo.descricao" required></TextArea>
              </sui-form-field>
            </sui-form-fields>

            <sui-form-field class="form-buttons">
              <sui-button type="button" @click="cancelAdd">Cancelar</sui-button>
              <sui-button type="submit" color="teal">Adicionar</sui-button>
            </sui-form-field>
          </sui-form>

          <sui-form v-else-if="type == 3" @submit="editIngredient">
            <h3>{{ this.prod.title }} - Editar ingrediente</h3>

            <sui-form-fields fields="three" class="form-field">
              <sui-form-field>
                <label v-if="!un">Peso Líquido Operacional</label>
                <label v-else>Quantidade</label>
                <sui-input
                  v-model="produto.peso_liquido_operacional"
                  type="number"
                  step="any"
                  transparent
                  class="inputs"
                />
              </sui-form-field>
              <sui-form-field v-show="!un">
                <label>Peso Pronto</label>
                <sui-input
                  v-model="produto.peso_pronto"
                  step="any"
                  type="number"
                  transparent
                  class="inputs"
                />
              </sui-form-field>
              <sui-form-field class="form-buttons">
                <sui-button type="button" @click="cancelAdd"
                  >Cancelar</sui-button
                >
                <sui-button type="submit" color="teal">Editar</sui-button>
              </sui-form-field>
            </sui-form-fields>
          </sui-form>

          <sui-form v-else-if="type == 4" @submit="editStep">
            <h3>Editar etapa de preparo</h3>
            <sui-form-fields fields="two">
              <sui-form-field>
                <label>Tempo (em minutos)</label>
                <input type="number" v-model="passo.tempo" />
              </sui-form-field>
              <sui-form-field>
                <label>Descrição</label>
                <TextArea v-model="passo.descricao" required></TextArea>
              </sui-form-field>
            </sui-form-fields>

            <sui-form-field class="form-buttons">
              <sui-button type="button" @click="cancelAdd">Cancelar</sui-button>
              <sui-button type="submit" color="teal">Editar</sui-button>
            </sui-form-field>
          </sui-form>
        </div>
      </div>

      <div class="image-upload">
        <label for="file-input">
          <img :src="fileUrl" alt="" v-if="fileUrl" />
          <img :src="cadastro.imageUrl" alt="" v-else-if="cadastro.imageUrl" />
          <img src="../../assets/food.jpg" alt="" v-else />
        </label>

        <input id="file-input" type="file" @change="handleFileChange" />
      </div>
    </div>

    <div class="ficha-wrapper">
      <h3 v-show="produtos.length != 0">Ingredientes</h3>
      <p v-for="(produto, index) in produtos" :key="produto.produto._id">
        {{ produto.produto.item }}
        {{
          handleWeight(
            produto.peso_liquido_operacional,
            produto.produto.unidade
          )
        }}
        <sui-icon
          name="pencil"
          color="grey"
          class="optionIcons"
          @click="setEdit(index)"
        />
        <sui-icon
          name="trash"
          color="red"
          class="optionIcons"
          @click="deleteItem(index)"
        />
      </p>
      <h3 v-show="passos.length != 0">Modo de preparo</h3>
      <p v-for="(passo, index) in passos" :key="passo.descricao">
        {{ handleTime(passo.tempo) }}
        - {{ passo.descricao }}
        <sui-icon
          name="pencil"
          color="grey"
          class="optionIcons"
          @click="setStep(index)"
        />
        <sui-icon
          name="trash"
          color="red"
          class="optionIcons"
          @click="deleteStep(index)"
        />
      </p>
    </div>
  </div>
</template>

<script>
import {
  getFichaTecnica,
  getGrupos,
  getProdutoPorSubgrupo,
  getSubgrupos,
  putFichaTecnica,
  putFichaTecnicaImg,
} from "../../services/apiService";
import TextArea from "../../components/TextArea";

export default {
  nome: "DetalhesFichaTecnica",
  props: {
    cod: {
      default: null,
      type: String,
    },
  },
  components: {
    TextArea,
  },
  data() {
    return {
      // cadastro prato
      cadastro: {},
      // cadastro ficha tecnica
      produtos: [],
      passos: [],
      ficha_tecnica: [],
      produto: {},
      passo: {},
      grupos: [],
      subgrupos: [],
      items: [],
      grupo: null,
      subgrupo: null,
      loading: null,
      adding: false,
      type: 0,
      prod: "",
      stepIndex: "",
      file: null,
      fileUrl: null,
      un: false,
    };
  },
  methods: {
    async add(e) {
      e.preventDefault();
      const newItem = JSON.parse(
        JSON.stringify(this.type == 1 ? this.produto : this.passo)
      );

      if (this.type == 1) {
        newItem.produto = JSON.parse(newItem.produto);
        this.produtos.push(newItem);
      } else this.passos.push(newItem);
      this.cancelAdd();
      await this.montar_prato(e);
    },
    async montar_prato(e = null) {
      if (e) e.preventDefault();
      this.loading = true;
      const { id } = this.$route.params;
      const produtos = JSON.parse(JSON.stringify(this.produtos));
      produtos.forEach((produto) => {
        produto.produto = produto.produto._id;
      });
      const data = {
        id,
        ...this.cadastro,
        produtos,
        passos: this.passos,
      };

      await putFichaTecnica(data);
      this.loading = false;
    },
    async addSomething(type) {
      this.adding = true;
      this.type = type;

      if (this.type == 1)
        for (const key in this.produto) {
          this.grupo = null;
          this.subgrupo = null;
          this.produto[key] = null;
        }
      else for (const key in this.passo) this.passo[key] = null;
    },
    cancelAdd() {
      this.adding = false;
      this.type = 0;
    },
    handleWeight(value, un) {
      return `${value} ${un}`;
    },
    handleTime(value) {
      if (!value) return;
      const hours = value / 60;
      const rhours = Math.floor(hours);
      if (rhours == 0) return `${value} min`;
      let minutes = (hours - rhours) * 60;
      minutes = Math.round(minutes);
      if (minutes == 0) return `${rhours} horas`;
      return `${rhours} horas e ${minutes} min`;
    },
    async deleteItem(index) {
      this.produtos.splice(index, 1);
      await this.montar_prato();
    },
    async deleteStep(index) {
      this.passos.splice(index, 1);
      await this.montar_prato();
    },
    setEdit(index) {
      this.isEditing = true;
      this.type = 3;

      const prod = this.produtos[index];

      if (prod.produto.unidade == "un") this.un = true;
      else this.un = false;

      this.produto.peso_liquido_operacional = prod.peso_liquido_operacional;
      this.produto.peso_pronto = prod.peso_pronto;
      this.prod = { title: prod.produto.item, index };
    },
    setStep(index) {
      this.isEditing = true;
      this.type = 4;
      const step = this.passos[index];
      this.passo.tempo = step.tempo;
      this.passo.descricao = step.descricao;
      this.stepIndex = index;
    },
    async editIngredient(e) {
      e.preventDefault();
      const prod = this.produtos[this.prod.index];
      prod.peso_liquido_operacional = this.produto.peso_liquido_operacional;
      prod.peso_pronto = this.produto.peso_pronto;
      this.cancelAdd();
      await this.montar_prato(e);
    },
    async editStep(e) {
      e.preventDefault();
      const step = this.passos[this.stepIndex];
      step.tempo = this.passo.tempo;
      step.descricao = this.passo.descricao;
      this.cancelAdd();
      await this.montar_prato(e);
    },
    async handleFileChange(event) {
      this.file = event.target.files[0];
      this.fileUrl = URL.createObjectURL(this.file);

      const { id } = this.$route.params;

      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("id", id);

      await putFichaTecnicaImg(formData);
    },
  },
  watch: {
    async grupo(newVal, oldVal) {
      if (newVal) {
        this.subgrupos = [];
        const subgrupos = await getSubgrupos(newVal);
        subgrupos.forEach((subgrupo) => {
          this.subgrupos.push({ text: subgrupo.nome, value: subgrupo._id });
        });
      }
    },
    async subgrupo(newVal, oldVal) {
      if (newVal) {
        this.items = [];
        const items = await getProdutoPorSubgrupo(newVal);
        items.forEach((item) => {
          this.items.push({
            text: `${item.item} (${item.unidade})`,
            value: `{ "item": "${item.item}", "_id": "${item._id}", "unidade": "${item.unidade}" }`,
          });
        });
      }
    },
    "produto.produto"(val) {
      if (val) {
        const prod = JSON.parse(val);
        if (prod.unidade == "un") this.un = true;
        else this.un = false;
      }
    },
  },
  async mounted() {
    const { id } = this.$route.params;
    let ficha_tecnica;
    if (this.$store.state.api.cardapio.count == null)
      ficha_tecnica = await getFichaTecnica(id);
    else
      ficha_tecnica = this.$store.state.api.cardapio.data.find(
        (x) => x._id == id
      );

    this.cadastro = ficha_tecnica;
    this.produtos = ficha_tecnica.produtos;
    this.passos = ficha_tecnica.passos;

    if (this.$store.state.api.grupos.count == null) await getGrupos();
    this.$store.state.api.grupos.data.forEach((grupo) => {
      this.grupos.push({ text: grupo.nome, value: grupo._id });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#cadastro-wrapper {
  width: 100vw;

  h3 {
    margin-bottom: 1rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    img {
      height: 200px;
      width: 300px;
      object-fit: contain;
      margin-right: 5rem;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }

    .image-upload > input {
      display: none;
    }

    .header-info {
      display: flex;
      flex-direction: column;
      width: 100%;

      .columns > * {
        display: flex;
        flex-direction: column;
        > * {
          margin: 1rem;
        }
      }

      .inputs {
        border-bottom: 1px solid $semantic-input-color;
        width: 100%;
      }
      .inputs.textarea {
        word-break: break-word;
      }
      .criar_prato,
      .editar_prato_header {
        width: 75%;
        background-color: $primary-gray;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.2);
        padding: 1rem;
      }
      .editar_prato_header {
        display: flex;
        flex-direction: row;
        align-items: center;
        h3 {
          color: #fff;
          margin: 1rem;
        }
      }
      .criar_ficha {
        padding: 1rem;
        width: 75%;
        color: #ffffff;
        background-color: $dark-primary-color;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.2);
        border-radius: 0 0 0.8rem 0;
      }
      .buttons-choice {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
      label {
        color: #fff;
      }
      .form-field {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .form-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

  .ficha-wrapper {
    padding: 2rem;

    h3 {
      color: $secondary-text-color;
    }
  }
  .optionIcons {
    cursor: pointer;
  }
}
</style>
