<template>
  <div id="clients">
    <div class="text">
      <h1>Confira quem já é cliente nosso</h1>
      <p>
        Os nossos modelos de negócios são voltados para pequenos restaurantes.
      </p>
    </div>
    <div class="clients-cards">
      <ClientDetails
        name="Arena Mm"
        description="Cantina de um dos maiores centros esportivos de fortaleza"
        img="arena"
        class="shift1"
      />
      <ClientDetails
        name="Naturágua"
        description="Fornecedor parceiro de abrangência nacional"
        img="naturagua"
        class="shift2"
      />
    </div>
  </div>
</template>

<script>
import ClientDetails from "./ClientDetails.vue";

export default {
  name: "Clients",
  components: { ClientDetails },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#clients {
  width: 100%;
  margin-top: 160px;
  background: $primary-ten;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 158px 198px;

  .text {
    max-width: 340px;

    h1 {
      font: 700 48px "League Spartan", sans-serif;
    }

    p {
      font-size: 14px;
    }
  }

  .clients-cards {
    display: flex;
    justify-content: space-between;
    width: 50%;

    .shift1 {
      margin-top: -96px;
    }

    .shift2 {
      margin-bottom: -96px;
    }
  }

  @media (max-width: 1400px) {
    padding: 158px;
  }

  @media (max-width: 1280px) {
    padding: 158px 128px;
  }

  @media (max-width: 1279px) {
    flex-direction: column;
    padding: 78px 32px;

    .text {
      h1 {
        font-size: 36px;
      }

      p {
        font-size: 14px;
      }
    }

    .clients-cards {
      margin-top: 24px;
      flex-direction: column;
      align-items: center;

      .shift1,
      .shift2 {
        margin: 0;
      }

      .shift1 {
        margin-bottom: 62px;
      }
    }
  }
}
</style>
