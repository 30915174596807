<template>
  <div id="why-choose-us">
    <h1>Por que nos escolher?</h1>
    <!-- <div id="carousel-group"></div> -->
    <transition-group
      tag="div"
      name="list-animation"
      class="array-to-be-sorted"
      id="carousel"
    >
      <img
        src="../../assets/home/arrow_left.svg"
        alt=""
        :key="3"
        @click="select(true)"
      />
      <WhyChooseUsDetails
        v-for="(item, index) in details"
        :key="item.id"
        :img="item.img"
        :title="item.title"
        :description="item.description"
        :selected="item.selected"
        @switch="selectIndex(index)"
        :class="[
          index == 1 ? '' : 'not-selected',
          'list-animation-item',
          'list-item',
        ]"
      />
      <img
        src="../../assets/home/arrow_right.svg"
        alt=""
        :key="4"
        @click="select(false)"
      />
    </transition-group>
  </div>
</template>

<script>
import WhyChooseUsDetails from "./WhyChooseUsDetails";

export default {
  name: "WhyChooseUs",
  components: { WhyChooseUsDetails },
  data: () => ({
    details: [
      {
        id: 0,
        img: "chart",
        title: "Gestão de Estoque",
        description:
          "A medida que os produtos saem, o sistema calcula automaticamente o estoque, que voce recebe em tempo real com uma sugestão de quantidade de compra.",
        selected: false,
      },
      {
        id: 1,
        img: "cart",
        title: "Vendas online",
        description:
          "Com um link de compra o cliente pode escolher o produto, adicionar o endereço e fazer o pagamento de forma completamente autônoma.",
        selected: true,
      },
      {
        id: 2,
        img: "boxes",
        title: "Integração de estoque",
        description:
          "O fornecedor tem a capacidade de vizualizar e gerenciar o estoque dos produtos. Isso facilita a iniciativa da venda, que passa a ser possível pela parte do fornecedor.",
        selected: false,
      },
    ],
  }),
  methods: {
    rotate(reverse = false) {
      if (reverse) this.details.unshift(this.details.pop());
      else this.details.push(this.details.shift());
    },
    select(reverse = false, rotate = true) {
      this.details[1].selected = false;
      let nextIndex = reverse ? 0 : 2;
      this.details[nextIndex].selected = true;

      if (rotate) this.rotate(reverse);
    },
    selectIndex(index) {
      const current = this.details.find((item) => item.selected == true);
      current.selected = false;
      this.details[index].selected = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#why-choose-us {
  margin-top: 235px;

  h1 {
    margin-left: 64px;
    font: 800 64px "League Spartan", sans-serif;
  }

  #carousel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 55px 122px;

    .list-animation-item {
      transition: all ease 0.5s;
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: all ease 1s;
    }

    .fade-enter,
    .fade-leave-to {
      opacity: 0;
      transform: translateY(-30px) scale(0.9);
    }

    img {
      display: none;
    }
  }

  @media (max-width: 1279px) {
    h1 {
      margin-left: 32px;
      font-size: 36px;
    }

    #carousel {
      justify-content: center;

      .not-selected {
        display: none;
      }

      img {
        display: block;
        margin: 0 8px;
        cursor: pointer;
      }
    }
  }
}
</style>
