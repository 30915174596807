<template>
  <div id="cadastro-restaurante-wrapper">
    <div>
      <router-link to="/login">
        <h3><sui-icon name="arrow left" /> Voltar</h3>
      </router-link>
      <h3>Cadastrar novo restaurante</h3>
    </div>
    <sui-form class="register-form" @submit="sendForm">
      <sui-header dividing>Dados Cadastrais</sui-header>
      <sui-form-fields fields="two">
        <sui-form-field>
          <label>
            CNPJ
          </label>
          <sui-input
            required
            v-model="cadastro.cnpj"
            placeholder="CNPJ"
            maxlength="14"
            minlength="14"
        /></sui-form-field>
        <sui-form-field>
          <label>
            Nome/Razão
          </label>
          <sui-input
            required
            v-model="cadastro.nome_razao"
            placeholder="Nome/Razão"
        /></sui-form-field>
      </sui-form-fields>
      <sui-form-field>
        <label>
          Perfil
        </label>
        <sui-dropdown
          v-model="cadastro.perfil"
          placeholder="Perfil"
          selection
          :options="perfis"
        />
      </sui-form-field>
      <sui-header dividing>Dados do Usuário</sui-header>
      <sui-form-fields fields="three">
        <sui-form-field>
          <label>
            Nome
          </label>
          <sui-input
            required
            v-model="cadastro.nome"
            placeholder="Nome"
            icon-position="left"
            icon="user"
        /></sui-form-field>
        <sui-form-field>
          <label>
            Email
          </label>
          <sui-input
            required
            v-model="cadastro.email"
            placeholder="Email"
            icon-position="left"
            icon="at"
            type="email"
          />
        </sui-form-field>
        <sui-form-field>
          <label>
            Senha
          </label>
          <sui-input
            required
            v-model="cadastro.senha"
            placeholder="Senha"
            icon-position="left"
            icon="lock"
            type="password"
          />
        </sui-form-field>
      </sui-form-fields>
      <sui-header dividing>Endereço</sui-header>
      <sui-form-fields fields="three">
        <sui-form-field>
          <label>
            CEP
          </label>
          <sui-input
            required
            v-model="cadastro.cep"
            placeholder="CEP"
            maxlength="8"
        /></sui-form-field>
        <sui-form-field>
          <label>
            Estado
          </label>
          <sui-input required v-model="cadastro.estado" placeholder="Estado"
        /></sui-form-field>
        <sui-form-field>
          <label>
            Cidade
          </label>
          <sui-input required v-model="cadastro.cidade" placeholder="Cidade"
        /></sui-form-field>
      </sui-form-fields>
      <sui-form-fields fields="three">
        <sui-form-field>
          <label>
            Rua
          </label>
          <sui-input required v-model="cadastro.rua" placeholder="Rua"
        /></sui-form-field>
        <sui-form-field>
          <label>
            Número
          </label>
          <sui-input required v-model="cadastro.numero" placeholder="Número"
        /></sui-form-field>
        <sui-form-field>
          <label>
            Complemento
          </label>
          <sui-input v-model="cadastro.complemento" placeholder="Complemento"
        /></sui-form-field>
      </sui-form-fields>
      <sui-button
        type="submit"
        class="cadastrar"
        color="teal"
        content="Cadastrar"
        :loading="loading"
      />
    </sui-form>
  </div>
</template>

<script>
import { toast } from "../helpers";
import { postRestaurante } from "../services/adminService";
import axios from "axios";

export default {
  name: "Registrar",
  data() {
    return {
      perfis: [{ text: "Hamburgueria", value: "hamburgueria" }],
      cadastro: {
        cep: "",
        estado: "",
        cidade: "",
        bairro: "",
        rua: "",
      },
      loading: false,
    };
  },
  methods: {
    async sendForm(e) {
      e.preventDefault();
      this.loading = true;
      const res = await postRestaurante(this.cadastro);

      this.loading = false;
      if (res) this.$router.push("/login");
    },
  },
  watch: {
    "cadastro.cep": async function(val) {
      if (val.length == 8) {
        this.loadingCep = true;
        let res = await axios.get(`https://viacep.com.br/ws/${val}/json`);
        res = res.data;
        if (!res.erro) {
          this.cadastro.estado = res.uf;
          this.cadastro.cidade = res.localidade;
          this.cadastro.bairro = res.bairro;
          this.cadastro.rua = res.logradouro;
        } else {
          toast("error", "CEP inválido, tente outro");
          this.cadastro.estado = "";
          this.cadastro.cidade = "";
          this.cadastro.bairro = "";
          this.cadastro.rua = "";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
#cadastro-restaurante-wrapper {
  margin: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .cadastrar {
    float: right;
  }
}
</style>
