<template>
  <div id="restaurantes-wrapper">
    <h3>Restaurantes</h3>
    <div>
      <sui-table selectable striped>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell>Cliente</sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>

        <sui-table-body>
          <sui-table-row
            v-for="res in restaurantes"
            :key="res._id"
            @click="goToDetails(res._id, res.nome_razao)"
          >
            <sui-table-cell>{{ res.nome_razao }}</sui-table-cell>
          </sui-table-row>
        </sui-table-body>
      </sui-table>
      <sui-button color="red" content="Sair" @click="signOut" />
    </div>
  </div>
</template>

<script>
import { getRestaurantes } from "../../services/fornecedorService";

export default {
  name: "Restaurantes",
  data() {
    return {
      restaurantes: [],
    };
  },
  methods: {
    goToDetails(id, name) {
      this.$router.push({
        name: "fornecedor-restaurante-detalhe",
        params: { id },
        query: { name },
      });
    },
    signOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      localStorage.removeItem("cpf_cnpj");
      this.$store.commit("clearFornecedor");
      this.$router.push("/login");
    },
  },
  async mounted() {
    if (this.$store.state.fornecedor.restaurantes.count == null)
      await getRestaurantes(localStorage.getItem("cpf_cnpj"));

    this.restaurantes = this.$store.state.fornecedor.restaurantes.data;
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#restaurantes-wrapper {
  margin: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
}
</style>
