<template>
  <div id="app">
    <Sidebar v-show="!dontShowBarPages.includes($route.name)" />
    <div id="wrapper">
      <router-view class="application" />
      <Cart v-if="clientPages.includes($route.name)" />
      <Footer v-if="!['login', 'home'].includes($route.name)" />
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import Footer from "./components/Footer.vue";
import Cart from "./components/Cliente/Cart.vue";

export default {
  name: "App",
  components: {
    Sidebar,
    Footer,
    Cart,
  },
  data() {
    return {
      dontShowBarPages: [
        "login",
        "cliente-categoria",
        "cliente-cardapio",
        "registrar",
        "admin",
        "novo-restaurante",
        "novo-fornecedor",
        "fornecedor-restaurantes",
        "fornecedor-restaurante-detalhe",
        "fornecedor-detalhes-pedido",
        "admin-restaurantes",
        "admin-fornecedores",
        "home",
      ],
      clientPages: ["cliente-categoria", "cliente-cardapio"],
    };
  },
};
</script>

<style lang="scss" scoped>
#app {
  height: 100%;

  #wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.application {
  margin-left: 3rem;
  margin-bottom: 5rem;
}
</style>
