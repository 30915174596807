<template>
  <div>
    <div class="finalizado" v-if="status">
      <sui-icon name="check" color="green" size="huge" />

      <b>Seu pedido foi finalizado com sucesso!</b>
      <p>
        Agora é só aguardar a chegada do seu pedido!
      </p>
    </div>
    <div class="finalizado" v-else>
      <sui-icon name="times" color="red" size="huge" />

      <b>Ocorreu um problema com o seu pagamento</b>
      <p>
        Cheque com o seu banco para saber o que houve!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Finalizado",
  props: {
    status: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.finalizado {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p,
  b {
    text-align: center;
    font-size: 14px;
  }
}
</style>
