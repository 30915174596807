<template id="cardapio-template">
  <div id="cardapio-wrapper">
    <h3>Cardápio</h3>

    <sui-button
      fluid
      v-for="categoria in categorias"
      :key="categoria"
      :content="categoria"
      class="category-button"
      color="teal"
      @click="goToCardapio(categoria)"
    />
  </div>
</template>

<script>
import { getCategorias } from "../../services/clienteService";
import { normalizeAndSearch } from "../../helpers";

export default {
  name: "CategoriasPublico",
  data() {
    return {
      categorias: []
    };
  },
  methods: {
    goToCardapio(categoria) {
      this.$router.push({ name: "cliente-cardapio", params: { categoria } });
    },
    currency(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(amount);
    }
  },
  async mounted() {
    this.categorias = await getCategorias(this.$route.params.tenant);
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#cardapio-wrapper {
  padding-top: 2rem;
  margin-right: 2rem;

  h3 {
    color: $secondary-text-color;
    margin-bottom: 2rem;
  }

  .category-button {
    margin-bottom: 1rem;
  }
}
</style>
