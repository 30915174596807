<template>
  <div id="content">
    <h2 id="orcamento">Orçamento</h2>
    <h2>Restaurante {{ name }}</h2>

    <sui-table basic="very" celled>
      <sui-table-header>
        <sui-table-row>
          <sui-table-header-cell>Produto</sui-table-header-cell>
          <sui-table-header-cell>Quantidade</sui-table-header-cell>
          <sui-table-header-cell>Preço unitário</sui-table-header-cell>
          <sui-table-header-cell>Preço total</sui-table-header-cell>
        </sui-table-row>
      </sui-table-header>
      <sui-table-body>
        <sui-table-row
          v-for="item in this.$store.state.fornecedor.pedido.produtos"
          :key="item.product.id"
        >
          <sui-table-cell>{{ item.product.name }}</sui-table-cell>
          <sui-table-cell>
            {{ item.quantity }} {{ item.product.un }}
          </sui-table-cell>
          <sui-table-cell>{{ currency(item.price) }}</sui-table-cell>
          <sui-table-cell>
            Total: {{ currency(item.quantity * item.price) }}
          </sui-table-cell>
        </sui-table-row>
      </sui-table-body>
    </sui-table>

    <h3>Total:{{ currency(total) }}</h3>
  </div>
</template>

<script>
export default {
  name: "PedidoPDF",
  props: {
    total: Number,
  },
  data() {
    return {
      total: 0,
      name: "",
    };
  },
  mounted() {
    this.name = this.$route.query.name;
  },
  methods: {
    currency(amount) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        maximumFractionDigits: 2,
      }).format(amount);
    },
  },
};
</script>

<style lang="scss" scoped>
#content {
  #orcamento {
    position: absolute;
    top: 0;
    left: 0;
    margin: 2rem;
  }

  padding: 2rem;
  margin-top: 4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
