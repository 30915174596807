<template lang="html">
  <div id="pedidos-wrapper">
    <h3>Pedidos Online</h3>
    <div>
      <div class="top-segment">
        <div class="showable">
          <sui-input
            placeholder="Pesquisar por nome"
            icon="search"
            v-model="search"
          />
        </div>
      </div>
    </div>
    <sui-card-group :items-per-row="4" stackable>
      <sui-card
        v-for="pedido in filteredPedido"
        class="mesa"
        :id="pedido._id"
        :key="pedido._id"
        @click="setModal(pedido)"
      >
        <sui-card-content>
          <sui-icon name="utensils" class="right floated" size="large" />
          <sui-card-header>{{ pedido.codigo }}</sui-card-header>
        </sui-card-content>
        <sui-card-content>
          <sui-card-description>
            <b>Nome:</b>
            {{ pedido.entrega.nome }}
            <sui-divider />
          </sui-card-description>
          <sui-card-description v-if="pedido.entrega.pegarNoLocal">
            <b>A ser retirado em caixa</b><br />
            <sui-divider />
            <b>Já pagou? </b>
            <sui-icon name="check" color="green" v-if="pedido.pagamento" />
            <sui-icon name="times" color="red" v-else />
          </sui-card-description>
          <sui-card-description v-else>
            <b>Delivery:</b><br />
            {{ pedido.entrega.rua }}, {{ pedido.entrega.numero }}<br />
            {{ pedido.entrega.bairro }}<br />
            <sui-divider />
            <b>Já pagou? </b>
            <sui-icon name="check" color="green" v-if="pedido.pagamento" />
            <sui-icon name="times" color="red" v-else />
            <div v-if="!pedido.pagamento">
              <div>
                <b>Forma: </b> {{ handleForma(pedido.pagLocal.forma) }}<br />
                <b v-show="pedido.pagLocal.troco">Troco p/: </b
                >{{ handleCurrency(pedido.pagLocal.troco) }}
              </div>
            </div>
          </sui-card-description>
        </sui-card-content>
        <sui-card-content extra>
          Total: {{ handleCurrency(pedido.total) }}
        </sui-card-content>
      </sui-card>
    </sui-card-group>

    <sui-modal v-model="modal.visible" size="mini">
      <sui-modal-header>Finalizar pedido</sui-modal-header>
      <sui-modal-content class="finalizar">
        <sui-button
          content="Finalizar pedido"
          icon="check"
          label-position="left"
          @click="fOnline2()"
          primary
          :loading="modal.loading"
          v-if="modal.justFinalizar"
        />
        <sui-form v-else @submit="fOnline1">
          <sui-form-field>
            <label>Forma de pagamento</label>
            <sui-dropdown
              placeholder="Formas"
              selection
              :options="formas"
              v-model="forma"
            />
          </sui-form-field>
          <sui-button
            type="submit"
            content="Finalizar pedido"
            icon="check"
            label-position="left"
            primary
            :loading="modal.loading"
            >Finalizar</sui-button
          >
        </sui-form>
      </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
import {
  getPedidosOnline,
  finalizar1Online,
  finalizar2Online,
} from "../../services/pdvService";
import { currency, normalizeAndSearch } from "../../helpers";

export default {
  name: "PedidosOnline",
  data() {
    return {
      loading: false,
      pedidos: [],
      formas: [
        { value: "dinheiro", text: "Dinheiro" },
        { value: "credito", text: "Crédito" },
        { value: "debito", text: "Débito" },
      ],
      search: "",
      modal: {
        visible: false,
        justFinalizar: false,
        pedido: {},
        loading: false,
      },
      forma: "",
    };
  },
  async mounted() {
    this.pedidos = await getPedidosOnline();
    console.log(this.pedidos);
  },
  methods: {
    goToDetails(id) {
      this.$router.push({ name: "detalhes-mesa", params: { id } });
    },
    handleCurrency(amount) {
      return currency(amount);
    },
    handleForma(forma) {
      return this.formas.find((x) => x.value == forma).text;
    },
    setModal(pedido) {
      if (pedido.pagamento) this.modal.justFinalizar = true;
      else {
        this.modal.justFinalizar = false;
        if (pedido.pagLocal) this.forma = pedido.pagLocal.forma;
        else this.forma = "";
      }

      this.modal.visible = true;
      this.modal.pedido = pedido;
    },
    async fOnline1(e) {
      e.preventDefault();
      this.modal.loading = true;
      const res = await finalizar1Online({
        id: this.modal.pedido._id,
        forma: this.forma,
      });
      if (res)
        this.pedidos = this.pedidos.filter((pedido) => pedido._id != res._id);
      this.modal.loading = false;
      this.modal.visible = false;
    },
    async fOnline2() {
      this.modal.loading = true;
      const res = await finalizar2Online({ id: this.modal.pedido._id });
      if (res)
        this.pedidos = this.pedidos.filter((pedido) => pedido._id != res._id);
      this.modal.loading = false;
      this.modal.visible = false;
    },
  },
  computed: {
    filteredPedido() {
      return this.pedidos.filter((pedido) => {
        return normalizeAndSearch(pedido.entrega.nome, this.search);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#pedidos-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .top-segment {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .ui.input {
      width: 30rem;
    }
  }
  .showable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
  }
  .mesa {
    cursor: pointer;
  }
  .finalizar {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
