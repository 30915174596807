<template>
  <div id="cart-wrapper">
    <sui-button
      primary
      icon="shopping cart"
      circular
      size="huge"
      @click="openModal"
    />

    <sui-modal v-model="modal" size="tiny" v-if="state >= 0">
      <sui-modal-header>{{ components[state].title }}</sui-modal-header>
      <sui-modal-content scrolling>
        <component
          :is="components[state].component"
          @recebimento="comoReceber"
          @pagamento="comoPagar"
          @formularios="setForms"
          @valorTotal="setValorTotal"
          :frete="goToState1 == 2 ? 0 : 5"
          :entrega="formularios.entrega"
          :total="total"
          :status="reStatus"
          :restaurantInfo="restaurantInfo"
        />
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button content="Voltar" @click="goBack" />
        <sui-button
          content="Próximo passo"
          icon="arrow right"
          primary
          label-position="right"
          @click="changeState"
          v-show="state < 7 && this.$store.state.cliente.carrinho.length != 0"
        />
      </sui-modal-actions>
    </sui-modal>
  </div>
</template>

<script>
import { toast } from "../../helpers";
import {
  postPedido,
  postPedidoLoc,
  getRestaurantInfo,
} from "../../services/clienteService";

import Items from "./Items";
import ComoReceber from "./ComoReceber";
import Retirada from "./Retirada";
import Delivery from "./Delivery";
import Total from "./Total";
import ComoPagar from "./ComoPagar";
import PagamentoLocal from "./PagamentoLocal";
import Pagamento from "./Pagamento";
import Finalizado from "./Finalizado";

export default {
  name: "Cart",
  data() {
    return {
      modal: false,
      /*
        0 -> carrinho
        1 -> como deseja receber
        2 -> retirada no local
        3 -> formulario endereço
        4 -> total
        5 -> opções de pagamento
        6 -> pagamento no local
        7 -> formulario de pagamento
        8 -> pedido concluído
      */
      state: 0,
      components: [
        { title: "Carrinho", component: Items },
        {
          title: "Como você deseja receber o seu pedido?",
          component: ComoReceber,
        },
        {
          title: "Local de retirada",
          component: Retirada,
        },
        {
          title: "Delivery",
          component: Delivery,
        },
        {
          title: "Revisão do Pedido",
          component: Total,
        },
        {
          title: "Forma de Pagamento",
          component: ComoPagar,
        },
        {
          title: "Como quer pagar?",
          component: PagamentoLocal,
        },
        {
          title: "Pagamento",
          component: Pagamento,
        },
        {
          title: "Pedido Finalizado",
          component: Finalizado,
        },
      ],
      goToState1: "2",
      goToState2: "6",
      formularios: {
        entrega: {},
        pagamento: {},
        pedidos: {},
      },
      total: 0,
      reStatus: true,
      restaurantInfo: {},
    };
  },
  async mounted() {
    this.restaurantInfo = await getRestaurantInfo(this.$route.params.tenant);
  },
  methods: {
    goBack() {
      if (this.state == 2 || this.state == 3) this.state = 1;
      else if (this.state == 4) this.state = Number(this.goToState1);
      if (this.state == 7) this.state = 5;
      else if (this.state == 8) this.modal = false;
      else {
        this.state -= 1;
        if (this.state == -1) this.modal = false;
      }
    },
    openModal() {
      if (this.state == -1) this.state = 0;
      this.modal = true;
    },
    async changeState() {
      if (this.state == 0) {
        if (this.$store.state.cliente.carrinho.length == 0)
          toast("error", "Seu carrinho está vazio! Adicione alguns ítens");
        else this.state += 1;
      } else if (this.state == 1) {
        if (Number(this.goToState1) == 2) {
          if (!this.formularios.entrega.nome) {
            toast("error", "Preencha o seu nome!");
            return;
          } else
            this.formularios.entrega = {
              pegarNoLocal: true,
              nome: this.formularios.entrega.nome,
            };
        }
        this.state = Number(this.goToState1);
      } else if (this.state == 2) this.state = 4;
      else if (this.state == 3) {
        const f = this.formularios.entrega;
        if (!f || !f.nome || !f.cep || !f.rua || !f.bairro || !f.numero)
          toast("error", "Preencha o formulário corretamente!");
        else this.state += 1;
      }
      // OPÇÕES DE PAGAMENTO
      else if (this.state == 5) {
        // PAGAMENTO LOCAL
        if (Number(this.goToState2) == 6) {
          // RETIRADA
          if (Number(this.goToState1) == 2) {
            this.handlePedido();
            const res = await postPedidoLoc(
              this.formularios,
              this.$route.params.tenant
            );
            this.$store.commit("clearCart");
            this.handleRequest(res);
          }
          // ENTREGA
          else this.state = Number(this.goToState2);
        } else this.state = Number(this.goToState2);
      }
      // PAGAMENTO LOCAL COM ENTREGA
      else if (this.state == 6) {
        this.handlePedido();
        const res = await postPedidoLoc(
          this.formularios,
          this.$route.params.tenant
        );
        this.$store.commit("clearCart");
        this.handleRequest(res);
      } else this.state += 1;
    },
    comoReceber(value) {
      this.goToState1 = value;
    },
    comoPagar(value) {
      this.goToState2 = value;
    },
    async setForms(form) {
      if (Object.keys(form).length !== 0)
        this.formularios = { ...this.formularios, ...form };

      // PAGAMENTO ONLINE
      if (this.state == 7) {
        this.handlePedido();
        const res = await postPedido(
          this.formularios,
          this.$route.params.tenant
        );
        this.$store.commit("clearCart");
        this.handleRequest(res);
      }
    },
    setValorTotal(total) {
      this.total = total;
    },
    handlePedido() {
      this.formularios.pedidos = this.$store.state.cliente.carrinho;
      this.formularios.pedidos.forEach((pedido) => {
        if (pedido.item._id) pedido.item = pedido.item._id;
      });
      this.formularios.total = this.total;
    },
    handleRequest(res) {
      if (res.status == "rejected") this.reStatus = false;
      this.state = 8;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#cart-wrapper {
  position: fixed;
  right: 0;
  top: 0;
  margin: 1rem;
}
</style>
