<template>
  <div id="cadastro-fornecedor-wrapper">
    <h3>Cadastro de Fornecedor</h3>
    <form @submit="sendForm">
      <div>
        <h3>Dados Cadastrais</h3>
        <sui-grid>
          <sui-grid-row>
            <sui-grid-column :width="4"
              ><sui-dropdown
                placeholder="Tipo de pessoa *"
                selection
                v-model="cadastro.tipo_pessoa"
                :options="tipo_pessoa_options"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input
                placeholder="CPF/CNPJ *"
                v-model="cadastro.cpf_cnpj"
                required
            /></sui-grid-column>
            <sui-grid-column :width="8"
              ><sui-input
                placeholder="Nome/Razão *"
                v-model="cadastro.nome_razao"
                required
            /></sui-grid-column>
          </sui-grid-row>
          <sui-grid-row>
            <sui-grid-column :width="8"
              ><sui-input placeholder="Fantasia" v-model="cadastro.fantasia"
            /></sui-grid-column>
            <sui-grid-column :width="4">
              <sui-dropdown
                placeholder="Contribuinte"
                selection
                v-model="cadastro.str_contribuinte_icms"
                :options="tipo_contribuinte"
              />
            </sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input
                placeholder="Número do contribuinte"
                v-model="cadastro.num_contribuinte_icms"
            /></sui-grid-column>
          </sui-grid-row>
        </sui-grid>
      </div>
      <sui-divider />
      <div>
        <h3>Endereço</h3>
        <sui-grid>
          <sui-grid-row>
            <sui-grid-column :width="4"
              ><sui-input placeholder="CEP" v-model="cadastro.cep" maxlength="8"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input
                placeholder="Estado"
                v-model="cadastro.estado"
                readonly
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input
                placeholder="Cidade"
                v-model="cadastro.cidade"
                readonly
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input
                placeholder="Bairro"
                v-model="cadastro.bairro"
                readonly
            /></sui-grid-column>
          </sui-grid-row>
          <sui-grid-row>
            <sui-grid-column :width="6"
              ><sui-input placeholder="Rua" v-model="cadastro.rua" readonly
            /></sui-grid-column>
            <sui-grid-column :width="2"
              ><sui-input placeholder="Número" v-model="cadastro.numero"
            /></sui-grid-column>
            <sui-grid-column :width="8"
              ><sui-input
                placeholder="Complemento"
                v-model="cadastro.complemento"
            /></sui-grid-column>
          </sui-grid-row>
        </sui-grid>
      </div>
      <sui-divider />
      <div>
        <h3>Contato</h3>
        <sui-grid>
          <sui-grid-row>
            <sui-grid-column :width="4"
              ><sui-input
                placeholder="Responsável"
                v-model="cadastro.responsavel"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input placeholder="Telefone" v-model="cadastro.telefone"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input placeholder="E-mail" v-model="cadastro.email"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input placeholder="Website" v-model="cadastro.website"
            /></sui-grid-column>
          </sui-grid-row>
        </sui-grid>
      </div>
      <sui-divider />
      <div>
        <h3>Dados Adicionais</h3>
        <sui-grid>
          <sui-grid-row>
            <sui-grid-column :width="6"
              ><sui-dropdown
                placeholder="Tipo de contato"
                selection
                v-model="cadastro.tipo_contato"
                :options="tipo_contato"
            /></sui-grid-column>
            <sui-grid-column :width="6"
              ><sui-dropdown
                placeholder="Situação"
                selection
                v-model="cadastro.situacao"
                :options="tipo_situacao"
            /></sui-grid-column>
            <sui-grid-column :width="4"
              ><sui-input
                required
                placeholder="Tempo de reposição em dias*"
                v-model="cadastro.tempo_reposicao"
            /></sui-grid-column>
          </sui-grid-row>
        </sui-grid>
      </div>
      <sui-divider />
      <div>
        <h3>Observações</h3>
        <TextArea v-model="cadastro.observacao"></TextArea>
      </div>
      <sui-button color="teal" class="cadastrar" type="submit"
        >Cadastrar</sui-button
      >
    </form>
  </div>
</template>

<script>
import TextArea from "../../components/TextArea";
import { toast } from "../../helpers";
import { postFornecedores } from "../../services/apiService";
import axios from "axios";

export default {
  name: "CadastroFornecedor",
  components: {
    TextArea
  },
  data() {
    return {
      tipo_pessoa_options: [
        { text: "Física", value: "Física" },
        { text: "Jurídica", value: "Jurídica" }
      ],
      tipo_contribuinte: [
        { text: "Contribuinte ICMS", value: "Contribuinte ICMS" },
        {
          text: "Contribuinte isento de Inscrição no Cadastro de Contribuintes",
          value: "Contribuinte isento de Inscrição no Cadastro de Contribuintes"
        },
        {
          text:
            "Não contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes",
          value:
            "Não contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro de Contribuintes"
        }
      ],
      tipo_contato: [
        { text: "Cliente", value: "Cliente" },
        { text: "Fornecedor", value: "Fornecedor" },
        { text: "Outros", value: "Outros" }
      ],
      tipo_situacao: [
        { text: "Ativo", value: "Ativo" },
        { text: "Inativo", value: "Inativo" }
      ],
      cadastro: {
        cep: "",
        estado: "",
        cidade: "",
        bairro: "",
        rua: ""
      },
      loadingCep: false
    };
  },
  methods: {
    async sendForm(e) {
      e.preventDefault();
      if (!this.cadastro.tipo_pessoa) {
        toast("error", "Preencha o tipo de pessoa");
        return;
      }
      const res = await postFornecedores(this.cadastro);
      if (res) this.$router.push({ name: "fornecedores" });
    }
  },
  watch: {
    "cadastro.cep": async function(val) {
      if (val.length == 8) {
        this.loadingCep = true;
        let res = await axios.get(`https://viacep.com.br/ws/${val}/json`);
        res = res.data;
        if (!res.erro) {
          this.cadastro.estado = res.uf;
          this.cadastro.cidade = res.localidade;
          this.cadastro.bairro = res.bairro;
          this.cadastro.rua = res.logradouro;
        } else {
          toast("error", "CEP inválido, tente outro");
          this.cadastro.estado = "";
          this.cadastro.cidade = "";
          this.cadastro.bairro = "";
          this.cadastro.rua = "";
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#cadastro-fornecedor-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .ui.input,
  .ui.dropdown {
    width: 100%;
  }
  .cadastrar {
    float: right;
    margin-top: 1rem;
  }
}
</style>
