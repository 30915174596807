import { format, parseISO } from "date-fns";

import { pt } from "date-fns/locale";

export const currency = (amount) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(amount);
};

export const date = (date, hour = true) => {
  return hour
    ? format(parseISO(date), "'Dia' dd 'de' MMMM', às ' HH:mm'h'", {
        locale: pt,
      })
    : new Date(date).toLocaleDateString("pt-BR", {
        day: "numeric",
        month: "2-digit",
        year: "2-digit",
      });
};

export const toast = (type, message) => {
  $("body").toast({
    class: type,
    position: "bottom left",
    message,
  });
};

export const normalizeAndSearch = (string1, string2) => {
  return string1
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .includes(
      string2
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );
};
