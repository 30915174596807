<template lang="html">
  <div id="pedidos-wrapper">
    <h3>Mesas</h3>
    <div>
      <div class="top-segment">
        <div class="showable">
          <sui-input placeholder="Pesquisar por mesa" icon="search" />

          <div class="top-buttons">
            <sui-button
              icon="object ungroup"
              label-position="left"
              :content="juntarMesa ? 'Carregando' : 'Juntar Mesa'"
              color="yellow"
              :disabled="selected.length != 2"
              @click="mergeTable"
              :espera="espera"
            />
            <sui-button
              :icon="addTable ? 'minus' : 'plus'"
              label-position="left"
              :content="addTable ? 'Fechar aba' : 'Adicionar mesa'"
              color="teal"
              @click="addTable = !addTable"
            />
          </div>
        </div>
        <sui-segment v-show="addTable" class="add-new-table">
          <sui-form @submit="newTable">
            <sui-form-field>
              <label
                >Mesa (Para adicionar mais de uma, ponha entre vírgulas)</label
              >
              <input placeholder="Mesa(s)" v-model="tables" required />
            </sui-form-field>
            <sui-form-field>
              <label>Apelido</label>
              <input placeholder="Apelido (opcional)" v-model="nickname" />
            </sui-form-field>
            <sui-button
              content="Abrir"
              color="teal"
              type="submit"
              :loading="loading"
            />
          </sui-form>
        </sui-segment>
      </div>
    </div>
    <sui-card-group
      :items-per-row="5"
      stackable
      v-if="$store.state.pdv.pedidos.count != null"
    >
      <sui-card
        v-for="mesa in $store.state.pdv.pedidos.data"
        :key="mesa._id"
        class="mesa"
        @click="goToDetails($event, mesa._id)"
      >
        <sui-card-content>
          <sui-icon name="utensils" class="right floated" size="large" />
          <sui-checkbox
            v-model="selected"
            :value="mesa"
            class="right floated"
          />
          <sui-card-header>{{ mesa.apelido }}</sui-card-header>
          <sui-card-meta
            >Mesas:
            <sui-label size="mini" v-for="n in mesa.mesa" :key="n">
              {{ n }}
            </sui-label>
          </sui-card-meta>
        </sui-card-content>
        <sui-card-content extra>
          Em aberto: {{ handleCurrency(mesa.total) }}
        </sui-card-content>
      </sui-card>
    </sui-card-group>
    <div v-else>
      <sui-loader active />
    </div>
  </div>
</template>

<script>
import { getPedidos, addMesa, mergeMesa } from "../../services/pdvService";
import { currency } from "../../helpers";

export default {
  name: "Mesas",
  data() {
    return {
      addTable: false,
      tables: null,
      nickname: "",
      loading: false,
      search: "",
      selected: [],
      espera: false,
      juntarMesa: false,
    };
  },
  async mounted() {
    if (this.$store.state.pdv.pedidos.count == null) await getPedidos();
  },
  methods: {
    goToDetails(e, id) {
      if (e.target.tagName !== "INPUT" && e.target.tagName !== "LABEL")
        this.$router.push({ name: "detalhes-mesa", params: { id } });
    },
    handleCurrency(amount) {
      return currency(amount);
    },
    async newTable(e) {
      e.preventDefault();
      this.loading = true;

      const numbersOnly = (val) => {
        if (typeof val === "number") return val;
      };

      let tables = this.tables.split(",");
      tables = tables.map((el) => {
        const n = Number(el);
        return isNaN(n) ? el : n;
      });
      tables = tables.filter(numbersOnly);

      const data = {
        mesa: tables,
        apelido: this.nickname,
      };

      const table = await addMesa(data);
      this.loading = false;
      this.$router.push({ name: "detalhes-mesa", params: { id: table._id } });
    },

    async mergeTable() {
      this.espera = true;
      this.juntarMesa = true;

      const data = {
        mesas: [this.selected[0].mesa[0], this.selected[1].mesa[0]],
      };

      this.$store.commit("removePedido", this.selected[0]._id);
      this.$store.commit("removePedido", this.selected[1]._id);

      const res = await mergeMesa(data);

      if (res) this.selected.splice(0);

      this.espera = false;
      this.juntarMesa = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#pedidos-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .top-segment {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .ui.input {
      width: 30rem;
    }
  }
  .showable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
  }
  .mesa {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .showable {
      flex-direction: column;
    }

    .top-buttons {
      display: flex;
      flex-direction: row;
      margin: 0.5rem 0;
    }

    .ui.input {
      width: auto !important;
    }

    .add-new-table {
      margin-bottom: 1rem;
    }
  }
}
</style>
