<template>
  <div id="cadastro-usuario-wrapper">
    <h3>Cadastrar usuário</h3>
    <sui-form @submit="handleRegister" class="register-form">
      <sui-form-field>
        <sui-input
          required
          v-model="cadastro.nome"
          placeholder="Nome"
          icon-position="left"
          icon="user"
      /></sui-form-field>
      <sui-form-field>
        <sui-input
          required
          v-model="cadastro.email"
          placeholder="Email"
          icon-position="left"
          icon="at"
          type="email"
      /></sui-form-field>
      <sui-form-field>
        <sui-dropdown
          v-model="cadastro.role"
          placeholder="Cargo"
          selection
          :options="roles"
        />
      </sui-form-field>
      <sui-form-field>
        <sui-input
          required
          v-model="cadastro.senha"
          placeholder="Senha"
          icon-position="left"
          icon="lock"
          type="password"
        />
      </sui-form-field>
      <sui-button
        type="submit"
        class="cadastrar"
        color="teal"
        content="Cadastrar"
      />
    </sui-form>
  </div>
</template>

<script>
import TextArea from "../../components/TextArea";
import { toast } from "../../helpers";
import api from "../../services/api";

export default {
  name: "CadastroUsuario",
  components: {
    TextArea,
  },
  data() {
    return {
      cadastro: {},
      roles: [
        { text: "Gerente", value: "Gerente" },
        { text: "Garçom", value: "Garcom" },
        { text: "Cozinha", value: "Cozinha" },
      ],
      loading: false,
    };
  },
  methods: {
    async handleRegister(e) {
      e.preventDefault();
      this.loading = true;
      try {
        const data = {
          email: this.cadastro.email,
          senha: this.cadastro.senha,
          nome: this.cadastro.nome,
          role: this.cadastro.role,
        };
        const response = await api.post("admin/register", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            tenant: localStorage.getItem("tenant"),
          },
        });
        if (response) {
          this.$store.commit("pushUser", response.data.user);
          toast(
            "success",
            `Usuário de nome ${response.data.user.nome} criado com sucesso!`
          );
          this.$router.push("/usuarios");
        }
      } catch (err) {
        console.log(err);
        this.email = this.senha = this.role = this.nome = "";
        toast("error", "Ocorreu um erro");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";
#cadastro-usuario-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .cadastrar {
    float: right;
  }
}
</style>
