<template lang="html">
  <div id="compras-wrapper">
    <h3>Pedido de compra</h3>
    <h3>Fornecedor</h3>
    <div class="fornecedores-choice">
      <sui-dropdown
        placeholder="Fornecedor"
        selection
        search
        :options="fornecedores"
        v-model="pedido.fornecedor"
      />
      <!-- <p>Visualizar na visão do fornecedor</p> -->
    </div>
    <sui-divider />
    <h3>Compras</h3>
    <sui-message compact info v-show="!pedido.fornecedor"
      >Primeiro indique o fornecedor</sui-message
    >
    <p v-if="pedido.produtos.length == 0">Sem produtos adicionados</p>
    <sui-table striped v-else>
      <sui-table-header>
        <sui-table-header-cell>SKU</sui-table-header-cell>
        <sui-table-header-cell>Descrição</sui-table-header-cell>
        <sui-table-header-cell>Unidade</sui-table-header-cell>
        <sui-table-header-cell>Quantidade</sui-table-header-cell>
        <sui-table-header-cell>Preço unitário</sui-table-header-cell>
        <sui-table-header-cell>Preço total</sui-table-header-cell>
      </sui-table-header>
      <sui-table-body>
        <sui-table-row v-for="prod in selectedProdutos" :key="prod._id">
          <sui-table-cell>{{ prod.sku }}</sui-table-cell>
          <sui-table-cell>{{ prod.item }}</sui-table-cell>
          <sui-table-cell>{{ prod.unidade }}</sui-table-cell>
          <sui-table-cell>{{ prod.quantidade }}</sui-table-cell>
          <sui-table-cell>{{
            handleCurrency(prod.preco_unitario)
          }}</sui-table-cell>
          <sui-table-cell>{{
            handleCurrency(prod.preco_total)
          }}</sui-table-cell>
        </sui-table-row>
      </sui-table-body>
    </sui-table>

    <sui-button
      content="Adicionar produto"
      icon="plus"
      label-position="left"
      float="right"
      basic
      color="teal"
      @click="modalVisible = true"
      :disabled="!pedido.fornecedor"
    />

    <sui-button
      content="Salvar compra"
      icon="save"
      label-position="left"
      float="right"
      color="teal"
      @click="addEntrada"
      :disabled="pedido.produtos.length == 0"
    />
    <sui-modal v-model="modalVisible" size="small">
      <sui-modal-header>Adicionar produto</sui-modal-header>
      <sui-modal-content>
        <sui-form @submit="addPedido">
          <sui-form-field>
            <sui-dropdown
              placeholder="Produto"
              selection
              search
              :options="produtosDropdown"
              v-model="selectedProduto.produto"
              class="produto-dropdown"
              noResultsMessage="Nenhum produto encontrado"
            />
          </sui-form-field>
          <sui-form-fields>
            <sui-form-field width="six">
              <sui-input
                type="number"
                placeholder="Quantidade"
                v-model="selectedProduto.quantidade"
                required
              />
            </sui-form-field>
            <sui-form-field width="six">
              <sui-input
                type="number"
                placeholder="Valor unitário"
                selected
                v-model="selectedProduto.custo_produto"
                required
                step="0.01"
              />
            </sui-form-field>
            <sui-form-field width="four">
              <sui-input
                placeholder="Valor total"
                readonly
                :value="
                  handleCurrency(
                    selectedProduto.quantidade * selectedProduto.custo_produto
                  )
                "
              />
            </sui-form-field>
          </sui-form-fields>
          <sui-button
            type="button"
            content="Cancelar"
            @click="modalVisible = false"
          />
          <sui-button
            type="submit"
            content="Adicionar"
            color="teal"
            :disabled="!selectedProduto.produto"
          />
        </sui-form>
      </sui-modal-content>
    </sui-modal>
  </div>
</template>

<script>
import {
  getFornecedores,
  getProdutoPorFornecedor,
  postEntrada,
} from "../services/apiService";

import { currency } from "../helpers";

export default {
  name: "PedidoCompra",
  data() {
    return {
      fornecedores: [],
      produtos: [],
      produtosDropdown: [],
      selectedProduto: {
        produto: null,
        quantidade: null,
        custo_produto: null,
      },
      selectedProdutos: [],
      pedido: {
        fornecedor: null,
        produtos: [],
        status: false,
      },
      modalVisible: false,
    };
  },
  async mounted() {
    if (this.$store.state.api.fornecedores.count == null)
      await getFornecedores();

    this.$store.state.api.fornecedores.data.forEach((fornecedor) => {
      this.fornecedores.push({
        text: fornecedor.nome_razao,
        value: fornecedor._id,
      });
    });
  },
  watch: {
    "pedido.fornecedor": async function(newValue) {
      this.pedido.produtos = [];
      this.produtos = await getProdutoPorFornecedor(newValue);
      this.produtos.forEach((produto) => {
        this.produtosDropdown.push({
          text: `${produto.item} (${produto.unidade})`,
          value: produto._id,
        });
      });
    },
  },
  methods: {
    addPedido(e) {
      e.preventDefault();
      const produto = this.produtos.find(
        (x) => x._id == this.selectedProduto.produto
      );
      this.selectedProdutos.push({
        ...produto,
        quantidade: this.selectedProduto.quantidade,
        preco_unitario: this.selectedProduto.custo_produto,
        preco_total:
          this.selectedProduto.custo_produto * this.selectedProduto.quantidade,
      });
      this.pedido.produtos.push(
        JSON.parse(JSON.stringify(this.selectedProduto))
      );
      this.modalVisible = false;

      this.selectedProduto.produto = this.selectedProduto.quantidade = this.selectedProduto.custo_produto = null;
    },
    handleCurrency(amount) {
      return currency(amount);
    },
    async addEntrada() {
      await postEntrada(this.pedido);
      this.$router.push("entradas");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
#compras-wrapper {
  padding: 2rem;
  h3 {
    color: $secondary-text-color;
    margin-bottom: 1rem;
  }
  .fornecedores-choice {
    display: flex;
    flex-direction: row;
    align-items: center;

    p {
      margin-left: 2rem;
      cursor: pointer;
      color: $accent-color;
    }

    p:hover {
      font-weight: bold;
    }
  }

  .produto-dropdown {
    width: 100%;
  }
}
</style>
