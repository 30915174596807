<template>
  <div id="client">
    <img :src="require(`../../assets/home/clients/${img}.svg`)" />
    <h1>{{ name }}</h1>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "ClientDetails",
  props: {
    name: String,
    description: String,
    img: String,
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

#client {
  background: $white;
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 248px;
  padding: 48px 32px;

  h1 {
    font: 700 18px "League Spartan", sans-serif;
  }

  p {
    font-size: 12px;
    opacity: 0.6;
    text-align: center;
  }
}
</style>
