<template>
  <div>
    <sui-grid>
      <sui-grid-row>
        <sui-grid-column class="cadastro-column" data-tooltip="Item">
          <sui-input
            class="cadastro-input"
            v-model="value.item"
            placeholder="Item *"
          />
        </sui-grid-column>
      </sui-grid-row>
      <sui-grid-row>
        <sui-grid-column class="cadastro-column" :width="4" data-tooltip="NCM">
          <sui-input
            class="cadastro-input"
            v-model="value.ncm"
            placeholder="NCM"
            type="number"
          />
        </sui-grid-column>
        <sui-grid-column class="cadastro-column" :width="4" data-tooltip="CEST">
          <sui-input
            class="cadastro-input"
            v-model="value.cest"
            placeholder="CEST"
            type="number"
          />
        </sui-grid-column>
        <sui-grid-column
          class="cadastro-column"
          :width="4"
          data-tooltip="Origem"
        >
          <sui-input
            class="cadastro-input"
            v-model="value.origem"
            placeholder="Origem"
          />
        </sui-grid-column>
        <sui-grid-column class="cadastro-column" :width="4" data-tooltip="CST">
          <sui-input
            class="cadastro-input"
            v-model="value.cst"
            placeholder="CST"
            type="number"
          />
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>
  </div>
</template>

<script>
export default {
  name: "Tributacao",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    }
  }
};
</script>

<style lang="scss" src="../../styles/components/cadastroProdutos.scss" scoped />
